import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import TextInput from "../../input/TextInput";
import SelectApiInput from "../../input/SelectApiInput";

const schema = yup.object({
  ten_nvt: yup.string().required("Vui lòng nhập tên nhóm"),
});

function FormNhomHangPOS({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ten_nvt: "",
    la_nhom_hang_hoa: false,
    nhom_me: null,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        nhom_me: defaultValues.nh_me
          ? {
              _id: defaultValues.nh_me || "",
              ten_nvt: defaultValues?.ten_nh_me || "",
            }
          : null,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { nhom_me, ...fields } = values;
    return {
      ...fields,
      nh_me: nhom_me?._id || "",
      ten_nh_me: nhom_me?.ten_nvt || "",
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="600px"
      formTitle="nhóm hàng"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <TextInput
          required
          label="Tên nhóm hàng"
          labelWidth="25%"
          placeholder="Nhập tên nhóm hàng"
          name="ten_nvt"
          register={register}
          errorMessage={errors?.ten_nvt?.message as string}
        />
        <Controller
          name="nhom_me"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                labelWidth="25%"
                label="Nhóm mẹ"
                placeholder="Chọn nhóm mẹ"
                apiCode="dmnvt"
                defaultCondition={{
                  la_nhom_hang_hoa: true,
                  _id: defaultValues?._id
                    ? { $ne: defaultValues?._id }
                    : undefined,
                }}
                searchFields={["ten_nvt"]}
                getOptionLabel={(option) => option.ten_nvt}
                checkEqual={(value, option) => value?._id === option?._id}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormNhomHangPOS;
