import React from "react";
import SelectApiInput, { ISelectApiInput } from "../input/SelectApiInput";
import FilterBox from "./FilterBox";

interface IFilterSelectApi extends ISelectApiInput {
  title?: string;
}

function FilterSelectApi({ title, value, ...props }: IFilterSelectApi) {
  return (
    <FilterBox title={title} active={!!value}>
      <SelectApiInput labelWidth="25%" value={value} {...props} />
    </FilterBox>
  );
}

export default FilterSelectApi;
