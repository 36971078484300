import React, { useEffect, useState } from "react";
import FilterBox from "./FilterBox";
import TextInput from "../input/TextInput";
import { IconButton } from "@mui/material";
import { LuXCircle } from "react-icons/lu";

interface IFilterText {
  title?: string;
  value?: string;
  placeholder?: string;
  onSearch?: (value: string) => void;
}

function FilterText({ title, value, placeholder, onSearch }: IFilterText) {
  const [searchText, setSearchText] = useState<string>(value || "");
  const [active, setActive] = useState<boolean>(false);

  // handle search
  const handleSearch = (e: React.KeyboardEvent) => {
    // Key code enter = 13
    if (e.keyCode === 13) {
      onSearch?.(searchText.trim());
      setActive(!!searchText);
    }
  };

  // handle clear
  const handleClear = () => {
    setSearchText("");
    setActive(false);
    onSearch?.("");
  };

  useEffect(() => {
    onSearch?.(value || "");
    setSearchText(value || "");
    setActive(!!value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FilterBox title={title} active={active}>
      <TextInput
        direction="column"
        multiline={false}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={handleSearch}
        placeholder={placeholder}
        endAdornment={
          searchText ? (
            <IconButton
              sx={{
                backgroundColor: "whitish.whiteSoft",
                padding: "4px",
              }}
              onClick={handleClear}
            >
              <LuXCircle size={16} />
            </IconButton>
          ) : null
        }
      />
    </FilterBox>
  );
}

export default FilterText;
