import React from "react";
import WrapperExpand from "../WrapperExpand";
import { IExpand } from "../../../../types/expand.type";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { asyncDelete } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import TextInput from "../../input/TextInput";
import {
  formatDateDisplay,
  generateSplitText,
  numeralCustom,
} from "../../../../utils/helpers";
import TableApp from "../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import DichVuGiaoHang from "./components/DichVuGiaoHang";

const columns: TableColumn<any>[] = [
  {
    name: "Sản phẩm",
    selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
    width: "200px",
    wrap: true,
  },
  {
    name: "ĐVT",
    width: "100px",
    wrap: true,
    selector: (row) => row.ma_dvt,
  },
  {
    name: "Giá bán",
    wrap: true,
    width: "120px",
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
  },
  {
    name: "SL",
    width: "80px",
    selector: (row) => row.sl_xuat,
  },
  {
    name: "Tiền hàng",
    wrap: true,
    width: "120px",
    selector: (row) => row.tien_hang_nt,
    format: (row) => numeralCustom(row.tien_hang_nt).format(),
  },
  {
    name: "Tiền CK",
    width: "120px",
    selector: (row) => row.tien_ck_nt,
    format: (row) => numeralCustom(row.tien_ck_nt).format(),
  },
  {
    name: "Thành tiền",
    width: "120px",
    selector: (row) => row.tien_nt,
    format: (row) => numeralCustom(row.tien_nt).format(),
  },
];

const apiCode = "sogcapp";

function ExpandSOGCAPP({ data, onOpenEdit, setLoad }: IExpand) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  // const { currentStore } = useSelector((state: any) => state.config);
  const { showAlert } = useAlertContext();

  const thongTinGiaoHang = generateSplitText({
    strings: [
      data?.ten_nguoi_nhan,
      data?.dien_thoai,
      data?.dia_chi,
      data?.ten_xa_phuong,
      data?.ten_quan_huyen,
      data?.ten_tinh_thanh,
    ],
    symbol: ",",
  });

  const handleDelete = async () => {
    const resp = await asyncDelete({
      apiCode,
      id: data?._id,
      token,
      idApp: app?._id,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi xóa dữ liệu",
      });
    } else {
      showAlert({ type: "success", message: `Đã xóa thành công` });
      setLoad?.((prev) => prev + 1);
    }
  };

  // const handlePrint = async (idHd: string) => {
  //   try {
  //     const mauIn = (currentStore?.printers || []).find(
  //       (item: any) => item.ma_cn.toLowerCase() === apiCode
  //     );
  //     if (!mauIn) {
  //       showAlert({
  //         type: "warning",
  //         message: `Chi nhánh hiện tại chưa có mẫu in`,
  //       });
  //       return;
  //     }
  //     const idMauIn = mauIn.id_mau_in;
  //     const printUrl = `${API_URL}/${ID_APP}/${apiCode}/excel/${idMauIn}?_id=${idHd}&print=1&access_token=${token}`;
  //     const resp = await axios.get(printUrl);
  //     let content: any;
  //     if (resp && resp.status === 200) {
  //       content = resp.data;
  //     }
  //     const printFrame = document.createElement("iframe");
  //     printFrame.style.position = "absolute";
  //     printFrame.style.width = "0";
  //     printFrame.style.height = "0";
  //     printFrame.style.border = "none";
  //     document.body.appendChild(printFrame);
  //     printFrame?.contentDocument?.write(content);
  //     printFrame?.contentDocument?.close();
  //     setTimeout(() => {
  //       printFrame?.contentWindow?.focus(); // Đảm bảo focus vào iframe trước khi in
  //       printFrame?.contentWindow?.print(); // Thực hiện lệnh in
  //       // Sau khi in xong, xóa iframe để không giữ lại
  //       document.body.removeChild(printFrame);
  //     }, 500); // Đảm bảo đủ thời gian để nội dung được render
  //   } catch (error: any) {
  //     showAlert({
  //       type: "error",
  //       message:
  //         error?.response?.data?.error ||
  //         error?.response?.data?.message ||
  //         error?.error ||
  //         "Lỗi khi in hóa đơn",
  //     });
  //   }
  // };

  return (
    <>
      <WrapperExpand
        data={data}
        action={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ py: 2 }}
          >
            {!data?.post_code && (
              <DichVuGiaoHang data={data} setLoad={setLoad} />
            )}
            <Button
              onClick={onOpenEdit}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color: "common.white",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Chỉnh sửa
            </Button>
            {/* <Button
              onClick={() => handlePrint(data?._id)}
              variant="contained"
              size="small"
              color="success"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color: "common.white",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              In
            </Button> */}
            <Button
              onClick={() => {
                showAlert({
                  title: "Xác nhận xóa",
                  type: "info",
                  message: "Bạn có chắc muốn xóa dòng này không ?",
                  onConfirm: handleDelete,
                });
              }}
              variant="contained"
              size="small"
              color="error"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                color: "common.white",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Xóa
            </Button>
          </Stack>
        }
      >
        <Stack gap={2} sx={{ width: "100%", maxWidth: "1000px" }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Stack gap={2}>
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Số CT"
                  value={data?.so_ct}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Ngay CT"
                  value={formatDateDisplay(data?.ngay_ct, "DD/MM/YYYY HH:mm")}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Chi nhánh"
                  value={data?.ten_kho || data?.ma_kho}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Khách hàng"
                  value={data?.ten_kh || data?.ma_kh}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Nhân viên"
                  value={data?.ten_nv || data?.ma_nv}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Kênh bán"
                  value={data?.ten_kenh || data?.ma_kenh}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="PT thanh toán"
                  value={data?.ten_pt_thanh_toan}
                />
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <Stack gap={2}>
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Tổng số lượng"
                  value={numeralCustom(data?.t_sl).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Tổng tiền hàng"
                  value={numeralCustom(data?.t_tien_nt).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="CK sản phẩm"
                  value={numeralCustom(
                    (data?.t_ck_nt || 0) - (data?.tien_ck_hd || 0)
                  ).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="CK hóa đơn"
                  value={numeralCustom(data?.tien_ck_hd).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Tổng thanh toán"
                  value={numeralCustom(data?.t_tt_nt).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Tiền thu"
                  value={numeralCustom(data?.tien_thu).format()}
                />
                <TextInput
                  readOnly
                  labelWidth="25%"
                  label="Tiền thừa"
                  value={numeralCustom(data?.phai_tra).format()}
                />
              </Stack>
            </Grid2>
          </Grid2>
          {data?.dien_giai && (
            <Stack direction="row" gap={2}>
              <Typography sx={{ fontWeight: 600 }}>Ghi chú:</Typography>
              <Typography>{data?.dien_giai}</Typography>
            </Stack>
          )}
          {data?.details?.length > 0 && (
            <Stack sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>
                Chi tiết đơn hàng:
              </Typography>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid",
                  borderColor: "divider",
                  p: 1,
                }}
              >
                <TableApp columns={columns} data={data.details || []} />
              </Box>
            </Stack>
          )}
          {!!thongTinGiaoHang && (
            <Stack direction="row" gap={2} sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: 600 }}>
                Thông tin giao hàng:
              </Typography>
              <Typography>{thongTinGiaoHang}</Typography>
            </Stack>
          )}
        </Stack>
      </WrapperExpand>
    </>
  );
}

export default ExpandSOGCAPP;
