import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../utils/theme";
import BackdropProvider from "./backdrop/BackdropProvider";
import AlertProvider from "./alert/AlertProvider";

function GlobalContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <BackdropProvider>
        <AlertProvider>{children}</AlertProvider>
      </BackdropProvider>
    </ThemeProvider>
  );
}

export default GlobalContextProvider;
