import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Box, Grid2, Stack } from "@mui/material";
import moment from "moment";
import SelectApiInput from "../../input/SelectApiInput";
import DatetimeInput from "../../input/DateTimeInput";
import TextInput from "../../input/TextInput";
import { numeralCustom } from "../../../../utils/helpers";

const schema = yup.object({
  kho: yup
    .object()
    .typeError("Vui lòng chọn kho")
    .required("Vui lòng chọn kho"),
  ca: yup.object().typeError("Vui lòng chọn ca").required("Vui lòng chọn ca"),
  thoi_gian_tu: yup
    .date()
    .typeError("Vui lòng chọn ngày bắt đầu")
    .required("Vui lòng chọn ngày bắt đầu"),
  thoi_gian_den: yup
    .date()
    .typeError("Vui lòng chọn ngày kết thúc")
    .required("Vui lòng chọn ngày kết thúc"),
});

function FormGiaoCa({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    nhan_vien: null,
    nhan_vien_nhan: null,
    kho: null,
    ca: null,
    thoi_gian_tu: null,
    thoi_gian_den: null,
    tien_hang: 0,
    tien_giao: 0,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        nhan_vien: defaultValues.nhan_vien
          ? {
              email: defaultValues.nhan_vien,
            }
          : null,
        nhan_vien_nhan: defaultValues.nhan_vien_nhan
          ? {
              email: defaultValues.nhan_vien_nhan,
            }
          : null,
        kho: defaultValues.ma_kho
          ? {
              ma_kho: defaultValues.ma_kho,
              ten_kho: defaultValues.ten_kho,
            }
          : null,
        ca: defaultValues.ma_ca
          ? {
              ma_ca: defaultValues.ma_ca,
              ten_ca: defaultValues.ten_ca,
            }
          : null,
        thoi_gian_tu: defaultValues.thoi_gian_tu
          ? moment(defaultValues.thoi_gian_tu).format("YYYY-MM-DD HH:mm")
          : "",
        thoi_gian_den: defaultValues.thoi_gian_den
          ? moment(defaultValues.thoi_gian_den).format("YYYY-MM-DD")
          : "",
      }
    : defaultData;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { kho, ca, nhan_vien, nhan_vien_nhan, ...fields } = values;
    return {
      ...fields,
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
      nhan_vien: nhan_vien?.email || "",
      nhan_vien_nhan: nhan_vien_nhan?.email || "",
      ma_ca: ca?.ma_ca || "",
      ten_ca: ca?.ten_ca || "",
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="900px"
      formTitle="giao ca"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Box sx={{ px: 1 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <Controller
                control={control}
                name="nhan_vien"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhân viên"
                    labelWidth="30%"
                    apiCode="participant"
                    placeholder="Chọn nhân viên"
                    searchFields={["email", "name"]}
                    getOptionLabel={(option) => option.email}
                    checkEqual={(value, option) =>
                      value?.email === option?.email
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="thoi_gian_tu"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatetimeInput
                      required
                      label="Thời gian từ"
                      labelWidth="30%"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.thoi_gian_tu?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="kho"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    required
                    label="Chi nhánh"
                    labelWidth="30%"
                    apiCode="dmkho"
                    placeholder="Chọn chi nhánh"
                    searchFields={["ma_kho", "ten_kho"]}
                    getOptionLabel={(option) => option.ten_kho || option.ma_kho}
                    checkEqual={(value, option) =>
                      value?.ma_kho === option?.ma_kho
                    }
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.kho?.message as string}
                  />
                )}
              />

              <Controller
                name="tien_hang"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <TextInput
                      label="Tiền hàng"
                      labelWidth="30%"
                      value={numeralCustom(value).format()}
                      onChange={(e) => {
                        const val = numeralCustom(e.target.value).value();
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <Controller
                control={control}
                name="nhan_vien"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    label="Nhân viên nhận"
                    labelWidth="30%"
                    apiCode="participant"
                    placeholder="Chọn nhân viên"
                    searchFields={["email", "name"]}
                    getOptionLabel={(option) => option.email}
                    checkEqual={(value, option) =>
                      value?.email === option?.email
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="thoi_gian_den"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatetimeInput
                      required
                      label="Thời gian đến"
                      labelWidth="30%"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.thoi_gian_den?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="ca"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    required
                    label="Ca"
                    labelWidth="30%"
                    apiCode="dmca"
                    placeholder="Chọn ca"
                    searchFields={["ma_ca", "ten_ca"]}
                    getOptionLabel={(option) => option.ten_ca || option.ma_ca}
                    checkEqual={(value, option) =>
                      value?.ma_ca === option?.ten_ca
                    }
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.ca?.message as string}
                  />
                )}
              />

              <Controller
                name="tien_giao"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <TextInput
                      label="Tiền giao"
                      labelWidth="30%"
                      value={numeralCustom(value).format()}
                      onChange={(e) => {
                        const val = numeralCustom(e.target.value).value();
                        onChange(val);
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </ModalForm>
  );
}

export default FormGiaoCa;
