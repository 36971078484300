import React, { useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2 } from "@mui/material";
import NumberInput from "../../../input/NumberInput";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface IDinhMucSection {
  register?: UseFormRegister<FieldValues> | undefined;
}

function DinhMucSection({ register }: IDinhMucSection) {
  const [show, setShow] = useState<boolean>(true);

  return (
    <CollapseSection
      title="Định mức tồn"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <NumberInput
            label="Tối thiểu"
            name="ton_toi_thieu"
            register={register}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <NumberInput label="Tối đa" name="ton_toi_da" register={register} />
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default DinhMucSection;
