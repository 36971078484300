import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { asyncGetReport } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { groupBy } from "../../../utils/helpers";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { isArray } from "lodash";
import { useBackdropContext } from "../../../contexts/backdrop/BackdropProvider";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts";

function DoanhThuThang() {
  const theme = useTheme();
  const token = useSelector((state: any) => state.auth.token);
  const [data, setData] = useState<any[]>([]);
  const { showAlert } = useAlertContext();
  const { setOpenBackdrop } = useBackdropContext();

  const getReport = async () => {
    try {
      setOpenBackdrop?.(true);
      const today = moment();
      const resp = await asyncGetReport({
        apiCode: "ctbanle",
        token,
        query: {
          tu_ngay: today.clone().startOf("month").toISOString(),
          den_ngay: today
            .clone()
            .endOf("month")
            .hours(23)
            .minutes(59)
            .seconds(59)
            .milliseconds(999)
            .toISOString(),
        },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message: resp?.data?.error || resp?.data?.message,
        });
        return;
      }
      const data = resp?.data || [];
      data.pop();
      const days = groupBy({
        data: data,
        callbackMatch: (item) => item.ngay,
        returnKey: true,
      }) as { [key: string]: any[] };
      const numberDayOfMonth = moment().daysInMonth();

      const result = [];
      for (let i = 1; i <= numberDayOfMonth; i++) {
        if (days[i] && isArray(days[i]) && days[i].length > 0) {
          const doanhThu = days[i].reduce((acc, item) => {
            return acc + item.tien;
          }, 0);
          result.push({
            day: i,
            doanh_thu: doanhThu,
          });
        } else {
          result.push({ day: i, doanh_thu: 0 });
        }
      }
      setData(result);
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  const showChart = useMemo(() => {
    return (data || []).some((item) => item.doanh_thu > 0);
  }, [data]);

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ p: 2, borderRadius: "10px", backgroundColor: "background.paper" }}
    >
      <Typography sx={{ fontWeight: 600, mb: 1 }}>
        DOANH THU THÁNG NÀY
      </Typography>
      <Box>
        {!showChart ? (
          <Typography sx={{ py: 2, textAlign: "center" }}>
            Chưa có doanh thu
          </Typography>
        ) : (
          <BarChart
            dataset={data}
            xAxis={[
              { scaleType: "band", dataKey: "day", tickPlacement: "middle" },
            ]}
            series={[
              {
                dataKey: "doanh_thu",
                label: "Doanh thu",
                color: theme.palette.primary.main,
              },
            ]}
            margin={{ top: 50, bottom: 30, left: 80, right: 0 }}
            borderRadius={4}
            height={500}
          />
        )}
      </Box>
    </Box>
  );
}

export default DoanhThuThang;
