import React, { useEffect, useState } from "react";
import { Button, Menu, Stack, TextField, Typography } from "@mui/material";
import { numeralCustom } from "../../../../../../utils/helpers";
import TotalLine from "../../../../../../components/common/total-line";
import { LoadingButton } from "@mui/lab";

type ChietKhauType = {
  tien_ck: number;
  ty_le_ck: number;
};
interface IPrice {
  giaGoc: number;
  chietKhau: ChietKhauType;
  loading?: boolean;
  onChietKhauChange: (val: ChietKhauType) => void;
}

function Price({ giaGoc, chietKhau, loading, onChietKhauChange }: IPrice) {
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);
  const [isPercent, setIsPercent] = useState<boolean>(false);
  const [giaTriChietKhau, setGiaTriChietKhau] =
    useState<ChietKhauType>(chietKhau);
  const giaBan = (giaGoc || 0) - (chietKhau.tien_ck || 0);

  const handleCloseMenu = () => {
    setAnchorMenu(null);
    setGiaTriChietKhau(chietKhau);
  };

  const handleSaveCk = async () => {
    onChietKhauChange(giaTriChietKhau);
    setAnchorMenu(null);
  };

  const handleGiaTriChietKhauChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = 0;
    if (isPercent) {
      value = +e.target.value;
      if (value > 100) {
        value = 100;
      }
      const tienCk = +((giaGoc * value) / 100).toFixed(0);
      setGiaTriChietKhau({ tien_ck: tienCk, ty_le_ck: value });
    } else {
      value = numeralCustom(e.target.value).value() || 0;
      if (value > giaGoc) {
        value = giaGoc;
      }
      const tyLeCk = (value * 100) / giaGoc;
      setGiaTriChietKhau({ tien_ck: value, ty_le_ck: tyLeCk });
    }
  };

  useEffect(() => {
    setGiaTriChietKhau(chietKhau);
  }, [chietKhau]);

  return (
    <>
      <Menu
        open={!!anchorMenu}
        anchorEl={anchorMenu}
        onClose={handleCloseMenu}
        slotProps={{ paper: { elevation: 1, sx: { width: "300px" } } }}
      >
        <Stack gap={1} sx={{ px: 2 }}>
          <TotalLine label="Giá gốc" value={giaGoc} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ fontWeight: 600 }}>Giảm giá</Typography>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Button
                onClick={() => setIsPercent(!isPercent)}
                size="small"
                variant="contained"
                sx={{
                  boxShadow: "none",
                  px: 1,
                  py: 0.5,
                  fontSize: "12px",
                  fontWeight: 400,
                  textTransform: "none",
                  minWidth: "unset",
                  color: "common.white",
                  width: "42px",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                {isPercent ? "%" : "VND"}
              </Button>
              {isPercent ? (
                <TextField
                  type="number"
                  variant="standard"
                  autoComplete="off"
                  value={giaTriChietKhau.ty_le_ck}
                  onChange={handleGiaTriChietKhauChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "right",
                      width: "80px",
                    },
                  }}
                />
              ) : (
                <TextField
                  variant="standard"
                  autoComplete="off"
                  value={numeralCustom(giaTriChietKhau.tien_ck).format()}
                  onChange={handleGiaTriChietKhauChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      textAlign: "right",
                      width: "80px",
                    },
                  }}
                />
              )}
              <Typography>{isPercent ? "%" : "đ"}</Typography>
            </Stack>
          </Stack>
          <TotalLine label="Giá bán" value={giaGoc - giaTriChietKhau.tien_ck} />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
            sx={{ borderTop: "1px dashed", borderColor: "divider", pt: 1 }}
          >
            <Button
              onClick={handleCloseMenu}
              variant="contained"
              size="small"
              color="error"
              sx={{
                boxShadow: "none",
                px: 1,
                py: 0.5,
                color: "common.white",
                textTransform: "none",
                fontWeight: 400,
                "&:hover": { boxShadow: "none" },
              }}
            >
              Hủy
            </Button>
            <Button
              onClick={handleSaveCk}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                px: 1,
                py: 0.5,
                color: "common.white",
                textTransform: "none",
                fontWeight: 400,
                "&:hover": { boxShadow: "none" },
              }}
            >
              Lưu
            </Button>
          </Stack>
        </Stack>
      </Menu>
      <Stack direction="row" justifyContent="center" sx={{ width: "100%" }}>
        <LoadingButton
          loading={loading}
          size="small"
          variant="text"
          onClick={(e) => setAnchorMenu(e.currentTarget)}
          sx={
            !!anchorMenu
              ? {
                  color: "common.white",
                  backgroundColor: "primary.main",
                  fontSize: "14px",
                  fontWeight: "400",
                }
              : {
                  color:
                    chietKhau?.ty_le_ck > 0 || chietKhau.tien_ck > 0
                      ? "success.main"
                      : "text.primary",
                  fontSize: "14px",
                  fontWeight: "400",
                }
          }
        >
          {numeralCustom(giaBan).format()}
        </LoadingButton>
      </Stack>
    </>
  );
}

export default Price;
