import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import moment from "moment";
import InfoSection from "./info-section/InfoSection";
import DescriptionSection from "./description-section/DescriptionSection";

const schema = yup.object({
  khach_hang: yup
    .object()
    .typeError("Vui lòng chọn người nộp")
    .required("Vui lòng chọn người nộp"),
  kho: yup
    .object()
    .typeError("Vui lòng chọn kho")
    .required("Vui lòng chọn kho"),
  ngay_ct: yup
    .date()
    .typeError("Vui lòng chọn ngày chứng từ")
    .required("Vui lòng chọn ngày chứng từ"),
  trang_thai: yup
    .object()
    .typeError("Vui lòng chọn trạng thái")
    .required("Vui lòng chọn trạng thái"),
});

function FormPT1({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    so_ct: "",
    ngay_ct: moment().format("YYYY-MM-DD HH:mm"),
    kho: null,
    trang_thai: null,
    khach_hang: null,
    t_tt_nt: 0,
    dien_giai: "",
    tk_no: "1111",
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
        ngay_ct: defaultValues.ngay_ct
          ? moment().format("YYYY-MM-DD HH:mm")
          : "",
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { khach_hang, kho, trang_thai, t_tt_nt, ...fields } = values;
    const result = {
      ...fields,
      ma_kh: khach_hang?.ma_kh || "",
      ten_kh: khach_hang?.ten_kh || "",
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
      trang_thai: trang_thai?.ma_trang_thai || "",
      ten_trang_thai: trang_thai?.ten_trang_thai || "",
      details: [
        {
          ma_kh: khach_hang?.ma_kh || "",
          tien_nt: t_tt_nt,
          tk_co: "131",
        },
      ],
    };
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="900px"
      formTitle="phiếu thu"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
        <DescriptionSection register={register} />
      </Stack>
    </ModalForm>
  );
}

export default FormPT1;
