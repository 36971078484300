import { Grid2, Stack } from "@mui/material";
import React from "react";
import TextInput from "../../../input/TextInput";

function InfoSection({ data }: { data: any }) {
  return (
    <Stack gap={2} sx={{ width: "100%", maxWidth: "1000px" }}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              readOnly
              labelWidth="25%"
              label="Tên nhóm"
              value={data?.group_name}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Stack>
  );
}

export default InfoSection;
