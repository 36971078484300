import React from "react";
import { Route, Routes } from "react-router-dom";
import { privateRoutes, publicRoutes, restrictedRoutes } from ".";

function RenderRoute() {
  return (
    <Routes>
      {/* public route */}
      {publicRoutes.map((route) => {
        return (
          <Route
            key={route.id}
            path={route.path}
            element={<route.component />}
          ></Route>
        );
      })}
      {/* restricted route */}
      {restrictedRoutes.map((route) => {
        return (
          <Route
            key={route.id}
            path={route.path}
            element={<route.component />}
          ></Route>
        );
      })}
      {/* private route */}
      {privateRoutes.map((route) => {
        return (
          <Route
            key={route.id}
            path={route.path}
            element={<route.component />}
          ></Route>
        );
      })}
    </Routes>
  );
}

export default RenderRoute;
