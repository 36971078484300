import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterSelectApi from "../FilterSelectApi";
import FilterContainer from "../FilterContainer";
import FilterRadios from "../FilterRadios";
import moment from "moment";
import { IFilterProps } from "../../../../types/filter.type";

const trangThais = [
  {
    label: "Tất cả",
    value: "0",
  },
  {
    label: "Đang áp dụng",
    value: "1",
  },
  {
    label: "Ngưng áp dụng",
    value: "2",
  },
];
const hieuLucs = [
  {
    label: "Chưa đến ngày",
    value: "pending",
  },
  {
    label: "Đang áp dụng",
    value: "active",
  },
  {
    label: "Hết hiệu lực",
    value: "inactive",
  },
];

function FilterDMKHUYENMAI({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    kho: null,
    vat_tu: null,
    khach_hang: null,
    nhom_khach: null,
    phat_hanh: "0",
    hieu_luc: "active",
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    if (filter.khach_hang) {
      condition.ma_kh = filter.khach_hang.ma_kh;
    }
    if (filter.nhom_khach) {
      condition.nh_kh = filter.nhom_khach._id;
    }
    const today = moment().seconds(0).milliseconds(0);
    switch (filter.hieu_luc) {
      case "pending":
        condition.hieu_luc_tu = { $gt: today };
        break;
      case "inactive":
        condition.hieu_luc_den = { $lt: today };
        break;
      default: // acitve
        condition.hieu_luc_tu = { $lte: today };
        condition.hieu_luc_den = { $gte: today };
        break;
    }
    switch (filter.phat_hanh) {
      case "1":
        condition.status = true;
        break;
      case "2":
        condition.status = false;
        break;
      default:
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          apiCode="dmkho"
          placeholder="Chọn chi nhánh"
          searchFields={["ma_kho", "ten_kho"]}
          value={filter.kho}
          onChange={(val) => setFilter({ ...filter, kho: val })}
          getOptionLabel={(option) => option.ten_kho}
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
        />
        <FilterSelectApi
          direction="column"
          title="Sản phẩm"
          apiCode="dmvt"
          placeholder="Chọn Sản phẩm"
          searchFields={["ma_vt", "ten_vt"]}
          value={filter.vat_tu}
          onChange={(val) => setFilter({ ...filter, vat_tu: val })}
          getOptionLabel={(option) => option.ten_vt}
          checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
        />
        <FilterRadios
          values={hieuLucs}
          defaultValue={filter.hieu_luc}
          title="Thời gian áp dụng"
          onChange={(val) => setFilter({ ...filter, hieu_luc: val })}
        />
        <FilterRadios
          values={trangThais}
          defaultValue={filter.phat_hanh}
          title="Trạng thái"
          onChange={(val) => setFilter({ ...filter, phat_hanh: val })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDMKHUYENMAI;
