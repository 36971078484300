import React, { useEffect, useRef, useState } from "react";
import WrapperInput, { IWrapperInput } from "./WrapperInput";
import {
  Box,
  Container,
  Divider,
  Drawer,
  FormHelperText,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import {
  LuChevronLeft,
  LuChevronsUpDown,
  LuPlusCircle,
  LuSearch,
  LuX,
  LuXCircle,
} from "react-icons/lu";
import ModalBase from "../modal/ModalBase";
import { asyncGetList, asyncSearchList } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { isArray } from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { IForm } from "../../../types/form.type";
import { v4 } from "uuid";
import { MOBILE_CONTAINER } from "../../../utils/ui.constants";

export interface ISelectApiInput extends Omit<IWrapperInput, "children"> {
  apiCode: string;
  searchFields: string[];
  getOptionLabel: (option: any) => React.ReactNode;
  checkEqual: (value: any, option: any) => boolean;
  // optional
  placeholder?: string;
  placeholderSearch?: string;
  errorMessage?: string | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  wrapperSx?: SxProps;
  value?: any;
  defaultCondition?: { [key: string]: any };
  requestMethod?: "get" | "post";
  applyToken?: boolean;
  withIdApp?: boolean;
  query?: { [key: string]: any };
  Form?: React.ElementType<IForm>;
  forceOpenSelect?: boolean;
  selectTitle?: string;
  closeOnSelect?: boolean;
  useDrawer?: boolean;
  fixedScrollHeight?: string;
  onChange?: (val: any) => void;
  renderOption?: (
    option: any,
    optionProps: { key: string | number; isEqual: boolean; onClick: any }
  ) => React.ReactNode;
  renderDisplay?: ({
    onClick,
    value,
  }: {
    onClick: React.MouseEventHandler<any>;
    value: any;
  }) => React.ReactNode;
  renderTopList?: () => React.ReactNode;
  renderBottom?: (props: { onClose: () => void }) => React.ReactNode;
}

const heights = {
  input: "45px",
};

function SelectApiInput({
  label,
  labelWidth = "20%",
  id,
  placeholder,
  placeholderSearch,
  required,
  errorMessage,
  disabled,
  readOnly,
  wrapperSx,
  value,
  apiCode,
  defaultCondition,
  requestMethod = "post",
  applyToken = true,
  withIdApp = true,
  query,
  searchFields,
  Form,
  forceOpenSelect = false,
  selectTitle,
  closeOnSelect = true,
  useDrawer,
  fixedScrollHeight,
  onChange,
  getOptionLabel,
  checkEqual,
  renderDisplay,
  renderOption,
  renderTopList,
  renderBottom,
  ...wrapperProps
}: ISelectApiInput) {
  const { showAlert } = useAlertContext();

  const token = useSelector((state: any) => state.auth.token);
  const currentApp = useSelector((state: any) => state.app.data);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<number>(0);
  const [text, setText] = useState<string>("");
  const [paginationOptions, setPaginationOptions] = useState<{
    page: number;
    limit: number;
    totalRows: number;
  }>({
    page: 1,
    limit: 20,
    totalRows: 0,
  });
  const textRef = useRef("");

  const getOptions = async () => {
    if (loading) return;
    setLoading(true);
    const selfCondition = {
      page: paginationOptions.page,
      limit: paginationOptions.limit,
      q: { ...defaultCondition },
    };
    if (text && isArray(searchFields) && searchFields.length > 0) {
      selfCondition.q.$or = [];
      searchFields.forEach((searchFiled) =>
        selfCondition.q.$or.push({
          [searchFiled]: {
            $regex: text.split(" ").join(".*"),
            $options: "i",
          },
        })
      );
    }
    const functionGet =
      requestMethod === "post" ? asyncSearchList : asyncGetList;
    const requestOptions = {
      apiCode,
      applyToken,
      token,
      condition: selfCondition,
      withIdApp,
      idApp: currentApp?._id,
      query,
    };
    const request = functionGet(requestOptions);
    const requestCount = functionGet({
      ...requestOptions,
      condition: { ...requestOptions.condition, count: 1 },
    });
    Promise.all([request, requestCount])
      .then(([resp, respCount]) => {
        if (resp.status !== 200) {
          showAlert({
            type: "error",
            message:
              resp?.data?.message || resp?.data?.error || "Lỗi khi tải dữ liệu",
          });
          return;
        }
        if (respCount.status !== 200) {
          showAlert({
            type: "error",
            message:
              respCount?.data?.message ||
              respCount?.data?.error ||
              "Lỗi khi tải dữ liệu",
          });
          return;
        }
        setOptions([...options, ...resp.data]);
        setPaginationOptions({
          ...paginationOptions,
          page: paginationOptions.page + 1,
          totalRows: respCount.data?.rows_number || 0,
        });
      })
      .catch(() => {
        showAlert({
          type: "error",
          message: `Lỗi khi tải dữ liệu '${apiCode}'`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const hasNextPage = options.length < paginationOptions.totalRows;
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => getOptions(),
  });

  const handleCloseSelect = () => {
    setOpenSelect(false);
    setOptions([]);
    setPaginationOptions({ page: 1, limit: 20, totalRows: 0 });
    setText("");
  };

  const handleSelect = (option: any) => {
    onChange?.(option);
    if (closeOnSelect) {
      handleCloseSelect();
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange?.(null);
  };
  const handleOpenForm = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenForm(true);
  };

  const applySearch = () => {
    if (textRef.current === text) return;
    textRef.current = text;
    setPaginationOptions({ ...paginationOptions, page: 1, totalRows: 0 });
    setOptions([]);
    setLoad(load + 1);
  };

  const renderLoading = (sentryRef?: React.Ref<any>) => {
    return (
      <ListItemButton ref={sentryRef} sx={{ gap: 1 }}>
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ width: "10%", height: "20px" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ width: "50%", height: "20px" }}
        />
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ width: "20%", height: "20px" }}
        />
      </ListItemButton>
    );
  };

  useEffect(() => {
    if (apiCode && openSelect) {
      getOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode, openSelect, load]);

  return (
    <>
      {!!Form && openForm && (
        <Form
          apiCode={apiCode}
          open={openForm}
          onClose={() => setOpenForm(false)}
          onSuccess={(c) => {
            onChange?.(c);
            handleCloseSelect();
          }}
        />
      )}
      {useDrawer ? (
        <Drawer
          anchor="top"
          open={openSelect}
          onClose={handleCloseSelect}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "100vw",
              height: "100vh",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.paper",
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            <Container maxWidth="sm">
              <Stack
                sx={{ width: "100%", height: "50px" }}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <IconButton sx={{ padding: "0px" }} onClick={handleCloseSelect}>
                  <LuChevronLeft size={25} />
                </IconButton>
                <TextField
                  fullWidth
                  placeholder={placeholderSearch || "Tìm và chọn"}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      applySearch();
                    }
                  }}
                  InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                      <Stack direction="row">
                        {!text ? (
                          <IconButton sx={{ padding: "0px" }}>
                            <LuSearch size={25} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => setText("")}
                            sx={{ padding: "0px" }}
                          >
                            <LuX size={25} />
                          </IconButton>
                        )}
                      </Stack>
                    ),
                  }}
                  sx={{
                    height: "100%",
                    "& .MuiInputBase-root": {
                      paddingRight: "0px",
                      "& .MuiInputBase-input": {
                        height: "50px",
                        caretColor: "green",
                        px: 0,
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Stack>
            </Container>
            <Divider sx={{ height: "1px" }} />
            {!!renderTopList ? renderTopList() : null}
            <Container maxWidth={MOBILE_CONTAINER}>
              <List
                sx={{
                  height: fixedScrollHeight || "calc(100vh - 50px - 1px)",
                  overflow: "auto",
                }}
              >
                {options?.length === 0 && loading && renderLoading()}
                {isArray(options) &&
                  options.map((option) => {
                    const key = option._id || option.id || v4();
                    const isEqual = checkEqual(value, option);
                    if (!!renderOption) {
                      return renderOption(option, {
                        key,
                        isEqual,
                        onClick: () => handleSelect(isEqual ? null : option),
                      });
                    }
                    return (
                      <ListItemButton
                        key={key}
                        onClick={() => handleSelect(isEqual ? null : option)}
                        sx={{
                          backgroundColor: isEqual ? "primary.100" : "",
                          "&:hover": {
                            backgroundColor: isEqual ? "primary.100" : "",
                          },
                        }}
                      >
                        <ListItemText>{getOptionLabel(option)}</ListItemText>
                      </ListItemButton>
                    );
                  })}
                {hasNextPage && renderLoading(sentryRef)}
              </List>
            </Container>
            {!!renderBottom
              ? renderBottom({ onClose: handleCloseSelect })
              : null}
          </Box>
        </Drawer>
      ) : (
        <ModalBase
          open={openSelect}
          handleClose={!forceOpenSelect ? handleCloseSelect : () => {}}
          hideCloseIcon={!!forceOpenSelect}
          width="600px"
          title={selectTitle || "Tìm và chọn"}
        >
          <Stack sx={{ px: 2 }} gap={1}>
            {/* Search */}
            <Stack direction="row" alignItems="center" gap={1}>
              <TextField
                variant="outlined"
                fullWidth
                autoFocus
                autoComplete="off"
                spellCheck="false"
                placeholder={placeholderSearch || "Nhập từ khóa tìm kiếm"}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    applySearch();
                  }
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    border: "1px solid",
                    borderColor: "divider",
                    "&:focus-within": {
                      outline: "1px solid",
                      outlineColor: "primary.main",
                      borderColor: "primary.main",
                    },
                    "& .MuiInputBase-input": {
                      px: 2,
                      py: 1,
                      height: "45px",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
                slotProps={{
                  input: {
                    endAdornment: (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ color: "text.primary" }}
                      >
                        <IconButton onClick={applySearch}>
                          <LuSearch size={20} />
                        </IconButton>
                      </Stack>
                    ),
                  },
                }}
              />
              {!!Form && (
                <IconButton
                  onClick={() => setOpenForm(true)}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "common.white",
                    borderRadius: "10px",
                    "&:hover": { backgroundColor: "primary.main" },
                  }}
                >
                  <LuPlusCircle size={16} />
                </IconButton>
              )}
            </Stack>
            {/* Search end */}
            <Divider />
            {/* List */}
            {isArray(options) && options.length > 0 ? (
              <List sx={{ maxHeight: "60vh", overflow: "auto" }}>
                {options.map((option, index) => {
                  const isEqual = !!value ? checkEqual?.(value, option) : false;
                  return (
                    <ListItemButton
                      key={index}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: isEqual ? "primary.600" : "",
                      }}
                      onClick={() => handleSelect(isEqual ? null : option)}
                    >
                      <ListItemText>{getOptionLabel(option)}</ListItemText>
                    </ListItemButton>
                  );
                })}
                {hasNextPage && renderLoading(sentryRef)}
              </List>
            ) : (
              <>
                {loading ? (
                  renderLoading()
                ) : (
                  <Typography sx={{ textAlign: "center" }}>
                    Không tìm thấy kết quả
                  </Typography>
                )}
              </>
            )}
            {/* List end */}
          </Stack>
        </ModalBase>
      )}
      {/* Input */}
      {!!renderDisplay ? (
        renderDisplay({ value, onClick: () => setOpenSelect(true) })
      ) : (
        <WrapperInput
          id={id}
          label={label}
          required={required}
          labelWidth={labelWidth}
          {...wrapperProps}
        >
          <Stack sx={{ width: "100%" }}>
            <Box
              onClick={
                readOnly || disabled ? undefined : () => setOpenSelect(true)
              }
              sx={{
                width: "100%",
                height: heights.input,
                border: "2px solid",
                borderColor: "divider",
                borderRadius: "10px",
                cursor: "pointer",
                px: 1,
                backgroundColor: disabled ? "background.default" : "",
                "&:hover": {
                  filter: "brightness(90%)",
                },
                "&:hover .select-btn": { visibility: "visible" },
                ...wrapperSx,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Typography
                  className="one-line-ellipsis"
                  sx={{ flex: 1, opacity: !!value ? 1 : 0.3 }}
                >
                  {!!value ? getOptionLabel(value) : placeholder}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ color: "text.secondary" }}
                >
                  {!!value ? (
                    <>
                      {!readOnly && !disabled && (
                        <IconButton
                          onClick={handleDelete}
                          className="select-btn"
                          color="error"
                          sx={{ visibility: "hidden" }}
                        >
                          <LuXCircle size={16} />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <>
                      {!!Form && (
                        <IconButton
                          onClick={handleOpenForm}
                          className="select-btn"
                          color="success"
                          sx={{ visibility: "hidden" }}
                        >
                          <LuPlusCircle size={16} />
                        </IconButton>
                      )}
                    </>
                  )}
                  <LuChevronsUpDown size={14} />
                </Stack>
              </Stack>
            </Box>
            {errorMessage && (
              <FormHelperText error sx={{ mx: 1.75, fontStyle: "italic" }}>
                {errorMessage}
              </FormHelperText>
            )}
          </Stack>
        </WrapperInput>
      )}
    </>
  );
}

export default SelectApiInput;
