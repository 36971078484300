import React from "react";
import WrapperInput, { IWrapperInput } from "./WrapperInput";
import { TextField, TextFieldProps } from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";

export interface ITextInput extends Omit<IWrapperInput, "children"> {
  placeholder?: string;
  multiline?: boolean;
  errorMessage?: string | undefined;
  name?: TextFieldProps["name"];
  value?: TextFieldProps["value"];
  disabled?: boolean;
  readOnly?: boolean;
  endAdornment?: React.ReactNode;
  maxRows?: number;
  minRows?: number;
  textfieldProps?: TextFieldProps;
  onChange?: TextFieldProps["onChange"];
  register?: UseFormRegister<FieldValues> | undefined;
  onBlur?: TextFieldProps["onBlur"];
  onKeyUp?: TextFieldProps["onKeyUp"];
}

const heights = {
  input: "45px",
};

function TextInput({
  label,
  labelWidth = "20%",
  id,
  placeholder,
  multiline = true,
  required,
  errorMessage,
  name,
  value,
  disabled,
  readOnly,
  endAdornment,
  maxRows,
  minRows,
  textfieldProps,
  register,
  onChange,
  onBlur,
  onKeyUp,
  ...wrapperProps
}: ITextInput) {
  return (
    <WrapperInput
      id={id}
      label={label}
      required={required}
      labelWidth={labelWidth}
      {...wrapperProps}
    >
      <TextField
        id={id}
        name={name}
        multiline={multiline}
        fullWidth
        placeholder={placeholder}
        autoComplete="off"
        spellCheck={false}
        helperText={errorMessage}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        slotProps={{
          input: { readOnly, disabled, endAdornment, maxRows, minRows },
        }}
        {...(name ? register?.(name) : {})}
        sx={{
          "& .MuiFormHelperText-root": {
            color: "error.main",
            fontStyle: "italic",
          },
          "& .MuiInputBase-root": {
            height: multiline ? "" : heights.input,
            px: 2,
            py: multiline ? 0.3125 : 0,
            border: "2px solid",
            borderColor: "divider",
            "&:focus-within": {
              borderColor: "primary.main",
            },
            "& .MuiInputBase-input": {
              height: heights.input,
              px: 0,
              py: 1,
            },
            "& fieldset": { border: "none" },
          },
        }}
        {...textfieldProps}
      />
    </WrapperInput>
  );
}

export default TextInput;
