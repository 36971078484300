import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { NAVBAR_HEIGHT, NAVBAR_ITEMS } from "../../../../utils/ui.constants";
import NavItem from "../../nav-item";
import SelectStore from "./SelectStore";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();

  return (
    <Box
      sx={{
        height: NAVBAR_HEIGHT,
        backgroundColor: "primary.main",
      }}
    >
      <Container sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ height: "100%" }}
            gap={2}
          >
            {NAVBAR_ITEMS.map((item) => {
              let isActive = false;
              if (item.subs) {
                isActive = item.subs.some((sub) => {
                  return location.pathname === sub.link;
                });
              } else if (item.link) {
                isActive = location.pathname === item.link;
              }
              return (
                <NavItem
                  key={item.text}
                  text={item.text}
                  link={item.link}
                  subs={
                    !!item.subs
                      ? item.subs.map((sub) => {
                          const active = location.pathname === sub.link;
                          return {
                            ...sub,
                            sx: {
                              color: "common.white",
                              backgroundColor: active ? "#00000011" : "",
                              "& .MuiTypography-root": {
                                color: "common.white",
                              },
                            },
                          };
                        })
                      : undefined
                  }
                  startIcon={item.startIcon}
                  endIcon={item.endIcon}
                  sx={{
                    height: "42px",
                    color: "common.white",
                    backgroundColor: isActive ? "#00000011" : "",
                  }}
                  paperSx={{ backgroundColor: "primary.main" }}
                />
              );
            })}
          </Stack>
          <SelectStore />
        </Stack>
      </Container>
    </Box>
  );
}

export default Navbar;
