import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import CheckboxInput from "../../../input/CheckboxInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              required
              label="Mã"
              labelWidth="25%"
              placeholder="Nhập mã chi nhánh"
              name="ma_kho"
              register={register}
              errorMessage={errors?.ma_kho?.message as string}
            />
            <TextInput
              required
              label="Tên"
              labelWidth="25%"
              placeholder="Nhập tên chi nhánh"
              name="ten_kho"
              register={register}
              errorMessage={errors?.ten_kho?.message as string}
            />
            <Controller
              name="status"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Còn hoạt động"
                    checked={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              label="Điện thoại"
              labelWidth="25%"
              placeholder="Nhập số điện thoại"
              name="dien_thoai"
              register={register}
            />
            <TextInput
              label="Email"
              labelWidth="25%"
              placeholder="Nhập email"
              name="email"
              register={register}
              errorMessage={errors?.email?.message as string}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
