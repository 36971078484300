import { createTheme } from "@mui/material/styles";
import { viVN } from "@mui/material/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#ECC53A",
        900: "#ECC53Add",
        800: "#ECC53Aaa",
        700: "#ECC53A77",
        600: "#ECC53A44",
        500: "#ECC53A22",
      },
      secondary: {
        main: "#6F49FD",
        900: "#8C6DFD",
        800: "#A992FE",
        700: "#C5B6FE",
        600: "#E2DBFF",
      },
      info: {
        main: "#3DC2EC",
      },
      warning: {
        main: "#FF7F3E",
        900: "#F4CE14",
        200: "#FFF455",
        100: "#FAFFAF",
      },
      error: {
        main: "#FF0000",
        200: "#FFCAD4",
      },
      success: {
        main: "#06D001",
      },
      background: {
        default: "#eef2f6",
        paper: "#fdfdfd",
      },
      divider: "#c3c3c366",
      common: {
        white: "#ffffff",
        black: "#333333",
      },
      text: {
        primary: "rgb(54,65,82)",
      },
    },
    zIndex: {
      fab: 4000,
      drawer: 5000,
      modal: 6000,
      tooltip: 7000,
      snackbar: 8000,
      speedDial: 9000,
    },
    typography: {
      fontFamily: "inherit",
      body1: {
        fontSize: 14,
      },
      body2: {
        fontSize: 16,
      },
      subtitle1: {
        fontSize: 14,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 500,
      },
      h6: {
        fontSize: 18,
        fontWeight: 600,
      },
      h5: {
        fontSize: 20,
        fontWeight: 600,
      },
      h4: {
        fontSize: 22,
        fontWeight: 600,
      },
      h3: {
        fontSize: 24,
        fontWeight: 600,
      },
      h2: {
        fontSize: 26,
        fontWeight: 600,
      },
      h1: {
        fontSize: 28,
        fontWeight: 600,
      },
    },
    shape: { borderRadius: 10 },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiContainer: {
        defaultProps: {maxWidth: 'xl'}
      }
    },
  },
  viVN
);
export default theme;
