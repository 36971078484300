import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterDMNHKH({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = { nhom_khach: "" };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition: { [key: string]: any } = {};
    if (filter.nhom_khach) {
      condition.group_name = { $regex: filter.nhom_khach, $options: "i" };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Tên nhóm khách"
          placeholder="Tìm theo tên nhóm khách"
          value={filter.khach_hang}
          onSearch={(value) => setFilter({ ...filter, khach_hang: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDMNHKH;
