import React from "react";
import WrapperInput, { IWrapperInput } from "./WrapperInput";
import {
  DatePickerProps,
  DateTimePicker,
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { LuCalendarClock } from "react-icons/lu";
import moment from "moment";
import { FormHelperText, Stack } from "@mui/material";

interface IDatetimeInput extends Omit<IWrapperInput, "children"> {
  errorMessage?: string | undefined;
  name?: DatePickerProps<any>["name"];
  value?: DatePickerProps<any>["value"];
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (
    value: moment.Moment | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) => void;
}

const heights = {
  input: "45px",
};

function DatetimeInput({
  label,
  labelWidth = "20%",
  id,
  required,
  errorMessage,
  name,
  value,
  disabled,
  readOnly,
  onChange,
  ...wrapperProps
}: IDatetimeInput) {
  return (
    <WrapperInput
      id={id}
      label={label}
      required={required}
      labelWidth={labelWidth}
      {...wrapperProps}
    >
      <Stack>
        <DateTimePicker
          value={!!value ? moment(value) : null}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          dayOfWeekFormatter={(date) => date.format("ddd")}
          format="DD/MM/YYYY &#x23F0; HH:mm"
          formatDensity="spacious"
          slots={{
            openPickerIcon: () => <LuCalendarClock size={16} />,
            clearButton: () => <div>x</div>,
          }}
          slotProps={{
            textField: {
              variant: "outlined",
              fullWidth: true,
              size: "small",
              name,
              sx: {
                "& .MuiFormHelperText-root": {
                  color: "error.main",
                  fontStyle: "italic",
                },
                "& .MuiInputBase-root": {
                  height: heights.input,
                  pr: 2,
                  py: 0,
                  border: "2px solid",
                  borderColor: !!errorMessage ? "error.main" : "divider",
                  "&:focus-within": {
                    borderColor: !!errorMessage ? "error.main" : "primary.main",
                  },
                  "& .MuiInputBase-input": {
                    height: heights.input,
                    pr: 0,
                    pl: 2,
                    py: 1,
                  },
                  "& fieldset": { border: "none", px: 0 },
                },
              },
            },
          }}
        />
        {!!errorMessage && (
          <FormHelperText sx={{ fontStyle: "italic", color: "error.main" }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Stack>
    </WrapperInput>
  );
}

export default DatetimeInput;
