import React, { useEffect } from "react";
import { Button, SxProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentStore } from "../../../../redux/reducers/config.reducer";
import SelectApiInput from "../../input/SelectApiInput";

interface ISelectStore {
  displaySx?: SxProps;
}

function SelectStore({ displaySx }: ISelectStore) {
  const currentStore = useSelector((state: any) => state.config.currentStore);
  const dispatch = useDispatch();

  const selectStore = (store: any) => {
    dispatch(updateCurrentStore(store));
  };

  useEffect(() => {
    if (!currentStore) {
      const displayStore = document.getElementById("display-store");
      displayStore?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  return (
    <>
      <SelectApiInput
        apiCode="dmkho"
        searchFields={["ma_kho", "ten_kho"]}
        checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
        getOptionLabel={(option) => option.ten_kho}
        forceOpenSelect={!currentStore}
        selectTitle="Chọn chi nhánh"
        placeholderSearch="Tìm theo tên chi nhánh"
        value={currentStore}
        onChange={(val) => selectStore(val)}
        renderDisplay={({ value, onClick }) => {
          return (
            <Button
              id="display-store"
              onClick={onClick}
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none",
                backgroundColor: "background.paper",
                color: "text.primary",
                textTransform: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "background.paper",
                  color: "text.primary",
                },
                ...displaySx,
              }}
            >
              {currentStore?.ten_kho || "Chưa xác định"}
            </Button>
          );
        }}
      />
    </>
  );
}

export default SelectStore;
