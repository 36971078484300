import React from "react";
import { Box, FormLabel, Stack } from "@mui/material";

export interface IWrapperInput {
  children: React.ReactNode;
  label?: string;
  labelWidth?: string;
  id?: string;
  required?: boolean;
  direction?: "row" | "column";
}

function WrapperInput({
  id,
  children,
  label,
  labelWidth,
  required,
  direction = "row",
}: IWrapperInput) {
  if (direction === "row") {
    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={1}
        sx={{ width: "100%" }}
      >
        {label && (
          <Box sx={{ width: labelWidth }}>
            <FormLabel
              htmlFor={id}
              required={required}
              sx={{
                color: "text.primary",
                fontWeight: 500,
                "& .MuiFormLabel-asterisk": { color: "error.main" },
              }}
            >
              {label}
            </FormLabel>
          </Box>
        )}
        <Box sx={{ width: `calc(100% - ${labelWidth})` }}>{children}</Box>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      gap={0.5}
      sx={{ width: "100%" }}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          required={required}
          sx={{
            color: "text.primary",
            fontWeight: 500,
            "& .MuiFormLabel-asterisk": { color: "error.main" },
          }}
        >
          {label}
        </FormLabel>
      )}
      <Box sx={{ width: "100%" }}>{children}</Box>
    </Stack>
  );
}

export default WrapperInput;
