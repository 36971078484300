import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import ModalBase from "./ModalBase";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { API_URL, ID_APP } from "../../../utils/constants";
import { isArray } from "lodash";
import { downloadFile, generateLinkImage } from "../../../utils/helpers";
import ExcelIcon from "../../../assets/images/excel.png";
import ModalExcelFile from "./ModalExcelFile";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { useBackdropContext } from "../../../contexts/backdrop/BackdropProvider";
import { LuFileUp } from "react-icons/lu";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import TableApp from "../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";

interface IModalImportExcel {
  open: boolean;
  apiCode: string;
  setLoad: Dispatch<SetStateAction<number>>;
  handleClose: () => void;
  columns: TableColumn<any>[];
}

function ModalImportExcel({
  open,
  apiCode,
  columns,
  setLoad,
  handleClose,
}: IModalImportExcel) {
  const { showAlert } = useAlertContext();
  const inputFileRef = useRef<any>();
  const { setOpenBackdrop } = useBackdropContext();
  const token = useSelector((state: any) => state.auth.token);
  const [fileExcel, setFileExcel] = useState<File>();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelFiles, setExcelFiles] = useState<any[]>([]);

  const handleUpload = () => {
    inputFileRef.current?.click();
  };

  const handleGetExcelFile = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/importexceltemplate?access_token=${token}&code=${apiCode}`
      );
      if (resp.status === 200 && resp.data) {
        const data = resp.data;
        if (isArray(data) && data.length > 0) {
          if (data.length < 2) {
            const link = generateLinkImage(data[0].file);
            if (link) {
              downloadFile({ link });
            }
          } else {
            setExcelFiles(data);
          }
        }
      }
    } catch (error: any) {
      alert(error?.error || error?.message || "Lỗi khi xuất file excel");
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    setFileExcel(file);
  };

  const handleImportFile = async () => {
    try {
      if (!fileExcel) {
        return;
      }
      setOpenBackdrop?.(true);
      setLoading(true);
      const formData = new FormData();
      formData.append("xlsx", fileExcel, fileExcel?.name);
      const resp = await axios.post(
        `${API_URL}/${ID_APP}/${apiCode}/import/excel?ma_ct=${apiCode.toUpperCase()}&update=${checked}&access_token=${token}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (resp.status === 200) {
        handleClose();
      }
    } catch (error: any) {
      if (error.response?.data?.error?.rows_error?.length > 0) {
        showAlert({
          title: "Có lỗi xảy ra",
          type: "error",
          width: "800px",
          renderContent: () => {
            return (
              <Stack sx={{ width: "100%" }}>
                <Typography>
                  Số dòng thành công:{" "}
                  {error.response?.data?.error?.rows_imported}
                </Typography>
                <Typography>
                  Số dòng thất bại:{" "}
                  {error.response?.data?.error?.rows_error?.length}
                </Typography>
                <TableApp
                  data={error.response?.data?.error?.rows_error.map(
                    (item: any) => item.row
                  )}
                  columns={columns}
                />
              </Stack>
            );
          },
        });
      } else {
        showAlert({
          type: "error",
          title: "Thông báo",
          message: "Lỗi khi nhập file excel",
        });
      }
    } finally {
      setOpenBackdrop?.(false);
      setLoading(false);
      handleClose();
      setLoad((prev: number) => prev + 1);
    }
  };

  const openExcelFiles = useMemo(() => {
    return !!(excelFiles.length >= 2);
  }, [excelFiles.length]);

  return (
    <>
      {openExcelFiles && (
        <ModalExcelFile
          open={openExcelFiles}
          handleClose={() => setExcelFiles([])}
          data={excelFiles}
        />
      )}
      <ModalBase
        width="500px"
        title="Nhập file excel"
        open={open}
        handleClose={handleClose}
        actions={[
          <LoadingButton
            variant="contained"
            size="small"
            loading={loading}
            key={1}
            onClick={handleImportFile}
            disabled={!fileExcel}
            color="primary"
            sx={{
              textTransform: "none",
              color: "common.white",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Nhập
          </LoadingButton>,
          <Button
            key={2}
            variant="contained"
            color="error"
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "none",
              color: "common.white",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <Stack alignItems="center">
          <Stack alignItems="center">
            <input
              hidden
              type="file"
              name="excel"
              accept=".xlsx, .xls"
              ref={inputFileRef}
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              onClick={handleUpload}
              startIcon={<LuFileUp size={16} />}
              sx={{
                textTransform: "none",
                boxShadow: "none",
                color: "common.white",

                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Tải lên
            </Button>
            {fileExcel && (
              <Stack alignItems="center">
                <Avatar src={ExcelIcon} sx={{ width: 100, height: 100 }} />
                <Typography sx={{ fontSize: "12px", color: "primary.main" }}>
                  {fileExcel.name}
                </Typography>
              </Stack>
            )}
          </Stack>
          {/* <FormControlLabel
            label="Ghi đè nếu trùng mã"
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                size="small"
              />
            }
          /> */}
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", marginTop: "10px" }}
          >
            Bạn chưa có file mẫu? Hãy{" "}
            <Typography
              onClick={handleGetExcelFile}
              component="span"
              sx={{
                width: "100%",
                fontSize: "14px",
                color: "primary.main",
                cursor: "pointer",
                "&:hover": { color: "secondary.main" },
              }}
            >
              tải về
            </Typography>{" "}
            và điền thông tin theo file mẫu.
          </Typography>
          <Typography
            sx={{ textAlign: "center", mt: 2, color: "secondary.main" }}
          >
            Vui lòng không thoát ra khi đang nhập file excel
          </Typography>
        </Stack>
      </ModalBase>
    </>
  );
}

export default ModalImportExcel;
