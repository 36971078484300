import React from "react";
import ModalBase from "../../../modal/ModalBase";
import { Button, Stack } from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectApiInput from "../../../input/SelectApiInput";
import TextInput from "../../../input/TextInput";

interface IFormAdd {
  open: boolean;
  isEdit?: boolean;
  defaultValue?: any;
  add?: (data: any, isEdit: boolean) => void;
  handleClose: () => void;
}

const schema = yup.object<FieldValues>({
  ma_cn: yup.string().required("Vui lòng nhập mã chức năng"),
  mau_in: yup
    .object()
    .typeError("Vui lòng chọn mẫu in")
    .required("Vui lòng chọn mẫu in"),
});

function FormAdd({ open, isEdit, defaultValue, add, handleClose }: IFormAdd) {
  const defaultData: { [key: string]: any } = {
    ma_cn: "",
    mau_in: null,
  };
  const originData = defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        mau_in: defaultValue.id_mau_in
          ? {
              ma_cn: defaultValue.ma_cn || "",
              id_mau_in: defaultValue.id_mau_in || "",
              ten_mau_in: defaultValue.ten_mau_in || "",
            }
          : null,
      }
    : defaultData;
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const maCN = watch("ma_cn");

  const generateDataAdd = (values: typeof defaultData) => {
    const { mau_in, ...fields } = values;
    return {
      ...fields,
      id_mau_in: mau_in?._id || "",
      ten_mau_in: mau_in?.ten_mau_in || "",
    };
  };

  // handle save
  const handleSave = (values: any) => {
    const dataAdd = generateDataAdd(values);
    add?.(dataAdd, !!isEdit);
    handleClose?.();
  };

  return (
    <ModalBase
      title={`${isEdit ? "Thêm" : "Sửa"} mẫu in`}
      open={open}
      handleClose={handleClose}
      width="500px"
      actions={[
        <Button
          key={1}
          onClick={handleSubmit(handleSave)}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu
        </Button>,
        <Button
          key={2}
          onClick={handleClose}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Stack gap={2} sx={{ p: 2 }}>
        <TextInput
          required
          label="Mã CN"
          labelWidth="25%"
          placeholder="Nhập tên mẫu in"
          name="ma_cn"
          register={register}
          errorMessage={errors?.ma_cn?.message as string}
        />
        <Controller
          control={control}
          name="mau_in"
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                labelWidth="25%"
                label="Mẫu in"
                placeholder="Chọn mẫu in"
                disabled={!maCN}
                value={value}
                onChange={onChange}
                apiCode="rpt"
                searchFields={["ma_cn", "ten_mau_in"]}
                defaultCondition={{ ma_cn: maCN || "" }}
                getOptionLabel={(option) => option.ten_mau_in}
                checkEqual={(value, option) => value?._id === option?._id}
                errorMessage={errors?.mau_in?.message as string}
              />
            );
          }}
        />
      </Stack>
    </ModalBase>
  );
}

export default FormAdd;
