import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import { updateHds } from "../../../../../redux/reducers/pos.reducer";
import PaymentInfo from "./payment/PaymentInfo";

interface IBottom {
  height: string;
}

function Bottom({ height }: IBottom) {
  const { hds, currentHd } = useSelector((state: any) => state.pos);
  const { showAlert } = useAlertContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleDeleteHd = () => {
    if (!currentHd) return;
    showAlert({
      type: "warning",
      title: "Xác nhận",
      message: "Bạn có chác muốn xóa hóa đơn này không ?",
      onConfirm: () => {
        const newHds = hds.filter((item: any) => item._id !== currentHd._id);
        dispatch(updateHds(newHds));
      },
    });
  };

  return (
    <>
      <PaymentInfo open={openInfo} onClose={() => setOpenInfo(false)} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: "100%",
          height,
          px: 2,
          backgroundColor: "background.paper",
        }}
      >
        <Stack>
          <Typography>
            Tạo lúc:{" "}
            {moment(currentHd?.date_created).format("HH:mm - DD/MM/YYYY")}
          </Typography>
          <Typography>Tạo bởi: {currentHd?.user_created}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            onClick={handleDeleteHd}
            variant="contained"
            color="error"
            sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}
          >
            Xóa
          </Button>
          <Button
            onClick={() => setOpenInfo(true)}
            variant="contained"
            color="primary"
            sx={{
              boxShadow: "none",
              color: "common.white",
              "&:hover": { boxShadow: "none" },
            }}
          >
            Thanh toán
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default Bottom;
