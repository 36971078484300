import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterDMDVT({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    don_vi_tinh: "",
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.don_vi_tinh) {
      condition.$or = [
        {
          ma_dvt: {
            $regex: filter.don_vi_tinh.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_dvt: {
            $regex: filter.don_vi_tinh.split(" ").join(".*"),
            $options: "i",
          },
        },
        { $text: { $search: filter.don_vi_tinh } },
      ];
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterText
          title="Mã, tên đơn vị tính"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.don_vi_tinh}
          onSearch={(value) => setFilter({ ...filter, don_vi_tinh: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDMDVT;
