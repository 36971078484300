import React, { ChangeEvent } from "react";
import { Checkbox, FormControlLabel, SxProps } from "@mui/material";
import { Stack } from "@mui/system";

interface ICheckboxInput {
  label?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  readOnly?: boolean;
  disabled?: boolean;
  wrapperSx?: SxProps;
}

function CheckboxInput({
  label,
  checked,
  onChange,
  readOnly,
  disabled,
  wrapperSx,
  ...wrapperProps
}: ICheckboxInput) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
        ...wrapperSx,
      }}
    >
      <FormControlLabel
        label={label}
        sx={{
          pointerEvents: readOnly ? "none" : "",
          "& .MuiFormControlLabel-label": {
            fontWeight: 500,
            color: "text.primary",
          },
        }}
        control={
          <Checkbox
            readOnly={readOnly}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            size="small"
            sx={{ color: "#999" }}
          />
        }
      />
    </Stack>
  );
}

export default CheckboxInput;
