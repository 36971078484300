import React, { Dispatch, SetStateAction, useState } from "react";
import { Badge, Button, Menu, MenuItem } from "@mui/material";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { useSelector } from "react-redux";
import { asyncDelete } from "../../../utils/httpRequests";
import { isArray } from "lodash";

interface IActions {
  apiCode: string;
  selectedRows: any[];
  setSelectedRows: Dispatch<SetStateAction<any[]>>;
  setToggleClear: Dispatch<SetStateAction<boolean>>;
  setLoad: Dispatch<SetStateAction<number>>;
}

function Actions({
  apiCode,
  selectedRows,
  setSelectedRows,
  setToggleClear,
  setLoad,
}: IActions) {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);

  const handleCloseMenu = () => setAnchorMenu(null);

  const handleUnSelect = () => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
    handleCloseMenu();
  };

  const deleteSelectedRows = async () => {
    if (isArray(selectedRows) && selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        const resp = await asyncDelete({
          apiCode,
          token,
          id: selectedRows[i]._id,
        });
        if (resp.status !== 200) {
          showAlert({
            type: "error",
            message:
              resp?.data?.error || resp.data?.message || "Lỗi khi xóa dữ liệu",
          });
          break;
        }
        if (i === selectedRows.length - 1) {
          showAlert({
            type: "success",
            message: `Đã xóa thành công ${selectedRows.length} dòng`,
          });
        }
      }
      setSelectedRows([]);
      setToggleClear((prev) => !prev);
      handleCloseMenu();
      setLoad((prev) => prev + 1);
    }
  };

  return (
    <>
      <Menu open={!!anchorMenu} anchorEl={anchorMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={handleUnSelect}>Bỏ chọn</MenuItem>
        <MenuItem
          onClick={() =>
            showAlert({
              type: "info",
              message: "Bạn có chắc muốn xóa các dòng đã chọn không ?",
              onConfirm: deleteSelectedRows,
            })
          }
          sx={{ color: "error.main" }}
        >
          Xóa bỏ
        </MenuItem>
      </Menu>
      <Badge
        badgeContent={selectedRows?.length || 0}
        sx={{
          "& .MuiBadge-badge": {
            backgroundColor: "error.main",
            color: "common.white",
            border: "1px solid",
            borderColor: "common.white",
            top: "5px",
            right: "2px",
          },
        }}
      >
        <Button
          onClick={(e) => setAnchorMenu(e.currentTarget)}
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            boxShadow: "none",
            color: "common.white",
            textTransform: "none",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Thao tác
        </Button>
      </Badge>
    </>
  );
}

export default Actions;
