import React, { useState } from "react";
import DataTable, { TableProps } from "react-data-table-component";
import { isArray } from "lodash";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ITableApp {
  title?: string;
  toggleCleared?: boolean;
  rowPerPage?: number;
  onSelectedRowsChange?: (rows: any[]) => void;
}

function TableApp({
  title,
  data,
  columns,
  toggleCleared,
  rowPerPage = 5,
  onRowClicked,
  onSelectedRowsChange,
  ...tableProps
}: ITableApp & Omit<TableProps<any>, "onSelectedRowsChange">) {
  const theme = useTheme();
  const [currentRow, setCurrentRow] = useState<any>(null);

  // handle selected row change
  const handleSelectedRowsChange = ({
    selectedRows,
  }: {
    selectedRows: any[];
  }) => {
    onSelectedRowsChange?.(selectedRows);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataTable
        fixedHeader
        persistTableHead
        noContextMenu
        pointerOnHover
        highlightOnHover
        columns={columns}
        data={isArray(data) ? data : []}
        onRowClicked={(row, e) => {
          setCurrentRow(row);
          onRowClicked?.(row, e);
        }}
        clearSelectedRows={toggleCleared}
        onSelectedRowsChange={handleSelectedRowsChange}
        responsive
        paginationServer
        paginationDefaultPage={1}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Dòng trên bảng",
          rangeSeparatorText: "trên",
        }}
        paginationComponent={() => <div></div>}
        selectableRowsHighlight
        // expandable row
        expandableRowExpanded={(row) => row?._id === currentRow?._id}
        onRowExpandToggled={(bool, row) =>
          bool ? undefined : setCurrentRow(null)
        }
        progressComponent={
          <Stack
            gap={2}
            sx={{
              width: "100%",
              py: 1,
              px: 2,
            }}
          >
            {new Array(rowPerPage).fill(0).map((_, index) => {
              return (
                <Stack key={index} direction="row" alignItems="center" gap={1}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "20px", height: "20px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "100px", height: "15px" }}
                  />
                  <Stack sx={{ width: "400px" }}>
                    <Skeleton
                      variant="text"
                      sx={{ width: "40%", height: "15px" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ width: "100%", height: "15px" }}
                    />
                  </Stack>
                  <Skeleton
                    variant="rounded"
                    sx={{ width: "40px", height: "15px" }}
                  />
                </Stack>
              );
            })}
          </Stack>
        }
        noDataComponent={
          <Typography
            sx={{ fontSize: "14px", textAlign: "center", padding: "20px 0" }}
          >
            {title ? `Không có ${title}` : "Không có dữ liệu"}
          </Typography>
        }
        customStyles={{
          headRow: {
            style: {
              minHeight: "40px",
              fontWeight: 600,
              backgroundColor: theme.palette.background.paper,
              overflow: "hidden",
            },
          },
          headCells: {
            style: {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              padding: "8px 16px",
              fontSize: "14px",
              "& input": {
                accentColor: theme.palette.primary.main,
              },
            },
          },
          rows: {
            style: {
              "& .rdt-toggle": {
                display: "none",
              },
              "&:hover .rdt-toggle": {
                display: "unset",
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: `${theme.palette.divider}`,
            },
            selectedHighlightStyle: {
              backgroundColor: `${theme.palette.primary.main}22 !important`,
            },
          },
          cells: {
            style: {
              padding: "8px 16px",
              fontSize: "14px",
              "& input": {
                accentColor: theme.palette.primary.main,
              },
            },
          },
        }}
        conditionalRowStyles={[
          {
            when: (row) => row.status === false,
            style: {
              backgroundColor: `${theme.palette.error.main}22`,
            },
          },
        ]}
        {...tableProps}
      />
    </Box>
  );
}

export default TableApp;
