import { Backdrop, CircularProgress } from "@mui/material";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

const BackdropContext = createContext<{
  setOpenBackdrop: Dispatch<SetStateAction<boolean>> | undefined;
}>({ setOpenBackdrop: undefined });

function BackdropProvider({ children }: { children: React.ReactNode }) {
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);

  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <BackdropContext.Provider value={{ setOpenBackdrop }}>
        {children}
      </BackdropContext.Provider>
    </>
  );
}

export default BackdropProvider;

export const useBackdropContext = () => {
  const value = useContext(BackdropContext);
  if (!value) {
    throw new Error("BackdropContext must be used inside BackdropProvider");
  }
  return value;
};
