import React, { useEffect, useState } from "react";
import { Box, Chip, Grid2, IconButton, Stack, Typography } from "@mui/material";
import { LuTrash2 } from "react-icons/lu";
import Price from "./Price";
import Number from "./Number";
import Total from "./Total";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../../PosPage";
import { cloneDeep, isArray } from "lodash";
import { asyncSearchList } from "../../../../../../utils/httpRequests";
import moment from "moment";
import { useAlertContext } from "../../../../../../contexts/alert/AlertProvider";
import Options from "./Options";

interface IDetail {
  stt: number;
  data: any;
  readOnly?: boolean;
}

function Detail({ stt, data, readOnly = false }: IDetail) {
  const { showAlert } = useAlertContext();
  const { currentHd } = useSelector((state: any) => state.pos);
  const { currentStore } = useSelector((state: any) => state.config);
  const token = useSelector((state: any) => state.auth.token);
  const { handleUpdateCurrentHd } = usePosContext();
  const [loadingCk, setLoadingCk] = useState<boolean>(false);

  const handleDeleteDetail = (id: string) => {
    const newDetails = currentHd.details.filter((d: any) => d._id !== id);
    handleUpdateCurrentHd({ details: newDetails });
  };

  const handleNumberChange = (number: number) => {
    const newNumber = number < 1 ? 1 : number;
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex(
      (d: any) => d._id === data._id
    );
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.sl_order = newNumber;
    const tienCk: number =
      Math.floor(
        ((detail.ty_le_ck || 0) * (detail.gia_ban_le_goc || 0)) / 100
      ) * detail.sl_order;
    detail.tien_ck_nt = tienCk;
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleChietKhauChange = ({
    tien_ck,
    ty_le_ck,
  }: {
    tien_ck: number;
    ty_le_ck: number;
  }) => {
    const currentHdClone = cloneDeep(currentHd);
    const index = currentHdClone.details.findIndex(
      (d: any) => d._id === data._id
    );
    if (index < 0) return;
    const detail = currentHdClone.details[index];
    detail.ty_le_ck = ty_le_ck;
    detail.tien_ck_nt = tien_ck * detail.sl_order;
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };

  const handleApplyCK = (ck: any) => {
    let value = 0;
    const giaGoc = data?.gia_ban_le_goc || 0;
    const isPercent = (ck?.ty_le_ck || 0) > 0;
    if (isPercent) {
      value = ck?.ty_le_ck || 0;
      if (value > 100) {
        value = 100;
      }
      const tienCk = +((giaGoc * value) / 100).toFixed(0);
      handleChietKhauChange({ tien_ck: tienCk, ty_le_ck: value });
    } else {
      value = ck?.tien_ck || 0;
      if (value > giaGoc) {
        value = giaGoc;
      }
      const tyLeCk = (value * 100) / giaGoc;
      handleChietKhauChange({ tien_ck: value, ty_le_ck: tyLeCk });
    }
  };

  const handleApplyKM = (kms: any[]) => {
    handleUpdateCurrentHd({
      promotion: [...(currentHd?.promotion || []), ...(kms || [])],
    });
  };

  const getCK = async () => {
    if (readOnly) return;
    try {
      setLoadingCk(true);
      const today = moment();
      const query = {
        status: true,
        $or: [{ ma_vt: data?.ma_vt || "" }, { ma_nvt: data?.ma_nvt || "" }],
        ma_kho: currentStore?.ma_kho || "",
        "exfields.loai_chiet_khau": 2,
        hieu_luc_tu: { $lte: today.toISOString() },
        hieu_luc_den: { $gte: today.toISOString() },
      };
      const resp = await asyncSearchList({
        apiCode: "dmchietkhau",
        token,
        condition: { page: 1, limit: 99999, q: query },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi tải chương trình giảm giá",
        });
        return;
      }
      const cks = resp?.data || [];
      if (isArray(cks) && cks?.length > 0) {
        if (cks?.length === 1) {
          handleApplyCK(cks?.[0]);
        } else {
          cks.sort((a, b) => a.stt_uu_tien - b.stt_uu_tien);
          handleApplyCK(cks?.[0]);
        }
      }
    } finally {
      setLoadingCk(false);
    }
  };

  const getPromotion = async () => {
    if (readOnly) return;
    try {
      setLoadingCk(true);
      const today = moment();
      const query = {
        status: true,
        $or: [{ ma_vt: data?.ma_vt || "" }, { ma_nvt: data?.ma_nvt || "" }],
        ma_kho: currentStore?.ma_kho || "",
        sl_tu: { $lte: data?.sl_order || 1 },
        hieu_luc_tu: { $lte: today.toISOString() },
        hieu_luc_den: { $gte: today.toISOString() },
      };
      const resp = await asyncSearchList({
        apiCode: "dmkhuyenmai",
        token,
        condition: { page: 1, limit: 99999, q: query },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi tải khuyến mãi",
        });
        return;
      }
      const cks = resp?.data || [];
      if (isArray(cks) && cks?.length > 0) {
        if (cks?.length === 1) {
          handleApplyKM(cks?.[0]?.details_km || []);
        } else {
          cks.sort((a, b) => a.stt_uu_tien - b.stt_uu_tien);
          handleApplyKM(cks?.[0]?.details_km || []);
        }
      }
    } finally {
      setLoadingCk(false);
    }
  };

  useEffect(() => {
    getCK();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ma_vt, data?.ma_nvt]);

  useEffect(() => {
    // getPromotion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ma_vt, data?.ma_nvt, data?.sl_order]);

  return (
    <Box
      sx={{
        width: "100%",
        px: 2,
        py: 1,
        borderRadius: "10px",
        backgroundColor: "background.paper",
        pointerEvents: readOnly ? "none" : "all",
      }}
    >
      <Stack gap={1}>
        <Box>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 8 }}>
              <Typography>
                {stt}. {data.ten_vt} ({data.ma_vt}){" "}
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <Stack
                direction="row"
                alignItems="cener"
                justifyContent="flex-end"
                gap={1}
              >
                <Chip
                  component="span"
                  label={data?.ten_dvt || data?.ma_dvt}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
                <Options data={data} handleNumberChange={handleNumberChange} />
                {!readOnly && (
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleDeleteDetail(data._id)}
                  >
                    <LuTrash2 size={16} />
                  </IconButton>
                )}
              </Stack>
            </Grid2>
          </Grid2>
          <Box>
            <Grid2 container spacing={1}>
              <Grid2 size={{ xs: 4 }}>
                <Price
                  loading={loadingCk}
                  giaGoc={data.gia_ban_le_goc}
                  chietKhau={{
                    tien_ck: (data.tien_ck_nt || 0) / (data.sl_order || 1),
                    ty_le_ck: data.ty_le_ck || 0,
                  }}
                  onChietKhauChange={handleChietKhauChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 4 }}>
                <Number
                  defaultValue={data.sl_order || 1}
                  onNumberChange={handleNumberChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 4 }}>
                <Total value={data.tien_xuat_nt} />
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default Detail;
