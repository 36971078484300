import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterUserGroup({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    ten_nhom: "",
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.ten_nhom) {
      condition.group_name = {
        $regex: filter.ten_nhom.split(" ").join(".*"),
        $options: "i",
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Tên nhóm"
          placeholder="Tìm theo tên nhóm"
          value={filter.ten_nhom}
          onSearch={(value) => setFilter({ ...filter, ten_nhom: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterUserGroup;
