import moment from "moment";
import { PUBLIC_TOKEN, PUBLIC_URL } from "./constants";
import numeral from "numeral";
import { isArray } from "lodash";

numeral.register("locale", "vi", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "Nghìn",
    million: "Triệu",
    billion: "Tỷ",
    trillion: "Nghìn Tỷ",
  },
  currency: {
    symbol: "₫",
  },
  ordinal: (number) => number + "",
});
numeral.locale("vi");

function formatDateDisplay(date: any, format = "DD/MM/YYYY") {
  if (!date) return;
  if (!moment(date).isValid()) return date;
  return moment(date).format(format);
}

// generate search keywords
const generateSearchKeywords = (obj: { [key: string]: any }) => {
  let result = "";
  for (let key in obj) {
    result += `${!result ? "?" : "&"}${key}=${JSON.stringify(obj[key])}`;
  }
  return result;
};

// generate random string
function generateRandomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}

type generateSplitTextType = {
  strings: string[];
  symbol?: string;
};
function generateSplitText({ strings, symbol = "," }: generateSplitTextType) {
  if (!isArray(strings)) {
    return "";
  }
  const result = strings.reduce((acc: string, item: string) => {
    if (!!item) {
      if (!!acc) {
        return `${acc}${symbol} ${item}`;
      } else {
        return item;
      }
    } else {
      return acc;
    }
  }, "");
  return result;
}

// generate link image
const generateLinkImage = (link: string) => {
  return `${PUBLIC_URL}${link}?access_token=${PUBLIC_TOKEN}`;
};

type downloadFileType = {
  link: string;
  successMessage?: string;
};
const downloadFile = ({ link, successMessage }: downloadFileType) => {
  if (!link) return;
  try {
    const aTag = document.createElement("a");
    aTag.href = link;
    aTag.download = "true";
    aTag.click();
    alert(successMessage || "Đã tải xuống file excel mẫu");
  } catch (error: any) {
    alert(error?.error || error?.message || "Lỗi khi tải file");
  }
};

type groupByType = {
  data: any[];
  callbackMatch: (item: any) => string;
  returnKey?: boolean;
};
const groupBy = ({
  data = [],
  callbackMatch,
  returnKey = false,
}: groupByType) => {
  if (!callbackMatch) return data;
  let result: { [key: string]: any[] } = {};
  data.forEach((item) => {
    if (result[callbackMatch(item)]) {
      result[callbackMatch(item)].push(item);
    } else {
      result[callbackMatch(item)] = [item];
    }
  });
  if (returnKey) {
    return result;
  } else {
    return Object.values(result);
  }
};

export {
  numeral as numeralCustom,
  formatDateDisplay,
  generateSearchKeywords,
  generateRandomString,
  generateLinkImage,
  generateSplitText,
  downloadFile,
  groupBy,
};
