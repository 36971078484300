import moment from "moment";
import React, { useEffect, useState } from "react";
import { asyncGetReport } from "../../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import { isArray } from "lodash";
import { TableColumn } from "react-data-table-component";
import { numeralCustom } from "../../../../../utils/helpers";
import TableApp from "../../../tableapp/TableApp";

const columns: TableColumn<any>[] = [
  {
    name: "Chi nhánh",
    width: "300px",
    wrap: true,
    selector: (row) => `${row.ten_kho} (${row.ma_kho})`,
  },
  {
    name: "Số lượng",
    width: "200px",
    wrap: true,
    selector: (row) => row.ton_cuoi,
  },
  {
    name: "ĐVT",
    width: "200px",
    wrap: true,
    selector: (row) => row.ten_dvt || row.ma_dvt,
  },
  {
    name: "Giá trị",
    width: "200px",
    wrap: true,
    selector: (row) => row.du_cuoi,
    format: (row) => numeralCustom(row.du_cuoi).format(),
  },
];

interface IKhoSection {
  product: any;
}

function KhoSection({ product }: IKhoSection) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    if (loading) return;
    setLoading(true);
    const today = moment().format("YYYY-MM-DD");
    const resp = await asyncGetReport({
      apiCode: "thnxt",
      idApp: app?._id,
      token: token,
      query: {
        tu_ngay: today,
        den_ngay: today,
        ma_vt: product?.ma_vt || "",
        groupby: "ma_vt,ma_kho",
      },
    });
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải báo cáo kho",
      });
    } else {
      if (isArray(resp.data) && resp.data.length > 1) {
        resp.data.pop();
        setData(resp.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (product?.ma_vt) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.ma_vt]);

  return (
    <TableApp
      columns={columns}
      data={data}
      progressPending={loading}
      rowPerPage={2}
    />
  );
}

export default KhoSection;
