import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import moment from "moment";
import SelectApiInput from "../../input/SelectApiInput";
import TextInput from "../../input/TextInput";
import NumberInput from "../../input/NumberInput";
import { numeralCustom } from "../../../../utils/helpers";

const schema = yup.object({
  nam: yup.string().required("Vui lòng nhập năm đầu kỳ"),
  vat_tu: yup
    .object()
    .typeError("Vui lòng chọn Sản phẩm")
    .required("Vui lòng chọn Sản phẩm"),
  kho: yup
    .object()
    .typeError(`Vui lòng chọn chi nhánh`)
    .required(`Vui lòng chọn chi nhánh`),
  ton00: yup
    .number()
    .typeError("Vui lòng nhâp tồn đầu kỳ")
    .required("Vui lòng nhâp tồn đầu kỳ"),
});

function FormCDVT({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    nam: moment().year(),
    vat_tu: null,
    lo: null,
    kho: null,
    ton00: 0,
    du00: 0,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_lo_yn: !!defaultValues.ma_lo,
            }
          : null,
        lo: defaultValues.ma_lo ? { ma_lo: defaultValues.ma_lo } : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { vat_tu, lo, kho, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_lo: lo?.ma_lo || "",
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="500px"
      formTitle="tồn đầu kỳ"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <Controller
          name="kho"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                labelWidth="25%"
                label="Chi nhánh"
                placeholder="Chọn chi nhánh"
                apiCode="dmkho"
                searchFields={["ten_kho"]}
                getOptionLabel={(option) => option.ten_kho}
                checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
                value={value}
                onChange={onChange}
                errorMessage={errors?.kho?.message as string}
              />
            );
          }}
        />
        <Controller
          name="vat_tu"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <SelectApiInput
                required
                labelWidth="25%"
                label="Sản phẩm"
                placeholder="Chọn Sản phẩm"
                apiCode="dmvt"
                searchFields={["ma_vt", "ten_vt"]}
                defaultCondition={{
                  sp_yeu_cau_che_bien: { $ne: true },
                  sp_yeu_cau_pha_che: { $ne: true },
                }}
                getOptionLabel={(option) => option.ten_vt}
                checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
                value={value}
                onChange={onChange}
                errorMessage={errors?.vat_tu?.message as string}
              />
            );
          }}
        />
        <TextInput
          required
          label="Năm đầu kỳ"
          labelWidth="25%"
          placeholder="Nhập năm đầu kỳ"
          name="nam"
          register={register}
          errorMessage={errors?.nam?.message as string}
        />
        <NumberInput
          required
          label="Tồn đầu kỳ"
          labelWidth="25%"
          placeholder="Nhập tồn đầu kỳ"
          name="ton00"
          register={register}
          errorMessage={errors?.ton00?.message as string}
        />
        <Controller
          name="du00"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                label="Giá trị tồn"
                labelWidth="25%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = e.target.value;
                  onChange(numeralCustom(val).value());
                }}
              />
            );
          }}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormCDVT;
