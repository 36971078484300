import React from "react";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import FilterSelectApi from "../FilterSelectApi";
import { useLocation } from "react-router-dom";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterParticipant({ setCondition }: IFilterProps) {
  const location = useLocation();
  const originFilter: { [key: string]: any } = {
    email: "",
    name: "",
    userGroup: location.state?.userGroup || null,
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: "i",
      };
    }
    if (filter.name) {
      condition.name = {
        $regex: filter.name.split(" ").join(".*"),
        $options: "i",
      };
    }
    if (filter.userGroup) {
      condition.group_id = filter.userGroup._id;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterText
          title="Tên"
          placeholder="Tìm theo email"
          value={filter.name}
          onSearch={(value) => setFilter({ ...filter, name: value })}
        />
        <FilterSelectApi
          direction="column"
          apiCode="usergroup"
          placeholder="Chọn nhóm người dùng"
          searchFields={["group_name"]}
          title="Nhóm người dùng"
          checkEqual={(value, option) => value?._id === option?._id}
          getOptionLabel={(option) => option.group_name}
          value={filter.userGroup}
          onChange={(value) => setFilter({ ...filter, userGroup: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterParticipant;
