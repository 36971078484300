import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth.reducer";
import appReducer from "./app.reducer";
import posReducer from "./pos.reducer";
import fnbReducer from "./fnb.reducer";
import kitchenReducer from "./kitchen.reducer";
import orderReducer from "./order.reducer";
import configReducer from "./config.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  pos: posReducer,
  fnb: fnbReducer,
  kitchen: kitchenReducer,
  order: orderReducer,
  config: configReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
