import React, { useState } from "react";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface IPasswordInput {
  label: string;
  register?: UseFormRegister<FieldValues> | undefined;
  errorMessage?: string | undefined;
}

function PasswordInput({
  name,
  label,
  errorMessage,
  register,
  ...props
}: IPasswordInput & TextFieldProps) {
  const [isPassword, setIsPassword] = useState(true);

  return (
    <TextField
      name={name}
      type={isPassword ? "password" : "text"}
      variant="filled"
      label={errorMessage || label}
      autoComplete="off"
      slotProps={{
        input: {
          endAdornment: (
            <IconButton onClick={() => setIsPassword(!isPassword)}>
              {isPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
            </IconButton>
          ),
        },
      }}
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: "background.default",
          borderRadius: "10px",
          "&:before": {
            display: "none",
          },
          "&:after": {
            display: "none",
          },
          "& .MuiInputBase-input": {
            height: "50px",
          },
        },
      }}
      error={!!errorMessage}
      {...register?.(name as string)}
      {...props}
    />
  );
}

export default PasswordInput;
