import React from "react";
import { Box, Stack } from "@mui/material";
import Header from "./components/Header";
import Bottom from "./components/Bottom";
import Details from "./components/Details";
import Summary from "./components/Summary";

const heights = {
  header: "50px",
  summary: "90px",
  bottom: "50px",
};

function Right() {
  return (
    <Stack
      gap={1}
      justifyContent="space-between"
      sx={{
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Header height={heights.header} />
      <Box
        sx={{
          width: "100%",
          height: `calc(100vh - ${heights.header} - ${heights.summary} - ${heights.bottom} - 16px)`,
          overflow: "auto",
          px: 1,
        }}
      >
        <Details />
      </Box>
      <Box>
        <Summary height={heights.summary} />
        <Bottom height={heights.bottom} />
      </Box>
    </Stack>
  );
}

export default Right;
