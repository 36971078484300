import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import moment from "moment";
import InfoSection from "./info-section/InfoSection";

const schema = yup.object({
  ten_kh: yup.string().required("Vui lòng nhập tên khách hàng"),
  trang_thai: yup
    .object()
    .typeError("Vui lòng chọn trạng thái")
    .required("Vui lòng chọn trạng thái"),
  email: yup.string().email("Email không đúng định dạng"),
  dien_thoai: yup.string().required("Vui lòng nhập số điện thoại"),
  // .matches(/^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/, {
  //   message: "Số điện thoại không hợp lệ",
  //   excludeEmptyString: true,
  // })
  ngay_sinh: yup
    .date()
    .typeError("Vui lòng nhập ngày sinh")
    .required("Vui lòng nhập ngày sinh"),
});

function FormCustomer({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
  onSuccess,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ma_kh: "",
    ten_kh: "",
    ten_kh2: "", // Tên nhà thuốc, cơ sở kinh doanh
    dien_thoai: "",
    email: "",
    ghi_chu: "",
    id_number: "", // CMND/CCCD
    ngay_sinh: "",
    ma_so_thue: "",
    status: true,
    trang_thai: null,
    tinh_thanh_obj: null,
    quan_huyen_obj: null,
    xa_phuong_obj: null,
    nhom_kh: null,
    phu_trach: null,
    of_user: null,
    visible: null,
    users: [],
    dia_chi: "",
    kh_yn: true,
    ncc_yn: false,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
        nhom_kh: defaultValues.nh_kh
          ? {
              _id: defaultValues.nh_kh,
              group_name: defaultValues.ten_nh_kh,
            }
          : null,
        phu_trach: defaultValues.phu_trach
          ? {
              email: defaultValues.phu_trach,
              name: defaultValues.ten_phu_trach,
            }
          : null,
        of_user: defaultValues.of_user
          ? { email: defaultValues.of_user }
          : null,
        users:
          defaultValues.visible_to_users?.length > 0
            ? defaultValues.visible_to_users.map((u: any) => ({ email: u }))
            : [],
        tinh_thanh_obj: defaultValues.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen_obj: defaultValues.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong_obj: defaultValues.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
        ngay_sinh: defaultValues.ngay_sinh
          ? moment(defaultValues.ngay_sinh).format("YYYY-MM-DD")
          : "",
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const {
      trang_thai,
      tinh_thanh_obj,
      quan_huyen_obj,
      xa_phuong_obj,
      nhom_kh,
      phu_trach,
      visible,
      users,
      of_user,
      ma_kh,
      dien_thoai,
      ...fields
    } = values;
    return {
      ...fields,
      trang_thai: trang_thai?.ma_trang_thai || "",
      ten_trang_thai: trang_thai?.ten_trang_thai || "",
      tinh_thanh: tinh_thanh_obj?.ma_tinh_thanh || "",
      ten_tinh_thanh: tinh_thanh_obj?.ten_tinh_thanh || "",
      quan_huyen: quan_huyen_obj?.ma_quan_huyen || "",
      ten_quan_huyen: quan_huyen_obj?.ten_quan_huyen || "",
      xa_phuong: xa_phuong_obj?.ma_xa_phuong || "",
      ten_xa_phuong: xa_phuong_obj?.ten_xa_phuong || "",
      nh_kh: nhom_kh?._id || "",
      ten_nh_kh: nhom_kh?.group_name || "",
      phu_trach: phu_trach?.email || "",
      ten_phu_trach: phu_trach?.name || "",
      visible_to: visible?.value,
      visible_to_users: (users || []).map((u: any) => u.email),
      of_user: of_user?.email || "",
      dien_thoai,
      ma_kh: ma_kh || dien_thoai,
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
      onSuccess?.(resp?.data);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="1000px"
      formTitle="khách hàng"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
      </Stack>
    </ModalForm>
  );
}

export default FormCustomer;
