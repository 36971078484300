import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import SelectApiInput from "../../../../../../components/common/input/SelectApiInput";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../../PosPage";
import TextInput from "../../../../../../components/common/input/TextInput";

function ThongTinGiaoHang() {
  const { currentHd } = useSelector((state: any) => state.pos);
  const { handleUpdateCurrentHd } = usePosContext();
  const [thongTinNguoiNhan, setThongTinNguoiNhan] = useState<{
    [key: string]: any;
  }>({
    dia_chi: "",
    ten_nguoi_nhan: "",
    dien_thoai: "",
  });

  useEffect(() => {
    setThongTinNguoiNhan({
      dia_chi: currentHd?.dia_chi || "",
      ten_nguoi_nhan: currentHd?.ten_nguoi_nhan || "",
      dien_thoai: currentHd?.dien_thoai || "",
    });
  }, [currentHd?.dia_chi, currentHd?.ten_nguoi_nhan, currentHd?.dien_thoai]);

  return (
    <Stack gap={1} sx={{ width: "100%" }}>
      <Typography sx={{ fontWeight: 600 }}>Thông tin giao hàng</Typography>
      <Stack
        gap={1}
        sx={{
          p: 1,
          border: "1px dashed",
          borderColor: "divider",
          borderRadius: "10px",
        }}
      >
        {/* Tinh thanh */}
        <SelectApiInput
          requestMethod="get"
          direction="column"
          label="Tỉnh / thành"
          placeholder="Chọn tỉnh thành"
          placeholderSearch="Tìm theo tên tỉnh thành"
          apiCode="tinhthanh"
          withIdApp={false}
          searchFields={["ten_tinh_thanh"]}
          getOptionLabel={(option) => option.ten_tinh_thanh}
          checkEqual={(value, option) =>
            value?.ma_tinh_thanh === option?.ma_tinh_thanh
          }
          value={
            currentHd?.tinh_thanh
              ? {
                  ma_tinh_thanh: currentHd?.tinh_thanh || "",
                  ten_tinh_thanh: currentHd?.ten_tinh_thanh || "",
                }
              : null
          }
          onChange={(val) => {
            handleUpdateCurrentHd({
              tinh_thanh: val?.ma_tinh_thanh || "",
              ten_tinh_thanh: val?.ten_tinh_thanh || "",
              quan_huyen: "",
              ten_quan_huyen: "",
              xa_phuong: "",
              ten_xa_phuong: "",
            });
          }}
        />
        {/* Quan huyen */}
        <SelectApiInput
          disabled={!currentHd?.tinh_thanh}
          requestMethod="get"
          direction="column"
          label="Quận huyện"
          placeholder="Chọn quận huyện"
          placeholderSearch="Tìm theo tên quận huyện"
          apiCode="quanhuyen"
          withIdApp={false}
          searchFields={["ten_quan_huyen"]}
          defaultCondition={{
            ma_tinh_thanh: currentHd?.tinh_thanh || currentHd,
          }}
          getOptionLabel={(option) => option.ten_quan_huyen}
          checkEqual={(value, option) =>
            value?.ma_quan_huyen === option?.ma_quan_huyen
          }
          value={
            currentHd?.quan_huyen
              ? {
                  ma_quan_huyen: currentHd?.quan_huyen || "",
                  ten_quan_huyen: currentHd?.ten_quan_huyen || "",
                }
              : null
          }
          onChange={(val) => {
            handleUpdateCurrentHd({
              quan_huyen: val?.ma_quan_huyen || "",
              ten_quan_huyen: val?.ten_quan_huyen || "",
              xa_phuong: "",
              ten_xa_phuong: "",
            });
          }}
        />
        {/* Xa phuong */}
        <SelectApiInput
          disabled={!currentHd?.quan_huyen}
          requestMethod="get"
          direction="column"
          label="Xã phường"
          placeholder="Chọn xã phường"
          placeholderSearch="Tìm theo xã phường"
          apiCode="xaphuong"
          withIdApp={false}
          searchFields={["ten_xa_phuong"]}
          defaultCondition={{
            ma_quan_huyen: currentHd?.quan_huyen || undefined,
          }}
          getOptionLabel={(option) => option.ten_xa_phuong}
          checkEqual={(value, option) =>
            value?.ma_xa_phuong === option?.ma_xa_phuong
          }
          value={
            currentHd?.xa_phuong
              ? {
                  ma_xa_phuong: currentHd?.xa_phuong || "",
                  ten_xa_phuong: currentHd?.ten_xa_phuong || "",
                }
              : null
          }
          onChange={(val) => {
            handleUpdateCurrentHd({
              xa_phuong: val?.ma_xa_phuong || "",
              ten_xa_phuong: val?.ten_xa_phuong || "",
            });
          }}
        />
        <TextInput
          direction="column"
          label="Địa chỉ cụ thể"
          placeholder="Số nhà, tên đường,..."
          value={thongTinNguoiNhan.dia_chi || ""}
          onChange={(e) =>
            setThongTinNguoiNhan({
              ...thongTinNguoiNhan,
              dia_chi: e.target.value,
            })
          }
          onBlur={() =>
            handleUpdateCurrentHd({ dia_chi: thongTinNguoiNhan?.dia_chi || "" })
          }
        />
        <TextInput
          direction="column"
          label="Tên người nhận"
          placeholder="Nhập tên người nhận"
          value={thongTinNguoiNhan.ten_nguoi_nhan || ""}
          onChange={(e) =>
            setThongTinNguoiNhan({
              ...thongTinNguoiNhan,
              ten_nguoi_nhan: e.target.value,
            })
          }
          onBlur={() =>
            handleUpdateCurrentHd({
              ten_nguoi_nhan: thongTinNguoiNhan?.ten_nguoi_nhan || "",
            })
          }
        />
        <TextInput
          direction="column"
          label="Số điện thoại"
          placeholder="Nhập số điện thoại người nhận"
          value={thongTinNguoiNhan.dien_thoai || ""}
          onChange={(e) =>
            setThongTinNguoiNhan({
              ...thongTinNguoiNhan,
              dien_thoai: e.target.value,
            })
          }
          onBlur={() =>
            handleUpdateCurrentHd({
              dien_thoai: thongTinNguoiNhan?.dien_thoai || "",
            })
          }
        />
      </Stack>
    </Stack>
  );
}

export default ThongTinGiaoHang;
