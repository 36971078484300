import Avatar, { AvatarProps } from "@mui/material/Avatar";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringToLetter(string: string) {
  return string.split(" ")[0][0];
}

interface IAvatarApp extends AvatarProps {
  name?: string;
}

function AvatarApp({ name, sx, src, ...props }: IAvatarApp) {
  if (!src && name) {
    return (
      <Avatar sx={{ ...sx, backgroundColor: stringToColor(name.trim()) }}>
        {stringToLetter(name.trim())}
      </Avatar>
    );
  }
  return <Avatar src={src} sx={sx} {...props} />;
}
export default AvatarApp;
