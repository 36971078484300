import React from "react";
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

interface ICollapseSection {
  children: React.ReactNode;
  title?: string;
  show?: boolean;
  onCollapse?: () => void;
}

function CollapseSection({
  children,
  title,
  show,
  onCollapse,
}: ICollapseSection) {
  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: "10px",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "5px",
          backgroundColor: "background.default",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
        {!!onCollapse && (
          <IconButton onClick={onCollapse} sx={{ padding: 0.5 }}>
            {show ? <LuChevronUp size={16} /> : <LuChevronDown size={16} />}
          </IconButton>
        )}
      </Stack>
      <Collapse in={show}>
        <Box sx={{ p: 1 }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

export default CollapseSection;
