import React from "react";
import { IconButton, IconButtonProps } from "@mui/material";

interface IIconButtonApp extends IconButtonProps {}

function IConButtonApp({ children, sx, ...props }: IIconButtonApp) {
  return (
    <IconButton
      sx={{
        borderRadius: "10px",
        color: "secondary.main",
        backgroundColor: "secondary.600",
        transition: "all linear 0.1s",
        "&:hover": {
          color: "common.white",
          backgroundColor: "secondary.main",
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
}

export default IConButtonApp;
