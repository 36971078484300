import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { numeralCustom } from "../../../../../utils/helpers";
import NumberInput from "../../../input/NumberInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                labelWidth="30%"
                label="Tên PT"
                placeholder="Thanh toán khi nhận hàng"
                name="ten"
                register={register}
                required
                errorMessage={errors?.ten?.message as string}
              />
              <TextInput
                labelWidth="30%"
                label="TK ngân hàng"
                placeholder="3829981"
                name="tai_khoan_nh"
                register={register}
              />
              <TextInput
                labelWidth="30%"
                label="Chủ tài khoản"
                placeholder="Lê Kim Nhàn"
                name="chu_tai_khoan"
                register={register}
              />
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                labelWidth="30%"
                label="Ngân hàng"
                placeholder="BIDV"
                name="ngan_hang"
                register={register}
              />
              <Controller
                name="kieu_so1"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <NumberInput
                      label="Tỷ lệ CK"
                      labelWidth="30%"
                      value={numeralCustom(value).format()}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
