import React, { useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import TextInput from "../../../input/TextInput";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface IDiachiSection {
  register?: UseFormRegister<FieldValues> | undefined;
}

function DiachiSection({ register }: IDiachiSection) {
  const [show, setShow] = useState<boolean>(true);

  return (
    <CollapseSection
      title="Địa chỉ"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <TextInput
        minRows={3}
        maxRows={5}
        placeholder="Nhập địa chỉ chi nhánh"
        direction="column"
        name="dia_chi"
        register={register}
      />
    </CollapseSection>
  );
}

export default DiachiSection;
