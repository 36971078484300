import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import SelectApiInput from "../../../input/SelectApiInput";
import FormDMNHNCC from "../../dmnhncc/FormDMNHNCC";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              label="Mã NCC"
              labelWidth="25%"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_kh"
              register={register}
            />
            <TextInput
              required
              label="Tên NCC"
              labelWidth="25%"
              placeholder="Nhập tên NCC"
              name="ten_kh"
              register={register}
              errorMessage={errors?.ten_kh?.message as string}
            />
            <Controller
              name="nhom_ncc"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm NCC"
                    placeholder="Chọn Nhóm NCC"
                    apiCode="dmnhncc"
                    searchFields={["group_name"]}
                    getOptionLabel={(option) => option.group_name}
                    checkEqual={(value, option) => value?._id === option?._id}
                    value={value}
                    onChange={onChange}
                    Form={FormDMNHNCC}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            <TextInput
              label="Số điện thoại"
              labelWidth="25%"
              placeholder="Nhập số điện thoại"
              name="dien_thoai"
              register={register}
              errorMessage={errors?.dien_thoai?.message as string}
            />
            <TextInput
              label="Email"
              labelWidth="25%"
              placeholder="Nhập Email"
              name="email"
              register={register}
              errorMessage={errors?.email?.message as string}
            />
            <TextInput
              label="Mã số thuế"
              labelWidth="25%"
              placeholder="Nhập mã số thuế"
              name="ma_so_thue"
              register={register}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
