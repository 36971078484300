import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import ModalBase from "../../../modal/ModalBase";
import { Box, Button, Grid2 } from "@mui/material";
import SelectApiInput from "../../../input/SelectApiInput";
import NumberInput from "../../../input/NumberInput";
import TextInput from "../../../input/TextInput";
import { numeralCustom } from "../../../../../utils/helpers";

const schema = yup.object<FieldValues>({
  don_vi_tinh: yup
    .object()
    .typeError("Vui lòng chọn đơn vị tính")
    .required("Vui lòng chọn đơn vị tính"),
  mau: yup
    .number()
    .typeError("Số lượng phải là số")
    .required("Vui lòng nhập số lượng"),
  gia_ban_nt: yup
    .number()
    .typeError("Giá bán phải là số")
    .required("Vui lòng nhập giá"),
});

interface IFormAdd {
  open: boolean;
  isEdit?: boolean;
  defaultValue?: any;
  add?: (data: any, isEdit: boolean) => void;
  handleClose: () => void;
}

function FormAdd({ open, isEdit, defaultValue, add, handleClose }: IFormAdd) {
  const defaultData: { [key: string]: any } = {
    don_vi_tinh: null,
    mau: 0,
    gia_ban_nt: 0,
  };
  const originData = defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        don_vi_tinh: defaultValue?.ma_dvt
          ? { ma_dvt: defaultValue.ma_dvt, ten_dvt: defaultValue.ten_dvt }
          : null,
      }
    : defaultData;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const generateDataAdd = (values: typeof defaultData) => {
    const { don_vi_tinh, ...fields } = values;
    return {
      ...fields,
      tu: 1,
      ma_dvt: don_vi_tinh?.ma_dvt || "",
      ten_dvt: don_vi_tinh?.ten_dvt || "",
    };
  };

  // handle save
  const handleSave = (values: any) => {
    const dataAdd = generateDataAdd(values);
    add?.(dataAdd, !!isEdit);
    handleClose?.();
  };

  return (
    <ModalBase
      title={`${isEdit ? "Thêm" : "Sửa"} đơn vị tính`}
      open={open}
      handleClose={handleClose}
      width="500px"
      actions={[
        <Button
          key={1}
          onClick={handleSubmit(handleSave)}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu
        </Button>,
        <Button
          key={2}
          onClick={handleClose}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Box sx={{ p: 2 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Controller
              name="don_vi_tinh"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    apiCode="dmdvt"
                    label="Đơn vị tính"
                    labelWidth="30%"
                    searchFields={["ma_dvt", "ten_dvt"]}
                    placeholder="Chọn đơn vị tính"
                    checkEqual={(value, option) =>
                      value?.ma_dvt === option?.ma_dvt
                    }
                    getOptionLabel={(option) => option.ten_dvt}
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.don_vi_tinh?.message as string}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <NumberInput
              required
              label="Số lượng quy đổi"
              labelWidth="30%"
              placeholder="Nhập số lượng quy đổi"
              name="mau"
              register={register}
              errorMessage={errors?.mau?.message as string}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Controller
              name="gia_ban_nt"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    required
                    label="Giá bán quy đổi"
                    labelWidth="30%"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange(numeralCustom(val).value());
                    }}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </Box>
    </ModalBase>
  );
}

export default FormAdd;
