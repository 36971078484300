import React, { useState } from "react";
import { IForm } from "../../../../types/form.type";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import InfoSection from "./info-section";
import DetailsSection from "./details-section/DetailsSection";

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError("Vui lòng chọn Sản phẩm")
    .required("Vui lòng chọn Sản phẩm"),
  kho: yup
    .object()
    .typeError(`Vui lòng chọn chi nhánh`)
    .required(`Vui lòng chọn chi nhánh`),
  hieu_luc_tu: yup
    .date()
    .typeError("Vui lòng chọn ngày bắt đầu")
    .required("Vui lòng chọn ngày bắt đầu"),
  hieu_luc_den: yup
    .date()
    .typeError("Vui lòng chọn ngày kết thúc")
    .required("Vui lòng chọn ngày kết thúc"),
});

function FormDMKHUYENMAI({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { currentStore } = useSelector((state: any) => state.config);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    stt_uu_tien: "",
    vat_tu: null,
    nhom_vat_tu: null,
    kho: currentStore || null,
    khach_hang: null,
    nhom_khach: null,
    sl_tu: 0,
    sl_den: 0,
    hieu_luc_tu: moment().hours(8).minutes(0).format("YYYY-MM-DD HH:mm"),
    hieu_luc_den: moment()
      .hours(16)
      .minutes(30)
      .add(7, "days")
      .format("YYYY-MM-DD HH:mm"),
    status: false,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        nhom_vat_tu: defaultValues.ma_nvt
          ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
          : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        nhom_khach: defaultValues.nh_kh
          ? { _id: defaultValues.nh_kh, group_name: defaultValues.ten_nh_kh }
          : null,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format("YYYY-MM-DD HH:mm")
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format("YYYY-MM-DD HH:mm")
          : null,
      }
    : defaultData;
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState<any[]>(
    defaultValues?.details_km || []
  );

  const handleReset = () => {
    reset(originData);
    setDetails(defaultValues?.details_km || []);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { vat_tu, nhom_vat_tu, kho, khach_hang, nhom_khach, ...fields } =
      values;
    const result = {
      ...fields,
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_nvt: nhom_vat_tu?._id || "",
      ten_nvt: nhom_vat_tu?.ten_nvt || "",
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
      ma_kh: khach_hang?.ma_kh || "",
      ten_kh: khach_hang?.ten_kh || "",
      nh_kh: nhom_khach?._id || "",
      ten_nh_kh: nhom_khach?.group_name || "",
      details_km: details,
    };
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="900px"
      formTitle="khuyến mãi, quà tặng"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection errors={errors} control={control} register={register} />
        <DetailsSection details={details} setDetails={setDetails} />
      </Stack>
    </ModalForm>
  );
}

export default FormDMKHUYENMAI;
