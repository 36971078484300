import React from "react";
import { Stack, Typography } from "@mui/material";
import { numeralCustom } from "../../../../../../utils/helpers";

interface ITotal {
  value: number;
}

function Total({ value }: ITotal) {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
        {numeralCustom(value).format()}
      </Typography>
    </Stack>
  );
}

export default Total;
