import React, { useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import RichTextInput from "../../../input/RichTextInput";
import { Control, Controller } from "react-hook-form";

interface IDescriptionSection {
  control: Control<any, any>;
}

function DescriptionSection({ control }: IDescriptionSection) {
  const [show, setShow] = useState<boolean>(true);

  return (
    <CollapseSection
      title="Mô tả"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <Controller
        name="mieu_ta"
        control={control}
        render={({ field: { value, onChange } }) => {
          return <RichTextInput value={value} onChange={onChange} />;
        }}
      />
    </CollapseSection>
  );
}

export default DescriptionSection;
