import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import TextInput from "../../input/TextInput";

const schema = yup.object({
  ma_cn: yup.string().required("Vui lòng nhập mã chức năng"),
  ten_mau_in: yup.string().required("Vui lòng nhập tên mẫu in"),
});

function FormRPT({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData = { ma_cn: "", ten_mau_in: "", html_template: "" };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    return values;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="600px"
      formTitle="Mẫu in"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={1}>
        <TextInput
          required
          label="Mã CN"
          labelWidth="25%"
          placeholder="Nhập mã chức năng"
          name="ma_cn"
          register={register}
          errorMessage={errors?.ma_cn?.message as string}
        />
        <TextInput
          required
          label="Tên mẫu in"
          labelWidth="25%"
          placeholder="Tên mẫu in"
          name="ten_mau_in"
          register={register}
          errorMessage={errors?.ten_mau_in?.message as string}
        />
        <TextInput
          label="HTML mẫu in"
          labelWidth="25%"
          placeholder="Nhập html mẫu in"
          name="html_template"
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormRPT;
