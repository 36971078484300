import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { usePosContext } from "../../../../PosPage";
import { useSelector } from "react-redux";

function Pttt() {
  const { currentHd } = useSelector((state: any) => state.pos);
  const { pttts, handleUpdateCurrentHd } = usePosContext();

  const handlePtttChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const newPttt = pttts.find((item) => item._id === value);
    if (!newPttt) return;
    handleUpdateCurrentHd({
      pt_thanh_toan: newPttt._id,
      ten_pt_thanh_toan: newPttt.ten,
    });
  };

  if (pttts?.length < 0) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography sx={{ fontWeight: 600 }}>Phương thức thanh toán</Typography>
      <RadioGroup
        value={currentHd?.pt_thanh_toan}
        onChange={handlePtttChange}
        sx={{
          p: 1,
          border: "1px dashed",
          borderColor: "divider",
          borderRadius: "10px",
        }}
      >
        {pttts.map((item) => {
          return (
            <FormControlLabel
              key={item._id}
              label={item.ten}
              value={item._id}
              control={<Radio size="small" />}
            />
          );
        })}
      </RadioGroup>
    </Stack>
  );
}

export default Pttt;
