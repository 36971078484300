import React from "react";
import ModalBase from "./ModalBase";
import { LoadingButton } from "@mui/lab";
import { LuRefreshCcw, LuSave, LuXCircle } from "react-icons/lu";
import { Box, Button } from "@mui/material";

interface IModalForm {
  open: boolean;
  children: React.ReactNode;
  formTitle?: string;
  formWidth?: string;
  isEdit?: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onReset: () => void;
}

function ModalForm({
  children,
  formTitle,
  formWidth,
  isEdit,
  loading,
  open,
  onClose,
  onSubmit,
  onReset,
}: IModalForm) {
  return (
    <ModalBase
      width={formWidth || "600px"}
      title={`${isEdit ? "Chỉnh sửa" : "Thêm"} ${formTitle}`}
      open={open}
      handleClose={onClose}
      actions={[
        <Button
          key={0}
          onClick={onReset}
          variant="contained"
          size="small"
          color="secondary"
          startIcon={<LuRefreshCcw size={16} />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Nhập lại
        </Button>,
        <LoadingButton
          key={1}
          loading={loading}
          variant="contained"
          size="small"
          startIcon={<LuSave size={16} />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
          onClick={onSubmit}
        >
          Lưu
        </LoadingButton>,
        <Button
          key={2}
          onClick={onClose}
          variant="contained"
          size="small"
          color="error"
          startIcon={<LuXCircle size={16} />}
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Box
        sx={{
          maxHeight: "calc(90vh - 16px - 16px - 40px - 40px)",
          overflow: "auto",
          px: 2,
        }}
      >
        {children}
      </Box>
    </ModalBase>
  );
}

export default ModalForm;
