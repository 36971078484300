import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import { formatDateDisplay, generateLinkImage, numeralCustom } from "./helpers";
import ExpandHangHoa from "../components/common/expand/hanghoa/ExpandHangHoa";
import FormHangHoa from "../components/common/form/hanghoa/FormHangHoa";
import { IList } from "../types/list.type";
import FilterHangHoa from "../components/common/filters/hanghoa/FilterHangHoa";
import FilterNhomHang from "../components/common/filters/nhomhang/FilterNhomHang";
import FormNhomHang from "../components/common/form/nhomhang/FormNhomHang";
import FormDMQDDVT from "../components/common/form/dmqddvt/FormDMQDDVT";
import FilterDMQDDVT from "../components/common/filters/dmqddvt/FilterDMQDDVT";
import FilterDMDVT from "../components/common/filters/dmdvt/FilterDMDVT";
import FormDMDVT from "../components/common/form/dmdvt/FormDMDVT";
import FormCDVT from "../components/common/form/cdvt/FormCDVT";
import FilterCDVT from "../components/common/filters/cdvt/FilterCDVT";
import FormPKK from "../components/common/form/pkk/FormPKK";
import FilterPKK from "../components/common/filters/pkk/FilterPKK";
import ExpandPkk from "../components/common/expand/pkk/ExpandPkk";
import FilterPBL from "../components/common/filters/pbl/FilterPBL";
import ExpandPBL from "../components/common/expand/pbl/ExpandPBL";
import FilterHD7 from "../components/common/filters/hd7/FilterHD7";
import ExpandHD7 from "../components/common/expand/hd7/ExpandHD7";
import FormHD7 from "../components/common/form/hd7/FormHD7";
import FilterPN1 from "../components/common/filters/pn1/FilterPN1";
import ExpandPN1 from "../components/common/expand/pn1/ExpandPN1";
import FormPN1 from "../components/common/form/pn1/FormPN1";
import FilterPN5 from "../components/common/filters/pn5/FilterPN5";
import FormPN5 from "../components/common/form/pn5/FormPN5";
import ExpandPN5 from "../components/common/expand/pn5/ExpandPN5";
import ExpandPXK from "../components/common/expand/pxk/ExpandPXK";
import FormPXK from "../components/common/form/pxk/FormPXK";
import FilterPXH from "../components/common/filters/pxh/FilterPXH";
import ExpandPT1 from "../components/common/expand/pt1/ExpandPT1";
import FormPT1 from "../components/common/form/pt1/FormPT1";
import FilterPT1 from "../components/common/filters/pt1/FilterPT1";
import ExpandPC1 from "../components/common/expand/pc1/ExpandPC1";
import FormPC1 from "../components/common/form/pc1/FormPC1";
import FilterPC1 from "../components/common/filters/pc1/FilterPC1";
import FormCustomer from "../components/common/form/customer/FormCustomer";
import FilterCustomer from "../components/common/filters/dmkh/FilterDMKH";
import FormDMNHKH from "../components/common/form/dmnhkh/FormDMNHKH";
import FilterDMNHKH from "../components/common/filters/dmnhkh/FilterDMNHKH";
import FilterDMNCC from "../components/common/filters/dmncc/FilterDMNCC";
import FormNCC from "../components/common/form/ncc/FormNCC";
import FilterDMNHNCC from "../components/common/filters/dmnhncc/FilterDMNHNCC";
import FormDMNHNCC from "../components/common/form/dmnhncc/FormDMNHNCC";
import ExpandUsergroup from "../components/common/expand/usergroup/ExpandUsegroup";
import FilterUserGroup from "../components/common/filters/usergroup/FilterUserGroup";
import FormUsergroup from "../components/common/form/usergroup/FormUsergroup";
import CheckboxInput from "../components/common/input/CheckboxInput";
import FormParticipant from "../components/common/form/participant/FormParticipant";
import FilterParticipant from "../components/common/filters/participant/FilterParticipant";
import FormDmKho from "../components/common/form/dmkho/FormDmkho";
import FilterDMKHO from "../components/common/filters/dmkho/FilterDMKHO";
import FilterPTTHANHTOAN from "../components/common/filters/ptthanhtoan/FilterPTTHAHTOAN";
import FormPTTHANHTOAN from "../components/common/form/ptthanhtoan/FormPTTHANHTOAN";
import FilterDMKENHBAN from "../components/common/filters/dmkenhban/FilterDMKENHBAN";
import FormDMKENHBAN from "../components/common/form/dmkenhban/FormDMKENHBAN";
import FormChietKhauSP from "../components/common/form/chietkhausp/FormChietKhauSP";
import FilterChietkhauSP from "../components/common/filters/chietkhausp/FilterChietkhauSP";
import FormGiaoCa from "../components/common/form/giaoca/FormGiaoCa";
import FilterGiaoCa from "../components/common/filters/giaoca/FilterGiaoCa";
import FormDMCA from "../components/common/form/dmca/FormDMCA";
import FilterDMCA from "../components/common/filters/dmca/FilterDMCA";
import FilterRPT from "../components/common/filters/rpt/FilterRPT";
import FormRPT from "../components/common/form/rpt/FormRPT";
import FilterDVVANCHUYEN from "../components/common/filters/dvvanchuyen/FilterDVVANCHUYEN";
import FormDVVANCHUYEN from "../components/common/form/dvvanchuyen/FormDVVANCHUYEN";
import FormPBL from "../components/common/form/pbl/FormPBL";
import ExpandSOGCAPP from "../components/common/expand/sogcapp/ExpandSOGCAPP";
import FilterSOGCAPP from "../components/common/filters/sogcapp/FilterSOGCAPP";
import FormSOGCAPP from "../components/common/form/sogcapp/FormSOGCAPP";
import FormDMKHUYENMAI from "../components/common/form/dmkhuyenmai/FormDMKHUYENMAI";
import FilterDMKHUYENMAI from "../components/common/filters/dmkhuyenmai/FilterDMKHUYENMAI";
import FormNhomHangPOS from "../components/common/form/nhomhangpos/FormNhomHangPOS";
import FilterNhomHangPOS from "../components/common/filters/nhomhangpos/FilterNhomHangPOS";
import FormDMNV from "../components/common/form/dmnv/FormDMNV";

const lists: { [key: string]: IList } = {
  hanghoa: {
    apiCode: "dmvt",
    title: "Danh sách Sản phẩm",
    formTitle: "Sản phẩm",
    allowCreate: true,
    Expand: ExpandHangHoa,
    Form: FormHangHoa,
    Filter: FilterHangHoa,
    fields: [
      { label: "Mã SP", value: "ma_vt" },
      { label: "Tên sản phẩm", value: "ten_vt" },
      { label: "Đơn vị tính", value: "ten_dvt" },
      { label: "Mã vạch", value: "ma_vt2" },
      { label: "Nhóm hàng", value: "ten_nvt" },
    ],
    // condition: {
    //   sp_yeu_cau_pha_che: { $ne: true },
    //   sp_yeu_cau_che_bien: { $ne: true },
    // },
    columns: [
      {
        name: "Ảnh",
        width: "80px",
        cell: (row) => {
          return (
            <Tooltip
              placement="right"
              title={
                <Box
                  sx={{ width: "180px", height: "180px", borderRadius: "4px" }}
                >
                  <Avatar
                    src={
                      row.picture_thumb
                        ? generateLinkImage(row.picture_thumb)
                        : "/product.png"
                    }
                    imgProps={{ loading: "lazy" }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </Box>
              }
              slotProps={{
                popper: { disablePortal: true, sx: { zIndex: 10 } },
                tooltip: {
                  sx: {
                    padding: 0,
                    marginTop: "2px !important",
                    backgroundColor: "common.white",
                    boxShadow: "0px 2px 5px 0px #00000033",
                    color: "initial",
                  },
                },
              }}
            >
              <Avatar
                src={
                  row.picture_thumb
                    ? generateLinkImage(row.picture_thumb)
                    : "/product.png"
                }
                alt={row.ten_vt}
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "10px",
                  border: "1px dashed",
                  borderColor: "primary.main",
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        name: "Mã SP",
        width: "150px",
        selector: (row) => row.ma_vt,
        wrap: true,
      },
      {
        name: "Tên sản phẩm",
        width: "200px",
        selector: (row) => row.ten_vt,
        wrap: true,
      },
      {
        name: "Đơn vị tính",
        width: "150px",
        selector: (row) => row.ten_dvt,
        wrap: true,
      },
      {
        name: "Nhóm hàng",
        width: "200px",
        selector: (row) => row.ten_nvt,
      },
      {
        name: "Giá bán",
        width: "150px",
        selector: (row) => row.gia_ban_le,
        format: (row) => numeralCustom(row.gia_ban_le).format(),
      },
      {
        name: "Tồn kho",
        width: "150px",
        selector: (row) => row.ton00,
        format: (row) => numeralCustom(row.ton00).format(),
      },
    ],
  },
  nhomhang: {
    apiCode: "dmnvt",
    title: "Nhóm sản phẩm",
    formTitle: "Nhóm sản phẩm",
    // condition: { la_nhom_hang_hoa: true },
    allowCreate: true,
    Filter: FilterNhomHang,
    Form: FormNhomHang,
    columns: [
      {
        name: "Tên nhóm",
        width: "300px",
        wrap: true,
        selector: (row) => row.ten_nvt,
      },
      {
        name: "Nhóm mẹ",
        wrap: true,
        selector: (row) => row.ten_nh_me,
      },
    ],
  },
  nhomhangpos: {
    apiCode: "dmnvt",
    title: "Nhóm sản phẩm POS",
    formTitle: "Nhóm sản phẩn POS",
    condition: { la_nhom_hang_hoa: false },
    allowCreate: true,
    Filter: FilterNhomHangPOS,
    Form: FormNhomHangPOS,
    columns: [
      {
        name: "Tên nhóm",
        width: "300px",
        wrap: true,
        selector: (row) => row.ten_nvt,
      },
      {
        name: "Nhóm mẹ",
        wrap: true,
        selector: (row) => row.ten_nh_me,
      },
    ],
  },
  dmqddvt: {
    apiCode: "dmqddvt",
    title: "Quy đổi đơn vị tính",
    formTitle: "quy đổi đơn vị tính",
    allowCreate: true,
    fields: [
      { value: "ma_vt", label: "Mã Sản phẩm" },
      { value: "ten_vt", label: "Tên Sản phẩm" },
      { value: "ma_dvt_goc", label: "Đơn vị tính gốc" },
      { value: "ten_dvt", label: "Đơn vị tính quy đổi" },
    ],
    Form: FormDMQDDVT,
    Filter: FilterDMQDDVT,
    columns: [
      {
        name: "Sản phẩm",
        width: "300px",
        wrap: true,
        selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
      },
      {
        name: "Đơn vị gốc",
        width: "120px",
        wrap: true,
        selector: (row) => row.ma_dvt_goc,
      },
      {
        name: "Đơn vị quy đổi",
        width: "150px",
        wrap: true,
        selector: (row) => row.ma_dvt,
      },
      {
        name: "SL quy đổi",
        width: "150px",
        wrap: true,
        selector: (row) => row.tu,
      },
      {
        name: "Giá bán",
        width: "120px",
        wrap: true,
        selector: (row) => row.gia_ban_nt,
        format: (row) => numeralCustom(row.gia_ban_nt).format(),
      },
    ],
  },
  dmdvt: {
    apiCode: "dmdvt",
    title: "Đơn vị tính",
    formTitle: "Đơn vị tính",
    allowCreate: true,
    Form: FormDMDVT,
    Filter: FilterDMDVT,
    fields: [
      { label: "Mã", value: "ma_dvt" },
      { label: "Tên", value: "ten_dvt" },
    ],
    columns: [
      {
        name: "Tên đơn vị tính",
        selector: (row) => row.ten_dvt,
      },
    ],
  },
  cdvt: {
    apiCode: "cdvt",
    title: "Tồn đầu kỳ",
    formTitle: "Tồn đầu kỳ",
    allowCreate: true,
    fields: [
      { value: "name", label: "Năm" },
      { value: "ten_vt", label: "Sản phẩm" },
      { value: "ten_kho", label: "Chi nhánh" },
    ],
    Form: FormCDVT,
    Filter: FilterCDVT,
    columns: [
      {
        name: "Năm",
        width: "80px",
        selector: (row) => row.nam,
      },
      {
        name: "Sản phẩm",
        width: "200px",
        wrap: true,
        selector: (row) => (row.ten_vt ? `${row.ten_vt} (${row.ma_vt})` : ""),
      },
      {
        name: "Chi nhánh",
        width: "150px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Tồn đầu kỳ",
        width: "150px",
        wrap: true,
        selector: (row) => row.ton00,
      },
      {
        name: "Giá trị tồn",
        width: "150px",
        wrap: true,
        selector: (row) => row.du00,
        format: (row) => numeralCustom(row.du00).format(),
      },
    ],
  },
  // pkk: {
  //   apiCode: "pkk",
  //   title: "Kiểm kê",
  //   formTitle: "Kiểm kê",
  //   allowCreate: true,
  //   fields: [
  //     { value: "so_ct", label: "Số chứng từ" },
  //     { value: "ten_kho", label: "Chi nhánh" },
  //     { value: "ngay_ct", label: "Ngày chứng từ" },
  //     { value: "t_sl_ton_ss", label: "SL tồn sổ sách" },
  //     { value: "t_sl_ton_tt", label: "SL tồn thực tế" },
  //     { value: "t_sl", label: "SL chênh lệch" },
  //   ],
  //   Form: FormPKK,
  //   Filter: FilterPKK,
  //   Expand: ExpandPkk,
  //   columns: [
  //     {
  //       name: "Số CT",
  //       width: "100px",
  //       wrap: true,
  //       selector: (row) => row.so_ct,
  //     },
  //     {
  //       name: "Chi nhánh",
  //       width: "150px",
  //       selector: (row) => row.ten_kho,
  //     },
  //     {
  //       name: "Ngày chứng từ",
  //       width: "200px",
  //       selector: (row) => row.ngay_ct,
  //       format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
  //     },
  //     {
  //       name: "Tổng tồn sổ sách",
  //       width: "180px",
  //       selector: (row) => row.t_sl_ton_ss,
  //     },
  //     {
  //       name: "Tổng tồn thực tế",
  //       width: "180px",
  //       selector: (row) => row.t_sl_ton_tt,
  //     },
  //     {
  //       name: "Tổng chênh lệch",
  //       width: "180px",
  //       selector: (row) => row.t_sl,
  //     },
  //   ],
  // },
  pbl: {
    apiCode: "pbl",
    title: "Phiếu bán lẻ",
    formTitle: "Phiếu bán lẻ",
    allowCreate: true,
    fields: [
      { value: "so_ct", label: "Số chứng từ" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ngay_ct", label: "Ngày chứng từ" },
    ],
    Filter: FilterPBL,
    Expand: ExpandPBL,
    Form: FormPBL,
    columns: [
      {
        name: "Trạng thái",
        width: "120px",
        selector: (row) => row.ten_trang_thai,
        cell: (row) => {
          return (
            <Typography sx={{ color: row.color, fontWeight: 600 }}>
              {row.ten_trang_thai}
            </Typography>
          );
        },
      },
      {
        name: "Mã vận đơn",
        width: "130px",
        selector: (row) => row.post_code,
        wrap: true,
      },
      {
        name: "Số CT",
        width: "80px",
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "180px",
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Khách hàng",
        wrap: true,
        width: "120px",
        selector: (row) => row.ten_kh,
      },
      {
        name: "Tổng tiền hàng",
        width: "180px",
        selector: (row) => row.t_tien_nt,
        format: (row) => numeralCustom(row.t_tien_nt).format(),
      },
      {
        name: "Tổng CK",
        width: "120px",
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
      },
      {
        name: "Thành tiền",
        width: "120px",
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
      },
    ],
  },
  sogcapp: {
    apiCode: "sogcapp",
    title: "Phiếu bán hàng APP",
    formTitle: "Phiếu bán hàng APP",
    fields: [
      { value: "so_ct", label: "Số chứng từ" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ngay_ct", label: "Ngày chứng từ" },
    ],
    Filter: FilterSOGCAPP,
    Expand: ExpandSOGCAPP,
    Form: FormSOGCAPP,
    columns: [
      {
        name: "Trạng thái",
        width: "120px",
        selector: (row) => row.ten_trang_thai,
        cell: (row) => {
          return (
            <Typography sx={{ color: row.color, fontWeight: 600 }}>
              {row.ten_trang_thai}
            </Typography>
          );
        },
      },
      {
        name: "Mã vận đơn",
        width: "130px",
        selector: (row) => row.post_code,
        wrap: true,
      },
      {
        name: "Số CT",
        width: "80px",
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "180px",
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Khách hàng",
        wrap: true,
        width: "120px",
        selector: (row) => row.ten_kh,
      },
      {
        name: "Tổng tiền hàng",
        width: "180px",
        selector: (row) => row.t_tien_nt,
        format: (row) => numeralCustom(row.t_tien_nt).format(),
      },
      {
        name: "Tổng CK",
        width: "120px",
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
      },
      {
        name: "Thành tiền",
        width: "120px",
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
      },
    ],
  },
  // hd7: {
  //   apiCode: "hd7",
  //   title: "Trả hàng",
  //   formTitle: "Trả hàng",
  //   allowCreate: true,
  //   fields: [
  //     { value: "so_ct", label: "Số chứng từ" },
  //     { value: "ten_kho", label: "Chi nhánh" },
  //     { value: "ten_kh", label: "Khách hàng" },
  //     { value: "ten_nv", label: "Nhân viên" },
  //     { value: "ngay_ct", label: "Ngày chứng từ" },
  //   ],
  //   Filter: FilterHD7,
  //   Expand: ExpandHD7,
  //   Form: FormHD7,
  //   columns: [
  //     {
  //       name: "Số CT",
  //       width: "80px",
  //       wrap: true,
  //       selector: (row) => row.so_ct,
  //     },
  //     {
  //       name: "Ngày CT",
  //       width: "160px",
  //       wrap: true,
  //       selector: (row) => row.ngay_ct,
  //       format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY - HH:mm"),
  //     },
  //     {
  //       name: "Khách hàng",
  //       width: "140px",
  //       wrap: true,
  //       selector: (row) => row.ten_kh,
  //     },
  //     {
  //       name: "Chi nhánh",
  //       width: "140px",
  //       wrap: true,
  //       selector: (row) => row.ten_kho || row.ma_kho,
  //     },
  //     {
  //       name: "Tổng tiền hàng",
  //       width: "180px",
  //       wrap: true,
  //       selector: (row) => row.t_tien_hang_nt,
  //       format: (row) => numeralCustom(row.t_tien_hang_nt).format(),
  //     },
  //     {
  //       name: "Tổng CK",
  //       width: "100px",
  //       wrap: true,
  //       selector: (row) => row.t_ck_nt,
  //       format: (row) => numeralCustom(row.t_ck_nt).format(),
  //     },
  //     {
  //       name: "Thành tiền",
  //       width: "150px",
  //       selector: (row) => row.t_tien_nhap_nt,
  //       format: (row) => numeralCustom(row.t_tien_nhap_nt).format(),
  //     },
  //   ],
  // },
  pn1: {
    apiCode: "pn1",
    title: "Phiếu nhập hàng",
    formTitle: "Phiếu nhập hàng",
    allowCreate: true,
    Filter: FilterPN1,
    Expand: ExpandPN1,
    Form: FormPN1,
    fields: [
      { value: "so_ct", label: "Số chứng từ" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ngay_ct", label: "Ngày chứng từ" },
      { value: "ten_kh", label: "Nhà cung cấp" },
    ],
    columns: [
      {
        name: "Số CT",
        width: "80px",
        wrap: true,
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "180px",
        wrap: true,
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Nhà cung cấp",
        width: "140px",
        wrap: true,
        selector: (row) => row.ten_kh,
      },
      {
        name: "Tiền hàng",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_tien_hang,
        format: (row) => numeralCustom(row.t_tien_hang).format(),
      },
      {
        name: "Tiền phí",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_cp_nt,
        format: (row) => numeralCustom(row.t_cp_nt).format(),
      },
      {
        name: "Tiền thuế",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_thue_vao_nt,
        format: (row) => numeralCustom(row.t_thue_vao_nt).format(),
      },
      {
        name: "Tiền nhập",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
      },
    ],
  },
  // pn5: {
  //   apiCode: "pn5",
  //   title: "Phiếu trả hàng nhập",
  //   formTitle: "Phiếu trả hàng nhập",
  //   allowCreate: true,
  //   fields: [
  //     { value: "so_ct", label: "Số CT" },
  //     { value: "ten_kho", label: "Chi nhánh" },
  //     { value: "ten_kh", label: "Nhà cung cấp" },
  //   ],
  //   Filter: FilterPN5,
  //   Form: FormPN5,
  //   Expand: ExpandPN5,
  //   columns: [
  //     {
  //       name: "Số CT",
  //       selector: (row) => row.so_ct,
  //       width: "80px",
  //       wrap: true,
  //     },
  //     {
  //       name: "Ngày CT",
  //       width: "180px",
  //       wrap: true,
  //       selector: (row) => row.ngay_ct,
  //       format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
  //     },
  //     {
  //       name: "Chi nhánh",
  //       selector: (row) => row.ten_kho || row.ma_kho,
  //       width: "150px",
  //       wrap: true,
  //     },
  //     {
  //       name: "Nhà cung cấp",
  //       selector: (row) => row.ma_kh,
  //       width: "150px",
  //       wrap: true,
  //     },
  //     {
  //       name: "Tiền hàng",
  //       selector: (row) => row.t_tien_hang_nt,
  //       format: (row) => numeralCustom(row.t_tien_hang_nt).format(),
  //       width: "150px",
  //       wrap: true,
  //     },
  //     {
  //       name: "Tiền thuế",
  //       selector: (row) => row.t_thue_nt,
  //       format: (row) => numeralCustom(row.t_thue_nt).format(),
  //       width: "150px",
  //       wrap: true,
  //     },
  //     {
  //       name: "Tiền trả",
  //       selector: (row) => row.t_tt_nt,
  //       format: (row) => numeralCustom(row.t_tt_nt).format(),
  //       width: "150px",
  //       wrap: true,
  //     },
  //   ],
  // },
  pxk: {
    apiCode: "pxk",
    title: "Phiếu xuất hủy",
    formTitle: "Phiếu xuất hủy",
    allowCreate: true,
    fields: [
      { value: "so_ct", label: "Số CT" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ten_kh", label: "Nhà cung cấp" },
    ],
    Filter: FilterPXH,
    Expand: ExpandPXK,
    Form: FormPXK,
    columns: [
      {
        name: "Số CT",
        width: "100px",
        wrap: true,
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "160px",
        wrap: true,
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Nhà cung cấp",
        width: "150px",
        wrap: true,
        selector: (row) => row.ten_kh || row.ma_kh,
      },
      {
        name: "Tổng số lượng",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_sl,
      },
      {
        name: "Tiền hủy",
        width: "150px",
        wrap: true,
        selector: (row) => row.t_tien_xuat,
        format: (row) => numeralCustom(row.t_tien_xuat).format(),
      },
    ],
  },
  pt1: {
    apiCode: "pt1",
    title: "Phiếu thu",
    formTitle: "Phiếu thu",
    allowCreate: true,
    fields: [
      { value: "so_ct", label: "Số CT" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ten_kh", label: "Người nộp" },
    ],
    Filter: FilterPT1,
    Expand: ExpandPT1,
    Form: FormPT1,
    columns: [
      {
        name: "Số CT",
        width: "80px",
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "160px",
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        selector: (row) => row.ten_kho,
      },
      {
        name: "Người nộp",
        width: "200px",
        wrap: true,
        selector: (row) => row.ten_kh,
      },
      {
        name: "Giá trị",
        width: "100px",
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
      },
      {
        name: "Ghi chú",
        wrap: true,
        selector: (row) => row.dien_giai,
      },
    ],
  },
  pc1: {
    apiCode: "pc1",
    title: "Phiếu chi",
    formTitle: "Phiếu chi",
    allowCreate: true,
    fields: [
      { value: "so_ct", label: "Số CT" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ten_kh", label: "Người nhận" },
    ],
    Filter: FilterPC1,
    Expand: ExpandPC1,
    Form: FormPC1,
    columns: [
      {
        name: "Số CT",
        width: "80px",
        selector: (row) => row.so_ct,
      },
      {
        name: "Ngày CT",
        width: "160px",
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
      },
      {
        name: "Chi nhánh",
        width: "120px",
        selector: (row) => row.ten_kho,
      },
      {
        name: "Người nhận",
        selector: (row) => row.ten_kh,
        width: "200px",
        wrap: true,
      },
      {
        name: "Giá trị",
        width: "150px",
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
      },
      {
        name: "Ghi chú",
        wrap: true,
        selector: (row) => row.dien_giai,
      },
    ],
  },
  customer: {
    apiCode: "customer",
    title: "Danh sách khách hàng",
    formTitle: "khách hàng",
    allowCreate: true,
    condition: { kh_yn: true },
    fields: [
      { value: "ma_kh", label: "Mã khách" },
      { value: "ten_kh", label: "Tên khách" },
      { value: "email", label: "Email" },
      { value: "dien_thoai", label: "Điện thoại" },
      { value: "ten_nh_kh", label: "Nhóm khách" },
    ],
    Form: FormCustomer,
    Filter: FilterCustomer,
    columns: [
      {
        name: "Mã",
        selector: (row) => row.ma_kh,
        width: "150px",
      },
      {
        name: "Tên",
        selector: (row) => row.ten_kh,
        width: "200px",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        width: "200px",
      },
      {
        name: "Điện thoại",
        selector: (row) => row.dien_thoai,
        width: "120px",
      },
      {
        name: "Nhóm khách",
        selector: (row) => row.ten_nh_kh,
        width: "200px",
      },
      {
        name: "Ghi chú",
        selector: (row) => row.dien_giai,
        width: "200px",
      },
    ],
  },
  dmnhkh: {
    apiCode: "dmnhkh",
    title: "nhóm khách",
    formTitle: "nhóm khách",
    allowCreate: true,
    fields: [{ value: "group_name", label: "Tên nhóm" }],
    Filter: FilterDMNHKH,
    Form: FormDMNHKH,
    columns: [
      {
        name: "Tên nhóm",
        selector: (row) => row.group_name,
      },
    ],
  },
  dmncc: {
    apiCode: "customer",
    title: "Danh sách nhà cung cấp",
    formTitle: "nhà cung cấp",
    condition: { ncc_yn: true },
    allowCreate: true,
    Filter: FilterDMNCC,
    Form: FormNCC,
    columns: [
      {
        name: "Mã NCC",
        width: "130px",
        wrap: true,
        selector: (row) => row.ma_kh,
      },
      {
        name: "Tên NCC",
        width: "200px",
        wrap: true,
        selector: (row) => row.ten_kh,
      },
      {
        name: "Số điện thoại",
        width: "140px",
        selector: (row) => row.dien_thoai,
      },
      {
        name: "Email",
        width: "140px",
        wrap: true,
        selector: (row) => row.email,
      },
      {
        name: "Mã số thuế",
        width: "120px",
        wrap: true,
        selector: (row) => row.ma_so_thue,
      },
      {
        name: "Nhóm NCC",
        width: "120px",
        wrap: true,
        selector: (row) => row.ten_nh_kh,
      },
      {
        name: "Địa chỉ",
        width: "120px",
        wrap: true,
        selector: (row) => row.dia_chi,
      },
    ],
  },
  dmnhncc: {
    apiCode: "dmnhncc",
    title: "nhóm nhà cung cấp",
    formTitle: "nhóm nhà cung cấp",
    fields: [{ value: "group_name", label: "Tên nhóm" }],
    allowCreate: true,
    Filter: FilterDMNHNCC,
    Form: FormDMNHNCC,
    columns: [
      {
        name: "Tên nhóm",
        selector: (row) => row.group_name,
      },
    ],
  },
  chietkhausp: {
    apiCode: "dmchietkhau",
    condition: { "exfields.loai_chiet_khau": 2 },
    title: "Chiết khấu sản phẩm",
    formTitle: "Chiết khấu sản phẩm",
    fields: [
      { value: "ma_chietkhau", label: "Mã chiết khấu" },
      { value: "ten_chietkhau", label: "Tên chiết khấu" },
    ],
    allowCreate: true,
    Form: FormChietKhauSP,
    Filter: FilterChietkhauSP,
    columns: [
      {
        name: "Mã",
        selector: (row) => row.ma_chietkhau,
        width: "120px",
        wrap: true,
      },
      {
        name: "Tên",
        selector: (row) => row.ten_chietkhau,
        width: "200px",
        wrap: true,
      },
      {
        name: "Sản phẩm",
        selector: (row) => row.ten_vt,
        width: "150px",
        wrap: true,
      },
      {
        name: "Nhóm SP",
        selector: (row) => row.ten_nvt,
        width: "150px",
        wrap: true,
      },
      {
        name: "Tỷ lệ Ck",
        selector: (row) => row.ty_le_ck,
        format: (row) => numeralCustom(row.ty_le_ck).format(),
        width: "120px",
        wrap: true,
      },
      {
        name: "Tiền Ck",
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        width: "120px",
        wrap: true,
      },
      {
        name: "Từ ngày",
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu, "DD/MM/YYYY HH:mm"),
        width: "150px",
        wrap: true,
      },
      {
        name: "Đến ngày",
        selector: (row) => row.hieu_luc_den,
        format: (row) =>
          formatDateDisplay(row.hieu_luc_den, "DD/MM/YYYY HH:mm"),
        width: "150px",
        wrap: true,
      },
      {
        name: "Chi nhánh",
        selector: (row) => row.ten_kho,
        width: "150px",
        wrap: true,
      },
    ],
  },
  usergroup: {
    apiCode: "usergroup",
    title: "Nhóm người dùng",
    formTitle: "Nhóm người dùng",
    fields: [{ value: "group_name", label: "Tên nhóm" }],
    allowCreate: true,
    Expand: ExpandUsergroup,
    Filter: FilterUserGroup,
    Form: FormUsergroup,
    columns: [
      {
        name: "Tên nhóm",
        width: "200px",
        wrap: true,
        selector: (row) => row.group_name,
      },
    ],
  },
  participant: {
    apiCode: "participant",
    title: "Người dùng",
    formTitle: "người dùng",
    fields: [
      { value: "email", label: "Tài khoản" },
      { value: "name", label: "Tên" },
    ],
    Filter: FilterParticipant,
    Form: FormParticipant,
    columns: [
      {
        name: "Tài khoản",
        width: "200px",
        wrap: true,
        selector: (row) => row.email,
      },
      {
        name: "Tên",
        width: "200px",
        wrap: true,
        selector: (row) => row.name,
      },
      {
        name: "Nhóm",
        width: "140px",
        wrap: true,
        selector: (row) => row.group_name,
      },
      {
        name: "Quản trị viên",
        width: "200px",
        wrap: true,
        selector: (row) => row.admin,
        cell: (row) => {
          return (
            <CheckboxInput
              checked={row.admin}
              readOnly
              wrapperSx={{ display: "flex", justifyContent: "center" }}
            />
          );
        },
      },
    ],
  },
  dmkho: {
    apiCode: "dmkho",
    title: "Chi nhánh",
    formTitle: "Chi nhánh",
    fields: [
      { value: "ma_kho", label: "Mã chi nhánh" },
      { value: "ten_kho", label: "Tên chi nhánh" },
      { value: "exfields.dien_thoai", label: "Điện thoại" },
    ],
    allowCreate: true,
    Form: FormDmKho,
    Filter: FilterDMKHO,
    columns: [
      {
        name: "Mã",
        width: "120px",
        wrap: true,
        selector: (row) => row.ma_kho,
      },
      {
        name: "Tên",
        width: "200px",
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: "Địa chỉ",
        width: "200px",
        wrap: true,
        selector: (row) => row.dia_chi,
      },
      {
        name: "Điện thoại",
        width: "150px",
        wrap: true,
        selector: (row) => row.exfields?.dien_thoai,
      },
      {
        name: "Email",
        width: "200px",
        wrap: true,
        selector: (row) => row.exfields?.email,
      },
    ],
  },
  ptthanhtoan: {
    apiCode: "ptthanhtoan",
    title: "Phương thức thanh toán",
    formTitle: "Phương thức thanh toán",
    fields: [{ value: "ten", label: "Tên" }],
    allowCreate: true,
    Filter: FilterPTTHANHTOAN,
    Form: FormPTTHANHTOAN,
    columns: [
      {
        name: "Tên",
        selector: (row) => row.ten,
        width: "150px",
      },
      {
        name: "Tài khoản",
        selector: (row) => row.tai_khoan_nh,
        width: "120px",
      },
      {
        name: "Chủ tài khoản",
        selector: (row) => row.chu_tai_khoan,
        width: "140px",
      },
      {
        name: "Ngân hàng",
        selector: (row) => row.ngan_hang,
        width: "140px",
      },
    ],
  },
  dmkenhbanhang: {
    apiCode: "dmkenhbanhang",
    title: "Kênh bán",
    formTitle: "Kênh bán",
    fields: [
      { value: "ma_kenh", label: "Mã kênh" },
      { value: "ten_kenh", label: "Tên kênh" },
    ],
    allowCreate: true,
    Filter: FilterDMKENHBAN,
    Form: FormDMKENHBAN,
    columns: [
      {
        name: "Mã Kênh",
        selector: (row) => row.ma_kenh,
        width: "120px",
      },
      {
        name: "Tên Kênh",
        selector: (row) => row.ten_kenh,
      },
    ],
  },
  giaoca: {
    apiCode: "giaoca",
    title: "Giao ca",
    formTitle: "Giao ca",
    fields: [
      { value: "nhan_vien", label: "Nhân viên" },
      { value: "nhan_vien_nhan", label: "Nhân viên nhận" },
      { value: "ten_kho", label: "Chi nhánh" },
      { value: "ma_ca", label: "Ca" },
    ],
    allowCreate: true,
    Form: FormGiaoCa,
    Filter: FilterGiaoCa,
    columns: [
      {
        name: "Nhân viên",
        selector: (row) => row.nhan_vien,
        wrap: true,
        width: "150px",
      },
      {
        name: "Nhân viên nhận",
        selector: (row) => row.nhan_vien_nhan,
        wrap: true,
        width: "150px",
      },
      {
        name: "Chi nhánh",
        selector: (row) => row.ten_kho,
        wrap: true,
        width: "150px",
      },
      {
        name: "Ca",
        selector: (row) => row.ma_ca,
        wrap: true,
        width: "150px",
      },
      {
        name: "Từ ngày",
        selector: (row) => row.tu_ngay,
        format: (row) => formatDateDisplay(row.tu_ngay, "DD/MM/YYYY HH:mm"),
        width: "140px",
        wrap: true,
      },
      {
        name: "Đến ngày",
        selector: (row) => row.den_ngay,
        format: (row) => formatDateDisplay(row.den_ngay, "DD/MM/YYYY HH:mm"),
        width: "140px",
        wrap: true,
      },
      {
        name: "Tiền hàng",
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        wrap: true,
        width: "150px",
      },
      {
        name: "Tiền giao",
        selector: (row) => row.tien_giao,
        format: (row) => numeralCustom(row.tien_giao).format(),
        wrap: true,
      },
    ],
  },
  dmca: {
    apiCode: "dmca",
    title: "Ca làm việc",
    formTitle: "Ca làm việc",
    fields: [
      { value: "ma_ca", label: "Mã ca" },
      { value: "ten_ca", label: "Tên ca" },
    ],
    allowCreate: true,
    Form: FormDMCA,
    Filter: FilterDMCA,
    columns: [
      {
        name: "Mã ca",
        selector: (row) => row.ma_ca,
        width: "200px",
        wrap: true,
      },
      {
        name: "Tên ca",
        selector: (row) => row.ten_ca,
        wrap: true,
      },
    ],
  },
  rpt: {
    apiCode: "rpt",
    title: "Mẫu in",
    formTitle: "mẫu in",
    fields: [
      { value: "ma_cn", label: "Mã chức năng" },
      { value: "ten_mau_in", label: "Tên mẫu in" },
    ],
    allowCreate: true,
    Form: FormRPT,
    Filter: FilterRPT,
    columns: [
      {
        name: "Mã CN",
        selector: (row) => row.ma_cn,
        width: "200px",
      },
      {
        name: "Tên mẫu in",
        selector: (row) => row.ten_mau_in,
      },
    ],
  },
  dvvanchuyen: {
    apiCode: "dvvanchuyen",
    title: "Đơn vị vận chuyển",
    formTitle: "Đơn vị vận chuyển",
    fields: [
      { value: "ma", label: "Mã đơn vị" },
      { value: "ten", label: "Tên đơn vị" },
    ],
    allowCreate: true,
    Form: FormDVVANCHUYEN,
    Filter: FilterDVVANCHUYEN,
    columns: [
      {
        name: "Mã",
        selector: (row) => row.ma,
        wrap: true,
        width: "120px",
      },
      {
        name: "Tên",
        selector: (row) => row.ten,
        wrap: true,
        width: "200px",
      },
      {
        name: "API Token",
        selector: (row) => row.api_token,
        wrap: true,
      },
    ],
  },
  dmkhuyenmai: {
    apiCode: "dmkhuyenmai",
    title: "khuyến mãi, quà tặng",
    formTitle: "khuyến mãi, quà tặng",
    fields: [
      { value: "ma", label: "Mã đơn vị" },
      { value: "ten", label: "Tên đơn vị" },
    ],
    allowCreate: true,
    Form: FormDMKHUYENMAI,
    Filter: FilterDMKHUYENMAI,
    columns: [
      {
        name: "Ưu tiên",
        selector: (row) => row.stt_uu_tien,
        width: "80px",
        wrap: true,
      },
      {
        name: "Sản phẩm mua",
        selector: (row) => row.ten_vt,
        width: "200px",
        wrap: true,
      },
      {
        name: "Chi nhánh",
        selector: (row) => row.ten_kho,
        width: "140px",
        wrap: true,
      },
      {
        name: "Hiệu lực từ",
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu, "DD/MM/YYYY HH:mm"),
        width: "140px",
        wrap: true,
      },
      {
        name: "Hiệu lực đến",
        selector: (row) => row.hieu_luc_den,
        format: (row) =>
          formatDateDisplay(row.hieu_luc_den, "DD/MM/YYYY HH:mm"),
        width: "140px",
        wrap: true,
      },
      {
        name: "SL từ",
        selector: (row) => row.sl_tu,
        width: "100px",
        wrap: true,
      },
      {
        name: "SL đến",
        selector: (row) => row.sl_den,
        width: "100px",
        wrap: true,
      },
    ],
  },
  dmnv: {
    apiCode: "dmnv",
    title: "Nhân viên",
    formTitle: "Nhân viên",
    fields: [
      { value: "ma_nv", label: "Mã NV" },
      { value: "ten_nv", label: "Tên NV" },
    ],
    allowCreate: true,
    Form: FormDMNV,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_nv,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_nv,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.dien_thoai,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Ngày sinh',
        selector: (row) => row.ngay_sinh,
        format: (row) => formatDateDisplay(row.ngay_sinh),
        width: '120px',
      },
      // {
      //   name: 'Giới tính',
      //   selector: (row) => row.gioi_tinh,
      //   width: '120px',
      //   wrap: true,
      // },
      {
        name: 'Email',
        selector: (row) => row.email,
        width: '140px',
        wrap: true,
      },
    ]
  }
};

export { lists };
