import React from "react";
import ModalBase from "./ModalBase";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import { asyncPostData } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import PasswordInput from "../input/PasswordInput";

interface IModalChangePassword {
  open: boolean;
  onClose: () => void;
}

const schema = yup.object<FieldValues>({
  oldPassword: yup.string().required("Vui lòng nhập mật khẩu cũ"),
  newPassword: yup
    .string()
    .required("Vui lòng nhập mật khẩu mới")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      "Mật khẩu phải có từ 6 ký tự, ít nhất 1 chữ cái thường và 1 chữ cái in hoa"
    ),
  reNewPassword: yup
    .string()
    .required("Vui lòng xác nhận lại mật khẩu")
    .equals([yup.ref("newPassword")], "Mật khẩu xác nhận không trùng khớp"),
});

function ModalChangePassword({ open, onClose }: IModalChangePassword) {
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData = { oldPassword: "", newPassword: "", reNewPassword: "" };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    defaultValues: defaultData,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    const resp = await asyncPostData({
      apiCode: "changepassword",
      applyToken: true,
      withIdApp: false,
      token,
      data: values,
    });
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error ||
          resp?.data?.message ||
          "Lỗi khi cập nhật mật khẩu",
      });
    } else {
      reset(defaultData);
      onClose();
      showAlert({ type: "success", message: "Cập mật khẩu thành công" });
    }
  };

  return (
    <ModalBase
      title="Thay đổi mật khẩu"
      open={open}
      handleClose={onClose}
      width="600px"
      actions={[
        <LoadingButton
          key={1}
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          size="small"
          color="primary"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu thay đổi
        </LoadingButton>,
        <Button
          key={2}
          onClick={() => {
            reset(defaultData);
            onClose();
          }}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Stack gap={2} sx={{ px: 2 }}>
        <PasswordInput
          labelWidth="25%"
          label="Mật khẩu hiện tại"
          placeholder="Nhập mật khẩu hiện tại"
          name="oldPassword"
          register={register}
          errorMessage={errors?.oldPassword?.message as string}
        />
        <PasswordInput
          labelWidth="25%"
          label="Mật khẩu mới"
          placeholder="Nhập mật khẩu mới"
          name="newPassword"
          register={register}
          errorMessage={errors?.newPassword?.message as string}
        />
        <PasswordInput
          labelWidth="25%"
          label="Nhập lại mật khẩu mới"
          placeholder="Nhập lại mật khẩu mới"
          name="reNewPassword"
          register={register}
          errorMessage={errors?.reNewPassword?.message as string}
        />
      </Stack>
    </ModalBase>
  );
}

export default ModalChangePassword;
