import React, { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { INavItem } from "../../../types/nav.type";

function NavItem({
  text,
  link,
  startIcon,
  endIcon,
  subs,
  sx,
  paperSx,
}: INavItem) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  if (link) {
    return (
      <Link to={link}>
        <Button
          variant="text"
          size="small"
          startIcon={startIcon}
          endIcon={endIcon}
          sx={{ textTransform: "none", color: "text.primary", ...sx }}
        >
          {text}
        </Button>
      </Link>
    );
  }

  return (
    <>
      {!!subs && (
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          slotProps={{
            paper: { elevation: 1, sx: { minWidth: "150px", ...paperSx } },
          }}
        >
          {!!subs &&
            subs?.length > 0 &&
            subs?.map((sub) => {
              return (
                <Link key={sub.text} to={sub.link}>
                  <MenuItem
                    onClick={handleCloseMenu}
                    sx={{ gap: 1, ...sub.sx }}
                  >
                    {sub.Icon}
                    <Typography sx={{ color: "text.primary" }}>
                      {sub.text}
                    </Typography>
                  </MenuItem>
                </Link>
              );
            })}
        </Menu>
      )}
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="text"
        size="small"
        startIcon={startIcon}
        endIcon={endIcon}
        sx={{ textTransform: "none", color: "text.primary", ...sx }}
      >
        {text}
      </Button>
    </>
  );
}

export default NavItem;
