import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Stack } from "@mui/material";
import { LuPlusCircle } from "react-icons/lu";
import { TableColumn } from "react-data-table-component";
import { numeralCustom } from "../../../../../utils/helpers";
import TableApp from "../../../tableapp/TableApp";
import {
  asyncGetList,
  asyncPostData,
  asyncPutData,
} from "../../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import FormAdd from "./FormAdd";
import { cloneDeep } from "lodash";
import { LoadingButton } from "@mui/lab";

const columns: TableColumn<any>[] = [
  {
    name: "Đơn vị tính",
    width: "200px",
    wrap: true,
    selector: (row) => row.ten_dvt,
  },
  {
    name: "Số lượng quy đổi",
    wrap: true,
    width: "200px",
    selector: (row) => row.mau,
  },
  {
    name: "Giá bán",
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
  },
];

interface IDvtSection {
  dvts: any[];
  isEdit?: boolean;
  product?: any;
  setDvts?: Dispatch<SetStateAction<any>>;
}

function DvtSection({ dvts, isEdit, product, setDvts }: IDvtSection) {
  const token = useSelector((state: any) => state.auth.token);
  const app = useSelector((state: any) => state.app.data);
  const { showAlert } = useAlertContext();
  const [show, setShow] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [load, setLoad] = useState<number>(0);
  const [isEditRow, setIsEditRow] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseFormAdd = () => {
    setDefaultValue({});
    setIsEditRow(false);
    setOpenForm(false);
  };

  const handleRowClicked = (row: any) => {
    setDefaultValue(row);
    setIsEditRow(true);
    setOpenForm(true);
  };

  const handleAdd = async (dataToAdd: any, isEditRow: boolean) => {
    if (isEdit) {
      setLoading(true);
      let resp = null;
      if (isEditRow) {
        resp = await asyncPutData({
          apiCode: "dmqddvt",
          id: defaultValue?._id,
          token,
          idApp: app?._id,
          data: {
            ...dataToAdd,
            ma_dvt_goc: product?.ma_dvt,
            ma_vt: product?.ma_vt,
            ten_vt: product?.ten_vt,
            tu: 1,
          },
        });
      } else {
        resp = await asyncPostData({
          apiCode: "dmqddvt",
          token,
          idApp: app?._id,
          data: {
            ...dataToAdd,
            ma_dvt_goc: product?.ma_dvt,
            ma_vt: product?.ma_vt,
            ten_vt: product?.ten_vt,
            tu: 1,
          },
        });
      }
      if (resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi cập nhật đơn vị tính",
        });
      } else {
        setLoad(load + 1);
      }
      setLoading(false);
    } else {
      if (isEditRow) {
        const existedIndex = dvts.findIndex(
          (item) => item.ma_dvt === dataToAdd.ma_dvt
        );
        if (existedIndex >= 0) {
          setDvts?.((prev: any[]) => {
            const prevClone = cloneDeep(prev);
            prevClone.splice(existedIndex, 1, dataToAdd);
            return prevClone;
          });
        }
      } else {
        const existedIndex = dvts.findIndex(
          (item) => item.ma_dvt === dataToAdd.ma_dvt
        );
        if (existedIndex >= 0) {
          showAlert({ type: "warning", message: "Đơn vị tính đã tồn tại" });
        } else {
          setDvts?.((prev: any[]) => [...prev, dataToAdd]);
        }
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    const resp = await asyncGetList({
      apiCode: "dmqddvt",
      token,
      idApp: app?._id,
      condition: {
        page: 1,
        limit: 99999,
        q: { ma_vt: product?.ma_vt || "", ma_dvt: { $ne: product?.ma_dvt } },
      },
    });
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải đơn vị tính",
      });
      return;
    } else {
      setData(resp.data || []);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (product?.ma_vt) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.ma_vt, load]);

  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          handleClose={handleCloseFormAdd}
          add={handleAdd}
          isEdit={isEditRow}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Đơn vị tính khác"
        show={show}
        onCollapse={() => setShow(!show)}
      >
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <LoadingButton
              loading={loading}
              onClick={() => setOpenForm(true)}
              variant="contained"
              size="small"
              startIcon={<LuPlusCircle size={16} />}
              sx={{
                boxShadow: "none",
                color: "common.white",
                textTransform: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Thêm
            </LoadingButton>
          </Stack>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "10px",
              p: 1,
            }}
          >
            <TableApp
              columns={columns}
              data={isEdit ? data : dvts}
              onRowClicked={handleRowClicked}
            />
          </Box>
        </Stack>
      </CollapseSection>
    </>
  );
}

export default DvtSection;
