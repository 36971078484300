import React, { Dispatch, SetStateAction, useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Stack } from "@mui/material";
import ImageInput from "../../../input/ImageInput";
import { generateLinkImage } from "../../../../../utils/helpers";

interface IImageSection {
  setThumbnails: Dispatch<SetStateAction<{ [key: string]: File | null }>>;
  defaultValues: any;
}

function ImageSection({ setThumbnails, defaultValues }: IImageSection) {
  const [show, setShow] = useState<boolean>(true);

  const handleFileChange = (key: string, file: File) => {
    setThumbnails((prev) => {
      return { ...prev, [key]: file };
    });
  };

  return (
    <CollapseSection
      title="Hình ảnh"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <Stack direction="row" gap={2}>
        <ImageInput
          wrapperSx={{ width: "100px", height: "100px" }}
          url={
            defaultValues?.picture
              ? generateLinkImage(defaultValues?.picture)
              : ""
          }
          onChange={(file) => handleFileChange("picture", file as File)}
        />
        <ImageInput
          wrapperSx={{ width: "100px", height: "100px" }}
          url={
            defaultValues?.picture2
              ? generateLinkImage(defaultValues?.picture2)
              : ""
          }
          onChange={(file) => handleFileChange("picture2", file as File)}
        />
        <ImageInput
          wrapperSx={{ width: "100px", height: "100px" }}
          url={
            defaultValues?.picture3
              ? generateLinkImage(defaultValues?.picture3)
              : ""
          }
          onChange={(file) => handleFileChange("picture3", file as File)}
        />
      </Stack>
    </CollapseSection>
  );
}

export default ImageSection;
