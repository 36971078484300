import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import InfoSection from "./info-section/InfoSection";

const schema = yup.object({
  ten: yup.string().required("Vui lòng nhập tên"),
});

function FormPTTHANHTOAN({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ten: "",
    tai_khoan_nh: "",
    chu_tai_khoan: "",
    ngan_hang: "",
    dien_giai: "",
    kieu_so1: 0,
    tk_tien: null,
    tk_diem: null,
    tk_cn: null,
    tk_ck: null,
    tk_dt: null,
    tk_gv: null,
    tk_tl: null,
    hinh_thuc: 0,
    status: true,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        tk_tien: defaultValues.tk_tien
          ? {
              tk: defaultValues.tk_tien,
            }
          : null,
        tk_diem: defaultValues.tk_diem
          ? {
              tk: defaultValues.tk_diem,
            }
          : null,
        tk_cn: defaultValues.tk_cn
          ? {
              tk: defaultValues.tk_cn,
            }
          : null,
        tk_dt: defaultValues.tk_dt
          ? {
              tk: defaultValues.tk_dt,
            }
          : null,
        tk_tl: defaultValues.tk_tl
          ? {
              tk: defaultValues.tk_tl,
            }
          : null,
        tk_ck: defaultValues.tk_ck
          ? {
              tk: defaultValues.tk_ck,
            }
          : null,
        tk_gv: defaultValues.tk_gv
          ? {
              tk: defaultValues.tk_gv,
            }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { tk_tien, tk_diem, tk_cn, tk_dt, tk_tl, tk_ck, tk_gv, ...fields } =
      values;
    return {
      ...fields,
      tk_tien: tk_tien?.tk,
      tk_diem: tk_diem?.tk,
      tk_cn: tk_cn?.tk,
      tk_dt: tk_dt?.tk,
      tk_tl: tk_tl?.tk,
      tk_ck: tk_ck?.tk,
      tk_gv: tk_gv?.tk,
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="800px"
      formTitle="phương thức thanh toán"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection errors={errors} control={control} register={register} />
      </Stack>
    </ModalForm>
  );
}

export default FormPTTHANHTOAN;
