import React, { useEffect, useState } from "react";
import { Box, Grid2, Stack, Typography } from "@mui/material";
import Summary from "../../../components/common/summary";
import { groupBy, numeralCustom } from "../../../utils/helpers";
import moment from "moment";
import { asyncGetReport } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";

function ResultSellToday() {
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [result, setResult] = useState<{
    doanh_thu: number;
    tra_lai: number;
    doanh_thu_thuan: number;
    orders: number;
    customers: number;
  }>({
    doanh_thu: 0,
    tra_lai: 0,
    doanh_thu_thuan: 0,
    orders: 0,
    customers: 0,
  });

  const getReport = async () => {
    const today = moment();
    const resp = await asyncGetReport({
      apiCode: "ctbanle",
      token,
      query: {
        ma_ct: "pbl,hd7",
        tu_ngay: today
          .clone()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toISOString(),
        den_ngay: today
          .clone()
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString(),
      },
    });
    if (!resp || resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error ||
          resp?.data?.message ||
          "Lỗi khi thống kê báo cáo",
      });
      return;
    }
    const data = resp?.data || [];
    const total = data?.pop();
    const hds = (data || []).filter(
      (item: any) => item.ma_ct.toLowerCase() === "pbl"
    );
    const orders = groupBy({
      data: hds,
      callbackMatch: (item) => item.so_ct,
    }) as any[];
    const customers = groupBy({
      data: hds,
      callbackMatch: (item) => item.ma_kh,
    }) as any[];

    setResult({
      ...result,
      doanh_thu: total?.tien_xuat || 0,
      tra_lai: total?.lai || 0,
      doanh_thu_thuan: total?.tien || 0,
      orders: orders?.length || 0,
      customers: customers?.length || 0,
    });
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ p: 2, borderRadius: "10px", backgroundColor: "background.paper" }}
    >
      <Typography sx={{ fontWeight: 600, mb: 1 }}>
        KẾT QUẢ BÁN HÀNG HÔM NAY
      </Typography>
      <Grid2 container spacing={2}>
        {/* Doanh thu thuan */}
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <Summary imageUrl="/bank.png" title="Doanh thu thuần">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 550,
                  color: "secondary.main",
                }}
              >
                {numeralCustom(result.doanh_thu_thuan).format()}
              </Typography>
              {/* <Stack
                direction="row"
                alignItems="center"
                gap="2px"
                sx={{
                  color: true ? "primary.main" : "thirdly.main",
                }}
              >
                <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                  Trả hàng: {numeralCustom(result.tra_lai).format()}
                </Typography>
              </Stack> */}
            </Stack>
            {/* <Typography sx={{ fontSize: "12px", color: "gray" }}>
              Doanh thu: {numeralCustom(result.doanh_thu).format()}
            </Typography> */}
          </Summary>
        </Grid2>
        {/* Don da ban */}
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <Summary
            imageUrl="/bill.png"
            title="Đơn hàng hoàn tất"
            wrapperSx={{
              p: 1,
              width: "100%",
              backgroundColor: "secondary.600",
              borderRadius: "10px",
              height: "100%",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 550,
                  color: "secondary.main",
                }}
              >
                {numeralCustom(result.orders).format()}
              </Typography>
            </Stack>
          </Summary>
        </Grid2>
        {/* Khách hàng */}
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <Summary
            imageUrl="/customer.png"
            title="Khách hàng"
            wrapperSx={{
              p: 1,
              width: "100%",
              backgroundColor: "warning.100",
              borderRadius: "10px",
              height: "100%",
            }}
          >
            <Stack>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 550,
                  color: "secondary.main",
                }}
              >
                {numeralCustom(result.customers).format()}
              </Typography>
            </Stack>
          </Summary>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default ResultSellToday;
