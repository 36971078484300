import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ModalBase from "./ModalBase";
import { Button, Divider, Stack } from "@mui/material";
import AvatarApp from "../avatarapp/AvatarApp";
import { useDispatch, useSelector } from "react-redux";
import { generateLinkImage } from "../../../utils/helpers";
import TextInput from "../input/TextInput";
import { FieldValues, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { asyncPostData, uploadFile } from "../../../utils/httpRequests";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { updateProfile } from "../../../redux/reducers/auth.reducer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cloneDeep } from "lodash";
import ModalChangePassword from "./ModalChangePassword";

interface IModalUser {
  open: boolean;
  onClose: () => void;
}

const schema = yup.object<FieldValues>({
  phone: yup.string().matches(/^(03|05|07|08|09)\d{8,9}$/, {
    message: "Số điện thoại không hợp lệ",
    excludeEmptyString: true,
  }),
});

function ModalUser({ open, onClose }: IModalUser) {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const profile = useSelector((state: any) => state.auth.profile);
  const defaultData = {
    email: "",
    name: "",
    phone: "",
    address: "",
  };
  const originData = !!profile ? { ...defaultData, ...profile } : defaultData;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(profile?.picture);
  const [openModalChangePassword, setOpenModalChangePassword] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    setImageUrl("");
  };

  const onSubmit = async (values: any) => {
    const dataPost = cloneDeep(values);
    if (image) {
      const formData = new FormData();
      formData.append("file", image);
      const resp = await uploadFile({ formData, folder: "avatars", token });
      dataPost.picture = resp?.data?.fileUrl;
    } else if (image === null) {
      dataPost.picture = imageUrl;
    }
    const resp = await asyncPostData({
      apiCode: "updateprofile",
      applyToken: true,
      token,
      withIdApp: false,
      data: dataPost,
    });
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error ||
          resp?.data?.message ||
          "Lỗi khi cập nhật thông tin",
      });
    } else {
      showAlert({
        type: "success",
        message: "Cập nhật thành công",
      });
      dispatch(updateProfile(dataPost));
    }
  };

  useEffect(() => {
    if (image) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
    }
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <>
      <ModalChangePassword
        open={openModalChangePassword}
        onClose={() => {
          setOpenModalChangePassword(false);
        }}
      />
      <ModalBase
        title="Thông tin tài khoản"
        open={open}
        handleClose={onClose}
        width="600px"
        actions={[
          <LoadingButton
            key={1}
            loading={isSubmitting}
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": { boxShadow: "none" },
            }}
          >
            Lưu thay đổi
          </LoadingButton>,
          <Button
            key={2}
            onClick={onClose}
            variant="contained"
            size="small"
            color="error"
            sx={{
              boxShadow: "none",
              textTransform: "none",
              color: "common.white",
              "&:hover": { boxShadow: "none" },
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <Stack
          gap={2}
          sx={{ maxHeight: "calc(100vh - 100px - 50px)", overflow: "auto" }}
        >
          {/* Hình ảnh */}
          <Stack gap={1} alignItems="center" sx={{ px: 2 }}>
            <AvatarApp
              src={
                !!imageUrl
                  ? !image
                    ? generateLinkImage(profile.picture)
                    : imageUrl
                  : ""
              }
              sx={{ width: "100px", height: "100px", borderRadius: "10px" }}
            />
            <Stack direction="row" gap={1}>
              <Button
                onClick={() => inputRef.current?.click()}
                variant="contained"
                size="small"
                color="primary"
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  color: "common.white",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Chọn ảnh
              </Button>
              <input
                ref={inputRef}
                hidden
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={handleDeleteImage}
                sx={{
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Xóa ảnh
              </Button>
            </Stack>
            <Button
              onClick={() => setOpenModalChangePassword(true)}
              color="secondary"
              sx={{ textTransform: "none", fontWeight: 400 }}
            >
              Đổi mật khẩu
            </Button>
          </Stack>
          <Divider />
          {/* Thông tin */}
          <Stack gap={1} sx={{ px: 2 }}>
            <TextInput
              label="Tài khoản"
              readOnly
              placeholder="Tài khoản người dùng"
              name="email"
              register={register}
            />
            <TextInput
              label="Họ tên"
              placeholder="Nhập họ tên"
              name="name"
              register={register}
            />
            <TextInput
              label="Điện thoại"
              placeholder="Nhập số điện thoại"
              name="phone"
              register={register}
              errorMessage={errors?.phone?.message as string}
            />
            <TextInput
              label="Địa chỉ"
              placeholder="Nhập địa chỉ"
              name="address"
              register={register}
            />
          </Stack>
        </Stack>
      </ModalBase>
    </>
  );
}

export default ModalUser;
