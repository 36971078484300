import { useState, useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { useSelector } from "react-redux";
import FilterList from "../FilterList";
import { IFilterProps } from "../../../../types/filter.type";
import { useBackdropContext } from "../../../../contexts/backdrop/BackdropProvider";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import FilterText from "../FilterText";
import { asyncSearchList } from "../../../../utils/httpRequests";
import { ID_APP } from "../../../../utils/constants";

function FilterNhomHang({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    tenNvt: "",
    nhomMe: [],
  };
  const token = useSelector((state: any) => state.auth.token);
  const { setOpenBackdrop } = useBackdropContext();
  const { showAlert } = useAlertContext();
  const [groups, setGroups] = useState<any[]>([]);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const getNvt = async () => {
    try {
      setOpenBackdrop?.(true);
      const resp = await asyncSearchList({
        apiCode: "dmnvt",
        token,
        idApp: ID_APP,
        condition: { page: 1, limit: 9999999, q: { la_nhom_hang_hoa: true } },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi tải thông tin nhóm hàng",
        });
        return;
      }
      setGroups(resp?.data || []);
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  const productGroups = useMemo(() => {
    if (!groups || !Array.isArray(groups)) return [];
    return (groups || []).reduce((acc, group) => {
      return [...acc, { label: group.ten_nvt, value: group._id }];
    }, []);
  }, [groups]);

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.tenNvt) {
      condition.ten_nvt = {
        $regex: filter.tenNvt.split(" ").join(".*"),
        $options: "i",
      };
    }
    if ((filter.nhomMe || []).length > 0) {
      condition.nh_me = { $in: filter.nhomMe };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getNvt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterText
          title="Nhóm hàng"
          placeholder="Tìm theo nhóm hàng"
          value={filter.tenNvt}
          onSearch={(value) => setFilter({ ...filter, tenNvt: value })}
        />
        <FilterList
          title="Nhóm mẹ"
          items={productGroups}
          defaultValues={filter.nhomMe}
          onChange={(value) => setFilter({ ...filter, nhomMe: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterNhomHang;
