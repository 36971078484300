import React from "react";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import { formatDateDisplay } from "../../../utils/helpers";

interface IWrapperExpand {
  children?: React.ReactNode;
  data: any;
  action?: React.ReactNode;
  sx?: SxProps;
}

function WrapperExpand({ children, data, action, sx }: IWrapperExpand) {
  return (
    <Box sx={{ p: 2, ...sx }}>
      {children}
      {/* History */}
      <Stack gap={2} sx={{ pt: 2 }}>
        <Stack direction="row" gap={2}>
          <Typography>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              Tạo bởi:
            </Typography>{" "}
            <Typography component="span">{data?.user_created}</Typography>{" "}
          </Typography>
          <Typography>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              Vào lúc:
            </Typography>{" "}
            <Typography component="span">
              {formatDateDisplay(data?.date_created, "HH:mm - DD/MM/YYYY")}
            </Typography>{" "}
          </Typography>
        </Stack>
        <Stack direction="row" gap={2}>
          <Typography>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              Cập nhật bởi:
            </Typography>{" "}
            <Typography component="span">{data?.user_updated}</Typography>{" "}
          </Typography>
          <Typography>
            <Typography component="span" sx={{ fontWeight: 600 }}>
              Vào lúc:
            </Typography>{" "}
            <Typography component="span">
              {formatDateDisplay(data?.date_updated, "HH:mm - DD/MM/YYYY")}
            </Typography>{" "}
          </Typography>
        </Stack>
      </Stack>
      {action}
    </Box>
  );
}

export default WrapperExpand;
