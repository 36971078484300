import React, { useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2 } from "@mui/material";
import SelectApiInput from "../../../input/SelectApiInput";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import TextInput from "../../../input/TextInput";

interface IDescriptionSection {
  control?: Control<any, any>;
  register?: UseFormRegister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  tinhThanh?: any;
  quanHuyen?: any;
}

function GiaoHangSection({
  control,
  register,
  tinhThanh,
  quanHuyen,
  setValue,
}: IDescriptionSection) {
  const [show, setShow] = useState<boolean>(true);

  return (
    <CollapseSection
      title="Thông tin giao hàng"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            control={control}
            name="tinh_thanh"
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  requestMethod="get"
                  withIdApp={false}
                  labelWidth="25%"
                  label="Tỉnh thành"
                  placeholder="Chọn tỉnh thành"
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    setValue?.("quan_huyen", null);
                    setValue?.("xa_phuong", null);
                  }}
                  apiCode="tinhthanh"
                  searchFields={["ma_tinh_thanh", "ten_tinh_thanh"]}
                  getOptionLabel={(option) => option.ten_tinh_thanh}
                  checkEqual={(value, option) =>
                    value?.ma_tinh_thanh === option?.ma_tinh_thanh
                  }
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            control={control}
            name="quan_huyen"
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  disabled={!tinhThanh}
                  requestMethod="get"
                  withIdApp={false}
                  labelWidth="25%"
                  label="Quận huyện"
                  placeholder="Chọn quận huyện"
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    setValue?.("xa_phuong", null);
                  }}
                  apiCode="quanhuyen"
                  searchFields={["ma_quan_huyen", "ten_quan_huyen"]}
                  defaultCondition={{
                    ma_tinh_thanh: tinhThanh?.ma_tinh_thanh || undefined,
                  }}
                  getOptionLabel={(option) => option.ten_quan_huyen}
                  checkEqual={(value, option) =>
                    value?.ma_quan_huyen === option?.ma_quan_huyen
                  }
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            control={control}
            name="xa_phuong"
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  disabled={!quanHuyen}
                  requestMethod="get"
                  withIdApp={false}
                  labelWidth="25%"
                  label="Xã phường"
                  placeholder="Chọn xã phường"
                  value={value}
                  onChange={onChange}
                  apiCode="xaphuong"
                  searchFields={["ma_xa_phuong", "ten_xa_phuong"]}
                  defaultCondition={{
                    ma_quan_huyen: quanHuyen?.ma_quan_huyen || undefined,
                  }}
                  getOptionLabel={(option) => option.ten_xa_phuong}
                  checkEqual={(value, option) =>
                    value?.ma_xa_phuong === option?.ma_xa_phuong
                  }
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <TextInput
            label="Địa chỉ"
            labelWidth="25%"
            placeholder="Nhập địa chỉ cụ thể"
            name="dia_chi"
            register={register}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <TextInput
            label="Tên người nhận"
            labelWidth="25%"
            placeholder="Nhập tên người nhận"
            name="ten_nguoi_nhan"
            register={register}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <TextInput
            label="Số điện thoại"
            labelWidth="25%"
            placeholder="Nhập số điện thoại người nhận"
            name="dien_thoai"
            register={register}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            control={control}
            name="don_vi_van_chuyen"
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  labelWidth="25%"
                  label="ĐV vận chuyển"
                  placeholder="Chọn đơn vị vận chuyển"
                  value={value}
                  onChange={onChange}
                  apiCode="dvvanchuyen"
                  searchFields={["ma", "ten"]}
                  getOptionLabel={(option) => option.ten}
                  checkEqual={(value, option) => value?.ma === option?.ma}
                />
              );
            }}
          />
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default GiaoHangSection;
