import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import SelectApiInput from "../../../input/SelectApiInput";
import DateInput from "../../../input/DateInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              labelWidth="30%"
              label="Mã NV"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_nv"
              register={register}
            />
            <TextInput
              required
              labelWidth="30%"
              label="Tên NV"
              placeholder="Nhập tên nv"
              name="ten_nv"
              register={register}
              errorMessage={errors?.ten_nv?.message as string}
            />
            <Controller
              control={control}
              name="account"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK Đăng nhập"
                  apiCode="participant"
                  placeholder="Chọn tài khoản đăng nhập"
                  searchFields={["email", "name"]}
                  defaultCondition={{ status: true }}
                  getOptionLabel={(option) => option.email}
                  checkEqual={(value, option) => value?.email === option?.email}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <Controller
              name="ngay_sinh"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <DateInput
                    label="Ngày sinh"
                    labelWidth="25%"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
            <TextInput
              required
              labelWidth="25%"
              label="Điện thoại"
              placeholder="Nhập số điện thoại"
              name="dien_thoai"
              register={register}
              errorMessage={errors?.dien_thoai?.message as string}
            />
            {/* <TextInput
              labelWidth="25%"
              label="Email"
              placeholder="Nhập email"
              name="emai"
              register={register}
              errorMessage={errors?.email?.message as string}
            /> */}
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
