import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import FilterSelectApi from "../FilterSelectApi";
import { IFilterProps } from "../../../../types/filter.type";

function FilterDMQDDVT({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    vat_tu: null,
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterSelectApi
          direction="column"
          title="Sản phẩm"
          apiCode="dmvt"
          searchFields={["ma_vt", "ten_vt"]}
          placeholder="Chọn một Sản phẩm"
          getOptionLabel={(option) => option.ten_vt}
          checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
          value={filter.vat_tu}
          onChange={(val) => setFilter({ ...filter, vat_tu: val })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDMQDDVT;
