import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import Detail from "./detail";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { usePosContext } from "../../../PosPage";

function Details() {
  const { currentHd } = useSelector((state: any) => state.pos);
  const { handleUpdateCurrentHd } = usePosContext();

  const handleClearPromotion = () => {
    handleUpdateCurrentHd({ promotion: [] });
  };

  return (
    <Stack gap={1}>
      {currentHd?.details &&
        currentHd?.details?.length > 0 &&
        currentHd?.details.map((detail: any, index: number) => {
          return <Detail key={detail._id} stt={index + 1} data={detail} />;
        })}
      {currentHd?.promotion && currentHd?.promotion?.length > 0 && (
        <Stack
          gap={1}
          sx={{ pt: 1, borderTop: "1px dashed", borderColor: "divider" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ fontWeight: 600 }}>Hàng tặng</Typography>
            <Button
              onClick={handleClearPromotion}
              size="small"
              variant="contained"
              color="error"
              sx={{
                boxShadow: "none",
                textTransform: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Xóa
            </Button>
          </Stack>
          <Stack gap={1}>
            {currentHd?.promotion.map((detail: any, index: number) => {
              return (
                <Detail key={v4()} readOnly stt={index + 1} data={detail} />
              );
            })}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default Details;
