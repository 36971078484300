import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { asyncGetReport } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { groupBy } from "../../../utils/helpers";
import { useBackdropContext } from "../../../contexts/backdrop/BackdropProvider";

function HangBanChay() {
  const theme = useTheme();
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const { setOpenBackdrop } = useBackdropContext();
  const [data, setData] = useState<any[]>([]);

  const getReport = async () => {
    try {
      setOpenBackdrop?.(true);
      const today = moment().month(7);
      const resp = await asyncGetReport({
        apiCode: "ctbanle",
        token,
        query: {
          ma_ct: "PBL",
          tu_ngay: today
            .clone()
            .startOf("months")
            .hours(0)
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
          den_ngay: today
            .clone()
            .endOf("months")
            .hours(23)
            .minutes(59)
            .seconds(59)
            .milliseconds(999)
            .toISOString(),
        },
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.message || resp?.data?.error || "Lỗi khi tải báo cáo",
        });
        return;
      }
      const data = resp?.data || [];
      const products = groupBy({
        data: data,
        callbackMatch: (item) => item.ma_vt,
      }) as any[];
      const result = [];
      for (let i = 0; i < products.length; i++) {
        const currentData = products[i] || [];
        if (currentData.length > 0) {
          const tongSoLuong = currentData.reduce((acc: number, item: any) => {
            return acc + item.sl_xuat;
          }, 0);
          result.push({
            ten_vt: currentData[i]?.ten_vt,
            sl_xuat: tongSoLuong,
          });
        }
      }
      result.sort((a, b) => a.sl_xuat - b.sl_xuat);
      if (result.length < 20) {
        const missingNumber = 20 - result.length + 1;
        for (let i = 0; i < missingNumber; i++) {
          result.push({ ten_vt: "SP" + (i + 1), sl_xuat: 0 });
        }
      }
      setData(result);
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{ p: 2, borderRadius: "10px", backgroundColor: "background.paper" }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: 550, mb: "10px" }}>
        TOP 20 Sản phẩm BÁN CHẠY TRONG THÁNG THEO SỐ LƯỢNG
      </Typography>
      <Box>
        {data?.length > 0 ? (
          <Typography sx={{ py: 2, textAlign: "center" }}>
            Chưa có dữ liệu
          </Typography>
        ) : (
          <BarChart
            dataset={data}
            yAxis={[
              { scaleType: "band", dataKey: "ten_vt", tickPlacement: "middle" },
            ]}
            series={[
              {
                dataKey: "sl_xuat",
                label: "Số lượng bán",
                color: theme.palette.primary.main,
              },
            ]}
            margin={{ top: 50, bottom: 30, right: 0 }}
            borderRadius={4}
            height={600}
            layout="horizontal"
          />
        )}
      </Box>
    </Box>
  );
}

export default HangBanChay;
