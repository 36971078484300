import axios from "axios";
import { Buffer } from "buffer";
import { API_URL, ID_APP, PUBLIC_URL } from "../../utils/constants";
import { Dispatch } from "redux";
import { logoutUser } from "../reducers/auth.reducer";
import { persistor } from "../reducers/root";

// login user
type loginUserType = {
  username: string;
  password: string;
};
const loginUser = async ({ username, password }: loginUserType) => {
  try {
    const credentials = Buffer.from(username + ":" + password).toString(
      "base64"
    );
    const basicAuth = "Basic " + credentials;
    const resp = await axios.get(`${PUBLIC_URL}/auth/local?id_app=${ID_APP}`, {
      headers: {
        Authorization: basicAuth,
      },
    });
    if (resp && resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

type logoutType = {
  dispatch: Dispatch;
};
const logout = ({ dispatch }: logoutType) => {
  dispatch(logoutUser());
  localStorage.clear();
  persistor.pause();
  persistor.flush().then(() => {
    window.location.reload();
    return persistor.purge();
  });
};

// get profile
type getProfileType = {
  token: string;
};
const getProfile = async ({ token }: getProfileType) => {
  try {
    const resp = await axios.get(`${API_URL}/profile?access_token=${token}`);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

export { loginUser, logout, getProfile };
