import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import TextInput from "./components/TextInput";
import PasswordInput from "./components/PasswordInput";
import { LoadingButton } from "@mui/lab";
import TypeIt from "typeit-react";
import { FieldValues, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginUser } from "../../redux/actions/auth.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import withUnAuth from "../../hocs/withUnAuth";
import { updateToken } from "../../redux/reducers/auth.reducer";
import { useAlertContext } from "../../contexts/alert/AlertProvider";

const schema: yup.ObjectSchema<FieldValues> = yup.object({
  email: yup.string().required("Vui lòng nhập tài khoản đăng nhập"),
  password: yup.string().required("Vui lòng nhập mật khẩu"),
});

function LoginPage() {
  const { showAlert } = useAlertContext();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = async (values: FieldValues) => {
    const resp = await loginUser({
      username: values.email,
      password: values.password,
    });
    if (resp?.status === 200) {
      dispatch(updateToken(resp?.data?.token));
      navigate("/");
    } else {
      showAlert({
        type: "error",
        title: "Thông báo",
        message:
          resp.data?.message ||
          resp?.data?.error ||
          "Đăng nhập không thành công",
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "background.default",
        overflow: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#00000033",
        }}
      ></Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          borderRadius: "10px",
          backgroundColor: "background.paper",
          width: "475px",
          maxWidth: "90vw",
          position: "relative",
          zIndex: 3,
        }}
      >
        <Stack gap={4}>
          <Stack
            alignItems="center"
          >
            <Avatar src="/logo.svg" alt="logo" sx={{width: '80px', height: '80px', borderRadius: '0px'}} />
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              GOODCHARME
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 600,
                color: "primary.main",
              }}
            >
              <TypeIt options={{ loop: true }}>CHÀO MỪNG TRỞ LẠI</TypeIt>
            </Typography>
            <Typography sx={{ textAlign: "center", opacity: 0.6 }}>
              Nhập thông tin đăng nhập để tiếp tục
            </Typography>
          </Stack>
          <Stack component="form" gap={2} onSubmit={handleSubmit(submit)}>
            <TextInput
              label="Tài khoản đăng nhập"
              name="email"
              register={register}
              errorMessage={errors?.email?.message as string}
            />
            <PasswordInput
              label="Mật khẩu"
              name="password"
              register={register}
              errorMessage={errors?.password?.message as string}
            />
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              variant="contained"
              sx={{
                height: "45px",
                boxShadow: "none",
                color: "common.white",
                "&:hover": {
                  backgroundColor: "primary.main",
                  boxShadow: "none",
                },
              }}
            >
              Đăng nhập
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export default withUnAuth(LoginPage);
