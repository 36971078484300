import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import FilterRadios from "../FilterRadios";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterPTTHANHTOAN({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = { pttt: "", trang_thai: "true" };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.pttt) {
      condition.ten = { $regex: filter.pttt, $options: "i" };
    }
    switch (filter.trang_thai) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Tên phương thức"
          placeholder="Tìm theo tên"
          value={filter.pttt}
          onSearch={(value) => setFilter({ ...filter, pttt: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: "Tất cả", value: "all" },
            { label: "Còn sử dụng", value: "true" },
            { label: "Không sử dụng", value: "false" },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPTTHANHTOAN;
