import React from "react";
import WrapperInput, { IWrapperInput } from "./WrapperInput";
import { TextField, TextFieldProps } from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface INumberInput extends Omit<IWrapperInput, "children"> {
  placeholder?: string;
  errorMessage?: string | undefined;
  name?: TextFieldProps["name"];
  value?: TextFieldProps["value"];
  min?: number;
  max?: number;
  onChange?: TextFieldProps["onChange"];
  register?: UseFormRegister<FieldValues> | undefined;
}

const heights = {
  input: "45px",
};

function NumberInput({
  label,
  labelWidth = "20%",
  id,
  required,
  min,
  max,
  name,
  value,
  placeholder,
  errorMessage,
  onChange,
  register,
}: INumberInput) {
  return (
    <WrapperInput
      id={id}
      label={label}
      required={required}
      labelWidth={labelWidth}
    >
      <TextField
        id={id}
        name={name}
        type="number"
        fullWidth
        placeholder={placeholder}
        autoComplete="off"
        spellCheck={false}
        helperText={errorMessage}
        value={value}
        onChange={onChange}
        inputMode="numeric"
        slotProps={{ htmlInput: { min, max } }}
        {...(name ? register?.(name) : {})}
        sx={{
          "& .MuiFormHelperText-root": {
            color: "error.main",
            fontStyle: "italic",
          },
          "& .MuiInputBase-root": {
            height: heights.input,
            px: 0,
            py: 0,
            border: "2px solid",
            borderColor: !!errorMessage ? "error.main" : "divider",
            "&:focus-within": {
              borderColor: !!errorMessage ? "error.main" : "primary.main",
            },
            "& .MuiInputBase-input": {
              height: heights.input,
              px: 2,
              py: 1,
            },
            "& fieldset": { border: "none" },
          },
        }}
      />
    </WrapperInput>
  );
}

export default NumberInput;
