import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LuInfo, LuX } from "react-icons/lu";
import DrawerBase from "../../../../../../components/common/drawer/DrawerBase";
import {
  asyncGetList,
  asyncGetReport,
} from "../../../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import {
  generateLinkImage,
  numeralCustom,
} from "../../../../../../utils/helpers";
import Number from "./Number";
import TableApp from "../../../../../../components/common/tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import { isArray } from "lodash";

interface IOptions {
  data: any;
  handleNumberChange: (val: number) => void;
}

const heights = {
  top: "45px",
};

const tonKhoColumns: TableColumn<any>[] = [
  {
    name: "Chi nhánh",
    wrap: true,
    selector: (row) => row.ten_kho,
  },
  { name: "Tồn kho", selector: (row) => row.ton_cuoi },
  { name: "Đơn vị tính", selector: (row) => row.ma_dvt },
];

function Options({ data, handleNumberChange }: IOptions) {
  const token = useSelector((state: any) => state.auth.token);
  const [open, setOpen] = useState<boolean>(false);
  const [originProduct, setOriginProduct] = useState<any>();
  const [tonkhos, setTonKhos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOriginProduct = async () => {
    const resp = await asyncGetList({
      apiCode: "dmvt",
      token,
      condition: { page: 1, limit: 1, q: { ma_vt: data?.ma_vt || "" } },
    });
    if (resp && resp?.status === 200 && resp?.data?.length > 0) {
      setOriginProduct(resp?.data[0]);
    }
  };

  const getThnxt = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await asyncGetReport({
      apiCode: "thnxt",
      query: {
        groupBy: "ma_vt,ma_kho",
        ma_vt: data.ma_vt,
        tu_ngay: moment().subtract(7, "days"),
        den_ngay: moment().hours(23).minutes(59).seconds(59),
      },
    });
    if (resp && isArray(resp?.data) && resp?.data?.length > 1) {
      const result = resp.data || [];
      result.pop();
      setTonKhos(result || []);
    }
    setLoading(false);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (data?.ma_vt && open) {
      getOriginProduct();
      getThnxt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.ma_vt, open]);

  return (
    <>
      <DrawerBase anchor="left" open={open} onClose={handleCloseDrawer}>
        <Box sx={{ width: "500px" }}>
          {/* Top */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              height: heights.top,
              px: 2,
              borderBottom: "1px dashed",
              borderColor: "divider",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              {data?.ten_vt}
            </Typography>
            <IconButton onClick={handleCloseDrawer}>
              <LuX size={16} />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              width: "100%",
              height: `calc(100vh - ${heights.top})`,
              overflow: "auto",
            }}
          >
            <Stack gap={2} sx={{ p: 2 }}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 4 }}>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      margin: "0 auto",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                      src={
                        originProduct?.picture_thumb
                          ? generateLinkImage(originProduct.picture_thumb)
                          : ""
                      }
                    />
                  </Box>
                </Grid2>
                <Grid2 size={{ xs: 8 }}>
                  <Stack sx={{ width: "100%" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing="20px"
                      sx={{
                        padding: "10px 0",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        Giá gốc :
                      </Typography>
                      <Typography>
                        {numeralCustom(originProduct?.gia_ban_le).format()}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={2}
                      sx={{
                        padding: "10px 0",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, flexShrink: 0 }}>
                        Số lượng :
                      </Typography>
                      <Number
                        defaultValue={data.sl_order || 1}
                        onNumberChange={handleNumberChange}
                      />
                      <Typography sx={{ color: "primary.main", flexShrink: 0 }}>
                        Tồn: {originProduct?.ton00} {originProduct?.ten_dvt}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid2>
              </Grid2>
              <Stack gap={1}>
                <Typography sx={{ fontWeight: 500 }}>
                  Tồn kho chi tiết:
                </Typography>
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: "1px dashed",
                    borderColor: "divider",
                    p: 1,
                  }}
                >
                  <TableApp
                    progressPending={loading}
                    columns={tonKhoColumns}
                    data={tonkhos}
                  />
                </Box>
              </Stack>
              <Stack gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Mô tả :
                </Typography>
                <Box
                  component="div"
                  sx={{
                    "& img": {
                      display: "block",
                      width: "100%",
                      height: "auto",
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: originProduct?.mieu_ta }}
                ></Box>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </DrawerBase>
      <IconButton size="small" color="info" onClick={() => setOpen(true)}>
        <LuInfo size={16} />
      </IconButton>
    </>
  );
}

export default Options;
