import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

interface ITablepagination {
  rowCount: number;
  rowsPerPage: number;
  currentPage: number;
  paginationRowsPerPageOptions: number[];
  onChangePage: (val: number) => void;
  onChangeRowsPerPage: (val: number) => void;
}

function Tablepagination({
  rowCount,
  rowsPerPage,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
  ...props
}: ITablepagination) {
  const [pageValue, setPageValue] = useState<number>(currentPage || 1);
  const handleRowsPerPageChange = (e: SelectChangeEvent<number>) => {
    onChangeRowsPerPage?.(e.target.value as number);
  };

  const handlePageChange = () => {
    if (pageValue === currentPage) return;
    let valueToSet = pageValue;
    if (valueToSet < 1) valueToSet = 1;
    if (valueToSet > maxPage) valueToSet = maxPage;
    setPageValue(valueToSet);
    onChangePage?.(valueToSet);
  };

  const maxPage = useMemo(() => {
    let result = Math.floor((rowCount || 0) / (rowsPerPage || 1));
    if ((rowCount || 0) % (rowsPerPage || 1) !== 0) {
      ++result;
    }
    return result;
  }, [rowCount, rowsPerPage]);

  useEffect(() => {
    setPageValue(currentPage || 1);
  }, [currentPage]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing="10px"
      sx={{
        width: "100%",
        height: "42px",
        backgroundColor: "whitish.graySoft",
        padding: "0 10px",
      }}
    >
      <Stack direction="row" alignItems="center" spacing="5px">
        <Typography sx={{ fontSize: "14px" }}>Số dòng:</Typography>
        <FormControl
          sx={{
            width: "65px",
            "& .MuiSelect-select": { padding: "4px 8px" },
            "& fieldset": {
              border: "1px solid",
              borderColor: "divider",
              outline: "none",
            },
          }}
        >
          <Select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            sx={{ fontSize: "14px" }}
          >
            {!!paginationRowsPerPageOptions &&
              paginationRowsPerPageOptions.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{ fontSize: "14px", padding: "2px 4px" }}
                >
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Pagination
          count={maxPage}
          page={currentPage}
          onChange={(_, newPage) => {
            onChangePage?.(newPage);
            setPageValue(newPage);
          }}
          shape="rounded"
          variant="text"
          size="small"
          showFirstButton
          showLastButton
          siblingCount={1}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "14px",
              "&.Mui-selected": {
                color: "common.white",
              },
            },
          }}
        />
        <TextField
          type="number"
          value={pageValue}
          inputProps={{ min: 1, max: maxPage }}
          onChange={(e) => {
            setPageValue(Number(e.target.value));
          }}
          onBlur={handlePageChange}
          onKeyUp={(e) => {
            if (e.which === 13) {
              handlePageChange();
            }
          }}
          sx={{
            "& .MuiInputBase-root": {
              paddingLeft: 0,
              width: "50px",
            },
            "& .MuiFormLabel-root": {
              fontSize: "14px",
              fontWeight: 600,
              color: "primary.main",
              paddingRight: "5px",
              transform: "translate(14px, -12px)",
              backgroundColor: "whitish.pureWhite",
            },
            "& .MuiInputBase-input": {
              fontSize: "14px",
              padding: "2px 4px",
              textAlign: "center",
            },
          }}
        />
      </Stack>
      <Typography sx={{ fontSize: "14px" }}>
        Hiển thị {1 + ((currentPage || 0) - 1) * (rowsPerPage || 0)} -{" "}
        {Math.min(rowCount || 0, (currentPage || 0) * (rowsPerPage || 0))} /
        Tổng số {rowCount}
      </Typography>
    </Stack>
  );
}

export default Tablepagination;
