import React from "react";
import { Box } from "@mui/material";

function DescriptionSection({ data }: { data: any }) {
  return (
    <Box
      sx={{ maxHeight: "500px", overflow: "auto" }}
      dangerouslySetInnerHTML={{ __html: data?.mieu_ta }}
    ></Box>
  );
}

export default DescriptionSection;
