import React from "react";
import { Avatar, Box, Button, Container, Stack } from "@mui/material";
import { HEADER_HEIGHT } from "../../../../utils/ui.constants";
import NavItem from "../../nav-item";
import { Link } from "react-router-dom";
import { INavItem } from "../../../../types/nav.type";
import { LuSettings } from "react-icons/lu";
import Notification from "./Notification";
import UserSetting from "../../layout/header/UserSetting";

const system: INavItem = {
  text: "Hệ thống",
  endIcon: <LuSettings size={16} />,
  subs: [
    // {
    //   text: "Nhóm người dùng và phân quyền",
    //   link: "/list/usergroup",
    // },
    // {
    //   text: "Danh sách người dùng",
    //   link: "/list/participant",
    // },
    {
      text: "Tất cả chi nhánh",
      link: "/list/dmkho",
    },
    {
      text: "Phương thức thanh toán",
      link: "/list/ptthanhtoan",
    },
    {
      text: "Nhân viên",
      link: "/list/dmnv",
    },
    {
      text: "Giao ca",
      link: "/list/giaoca",
    },
    {
      text: "Kênh bán hàng",
      link: "/list/dmkenhbanhang",
    },
    {
      text: "Đơn vị vận chuyển",
      link: "/list/dvvanchuyen",
    },
  ],
};

function Header() {
  return (
    <Box sx={{ height: HEADER_HEIGHT, backgroundColor: "common.white" }}>
      <Container sx={{ height: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Link to="/">
            <Avatar
              src="/logo.svg"
              sx={{ width: "40px", height: "40px", borderRadius: "0px" }}
            />
          </Link>
          <Stack direction="row" alignItems="center" gap={2}>
            <Link to="/pos">
              <Button
                variant="contained"
                size="small"
                color="primary"
                sx={{
                  boxShadow: "none",
                  color: "common.white",
                  textTransform: "none",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Bán hàng
              </Button>
            </Link>
            <NavItem
              text={system.text}
              endIcon={system.endIcon}
              subs={system.subs}
            />
            <Notification />
            <UserSetting options={[]} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Header;
