import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import moment from "moment";
import { IFilterReport } from "../../../../../types/filter.type";
import { ConcernType } from "../../../../../types/report.type";
import { formatDateDisplay, numeralCustom } from "../../../../../utils/helpers";
import FilterContainer from "../../FilterContainer";
import FilterSelectApi from "../../FilterSelectApi";
import FilterTimeFormTo from "../../FilterTimeFromTo";

function FilterReportSoQuy({ setQueryObject, setConcern }: IFilterReport) {
  const originFilter: { [key: string]: any } = {
    timeFrom: moment().startOf("months").format("YYYY-MM-DD"),
    timeTo: moment().format("YYYY-MM-DD"),
    kho: null,
  };
  const [timeOption, setTimeOption] = useState(null);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const queryObject: { [key: string]: any } = {
      tk: 111,
      groupBy: "so_ct",
    };
    const concern: ConcernType = {
      label: "soquy",
      value: "soquy",
      api: "soquy",
      convertData: (data) => {
        const dauKy = data.shift();
        data.splice(0, 1);
        const cuoiKy = data.pop();
        const tongPhatSinh = data.pop();

        const result: { [key: string]: any }[] = data.map((item) => {
          return {
            so_ct: item.so_ct,
            ngay_ct: item.ngay_ct,
            ma_kho: item.ma_kho,
            ma_ct: item.ma_ct,
            tien_thu: item.ps_no_nt,
            tien_chi: item.ps_co_nt,
          };
        });

        result.unshift({
          ma_kho: "Đầu kỳ",
          tien_thu: dauKy.ps_no_nt,
          tien_chi: dauKy.ps_co_nt,
        });
        result.push({
          ma_kho: "Phát sinh trong kỳ",
          tien_thu: tongPhatSinh.ps_no_nt,
          tien_chi: tongPhatSinh.ps_co_nt,
        });
        result.push({
          ma_kho: "Cuối kỳ",
          tien_thu: cuoiKy.ps_no_nt,
          tien_chi: cuoiKy.ps_co_nt,
        });
        return result;
      },
      columns: [
        {
          name: "Số CT",
          selector: (row) => row.so_ct,
          width: "120px",
          wrap: true,
        },
        {
          name: "Ngày CT",
          selector: (row) => row.ngay_ct,
          format: (row) => formatDateDisplay(row.ngay_ct, "DD/MM/YYYY HH:mm"),
          width: "120px",
          wrap: true,
        },
        {
          name: "Loại CT",
          selector: (row) => row.ma_ct,
          wrap: true,
          width: "130px",
        },
        {
          name: "Chi nhánh",
          selector: (row) => row.ma_kho,
          wrap: true,
        },
        {
          name: "Tiền thu",
          selector: (row) => row.tien_thu,
          format: (row) => numeralCustom(row.tien_thu).format(),
          wrap: true,
        },
        {
          name: "Tiền chi",
          selector: (row) => row.tien_chi,
          format: (row) => numeralCustom(row.tien_chi).format(),
          wrap: true,
        },
        {
          name: "Diễn giải",
          selector: (row) => row.dien_giai,
          wrap: true,
        },
      ],
    };
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }

    setConcern(concern);
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOption, filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack spacing="10px">
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          placeholder="Chọn chi nhánh"
          apiCode="dmkho"
          value={filter.kho}
          searchFields={["ma_kho", "ten_kho"]}
          getOptionLabel={(option) =>
            option.ten_kho ? `${option.ten_kho} (${option.ma_kho})` : ""
          }
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
          onChange={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterTimeFormTo
          title="Thời gian"
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          showOptions
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterReportSoQuy;
