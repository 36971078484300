import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { Box, Stack } from "@mui/material";
import Group from "./components/Group";
import Products from "./components/Products";
import { asyncSearchList } from "../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import useInfiniteScroll from "react-infinite-scroll-hook";

const heights = {
  header: "50px",
  group: "50px",
};

function Left() {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const app = useSelector((state: any) => state.app.data);
  const [condition, setCondition] = useState<{ [key: string]: any }>({
    search: "",
    group_id: "",
    page: 1,
  });
  const [products, setProducts] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<number>(0);

  const getProducts = async () => {
    if (loading) return;
    setLoading(true);
    const filter: { [key: string]: any } = {
      page: condition.page,
      limit: 24,
      q: {
        // sold_on_pos: true,
      },
    };
    if (condition.search) {
      filter.q.$or = [
        {
          ma_vt: {
            $regex: condition.search.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_vt: {
            $regex: condition.search.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    if (condition.group_id) {
      filter.q.ma_nvt = condition.group_id;
    }
    const req = asyncSearchList({
      apiCode: "dmvt",
      token,
      idApp: app?._id,
      condition: filter,
    });
    const reqCount = asyncSearchList({
      apiCode: "dmvt",
      token,
      idApp: app?._id,
      condition: { ...filter, count: 1 },
    });
    Promise.all([req, reqCount])
      .then(([resp, respCount]) => {
        setProducts([...products, ...resp.data]);
        setCount(respCount.data?.rows_number);
        setCondition({ ...condition, page: condition.page + 1 });
      })
      .catch((error: any) => {
        showAlert({
          type: "error",
          message:
            error?.data?.error ||
            error?.data?.message ||
            "Lỗi khi tải Sản phẩm",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hasNextPage = (products?.length || 0) < (count || 0);
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => getProducts(),
  });

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  useEffect(() => {
    setProducts([]);
    setCount(0);
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.search, condition.group_id]);

  return (
    <Stack
      sx={{ height: "100vh", backgroundColor: "background.default" }}
      gap={1}
    >
      <Header
        height={heights.header}
        condition={condition}
        setCondition={setCondition}
      />
      <Stack direction="row">
        <Box sx={{ width: "150px" }}>
          <Group
            headerHeight={heights.header}
            condition={condition}
            setCondition={setCondition}
          />
        </Box>
        <Products
          hasNextPage={hasNextPage}
          loadingRef={sentryRef}
          loading={loading}
          headerHeight={heights.header}
          data={products}
        />
      </Stack>
    </Stack>
  );
}

export default Left;
