import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid2,
  IconButton,
  Menu,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { numeralCustom } from "../../../../../../utils/helpers";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../../PosPage";
import { LuCircleDollarSign } from "react-icons/lu";

const currencies: number[] = [
  200000, 100000, 50000, 20000, 10000, 5000, 2000, 1000,
];

interface ITienThu {
  tongTien: number;
}

function TienThu({ tongTien }: ITienThu) {
  const { currentHd } = useSelector((state: any) => state.pos);
  const { handleUpdateCurrentHd } = usePosContext();
  const [tienThu, setTienThu] = useState<number>(0);
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const handleSaveTienThu = () => {
    if (tienThu === currentHd?.tien_thu) {
      return;
    }
    handleUpdateCurrentHd({ tien_thu: tienThu });
  };

  const handleSelectCurrentcy = (val: number, enough?: boolean) => {
    if (enough) {
      handleUpdateCurrentHd({ tien_thu: val });
    } else {
      const newTienThu = val + (currentHd?.tien_thu || 0);
      handleUpdateCurrentHd({ tien_thu: newTienThu });
    }
  };

  useEffect(() => {
    setTienThu(currentHd?.tien_thu || 0);
  }, [currentHd?.tien_thu]);

  return (
    <>
      <Menu
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={handleCloseMenu}
        slotProps={{ paper: { elevation: 1, sx: { width: "300px" } } }}
      >
        <Box sx={{ width: "100%", px: 2 }}>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 8 }}>
              <Button
                onClick={() => handleSelectCurrentcy(tongTien, true)}
                fullWidth
                variant="contained"
                size="small"
                sx={{
                  boxShadow: "none",
                  color: "common.white",
                  textTransform: "none",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Thu đủ
              </Button>
            </Grid2>
            <Grid2 size={{ xs: 4 }}>
              <Button
                onClick={() => handleSelectCurrentcy(500000)}
                fullWidth
                variant="outlined"
                size="small"
              >
                {numeralCustom(500000).format()}
              </Button>
            </Grid2>
            {currencies.map((item) => {
              return (
                <Grid2 key={item} size={{ xs: 3 }}>
                  <Button
                    onClick={() => handleSelectCurrentcy(item)}
                    variant="outlined"
                    fullWidth
                  >
                    {numeralCustom(item).format()}
                  </Button>
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
      </Menu>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography sx={{ fontWeight: 600 }}>Tiền thu</Typography>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <IconButton
            onClick={(e) => setAnchorMenu(e.currentTarget)}
            sx={{
              borderRadius: "10px",
              backgroundColor: "primary.main",
              color: "common.white",
              "&:hover": {
                backgroundColor: "primary.main",
              },
            }}
          >
            <LuCircleDollarSign size={16} />
          </IconButton>
          <TextField
            variant="standard"
            autoComplete="off"
            value={numeralCustom(tienThu).format()}
            onChange={(e) =>
              setTienThu(numeralCustom(e.target.value).value() as number)
            }
            onBlur={handleSaveTienThu}
            onKeyUp={(e) => {
              if (e.which === 13) {
                handleSaveTienThu();
              }
            }}
            sx={{
              "& .MuiInputBase-input": {
                textAlign: "right",
                width: "80px",
              },
            }}
          />

          <Typography>đ</Typography>
        </Stack>
      </Stack>
    </>
  );
}

export default TienThu;
