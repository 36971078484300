import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterSelectApi from "../FilterSelectApi";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";

function FilterGiaoCa({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    nhan_vien: null,
    nhan_vien_nhan: null,
    kho: null,
    ca: null,
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.ca) {
      condition.ma_ca = filter.ca.ma_ca;
    }
    if (filter.nhan_vien) {
      condition.nhan_vien = filter.nhan_vien.email;
    }
    if (filter.nhan_vien_nhan) {
      condition.nhan_vien_nhan = filter.nhan_vien_nhan.email;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          apiCode="dmkho"
          placeholder="Chọn chi nhánh"
          value={filter.kho}
          searchFields={["ma_kho", "ten_kho"]}
          getOptionLabel={(option) => option.ten_kho}
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
          onChange={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Ca"
          apiCode="dmca"
          placeholder="Chọn ca"
          value={filter.ca}
          searchFields={["ma_ca", "ten_ca"]}
          getOptionLabel={(option) => option.ten_ca}
          checkEqual={(value, option) => value?.ma_ca === option?.ma_ca}
          onChange={(value) => setFilter({ ...filter, ca: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Nhân viên"
          apiCode="participant"
          placeholder="Chọn nhân viên"
          value={filter.nhan_vien}
          searchFields={["email", "name"]}
          getOptionLabel={(option) => option.email}
          checkEqual={(value, option) => value?.email === option?.email}
          onChange={(value) => setFilter({ ...filter, nhan_vien: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Nhân viên nhận"
          apiCode="participant"
          placeholder="Chọn nhân viên"
          value={filter.nhan_vien_nhan}
          searchFields={["email", "name"]}
          getOptionLabel={(option) => option.email}
          checkEqual={(value, option) => value?.email === option?.email}
          onChange={(value) => setFilter({ ...filter, nhan_vien_nhan: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterGiaoCa;
