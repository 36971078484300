import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Skeleton, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { asyncSearchList } from "../../../../../utils/httpRequests";
import { isArray } from "lodash";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";

interface IGroup {
  headerHeight: string;
  condition: { [key: string]: any };
  setCondition: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

function Group({ headerHeight, condition, setCondition }: IGroup) {
  const { showAlert } = useAlertContext();
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoaing] = useState<boolean>(false);

  const getData = async () => {
    if (loading) return;
    setLoaing(true);
    const resp = await asyncSearchList({
      apiCode: "dmnvt",
      token,
      idApp: app?._id,
      condition: { page: 1, limit: 99999 },
    });
    if (resp.status === 200) {
      const groupData = resp?.data || [];
      groupData.sort((a: any, b: any) => a.stt - b.stt);
      setData([{ _id: "", ten_nvt: "Tất cả" }, ...groupData]);
    } else {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải nhóm hàng",
      });
    }
    setLoaing(false);
  };

  const renderLoading = () => {
    return (
      <>
        {new Array(5).fill(0).map((_, index) => {
          return (
            <Skeleton
              key={index}
              variant="rounded"
              animation="wave"
              sx={{ width: `${Math.random() * 80 + 20}px`, height: "34px" }}
            />
          );
        })}
      </>
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      gap={1}
      sx={{
        px: 1,
        py: 1,
        height: `calc(100vh - ${headerHeight} - 16px)`,
        overflow: "auto",
        backgroundColor: "background.paper",
      }}
    >
      <>
        {loading ? (
          renderLoading()
        ) : (
          <>
            {isArray(data) &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <Button
                    key={item._id}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setCondition({
                        ...condition,
                        group_id: item._id,
                        page: 1,
                      });
                    }}
                    sx={
                      condition.group_id === item._id
                        ? {
                            flexShrink: 0,
                            boxShadow: "none",
                            color: "common.white",
                            textTransform: "none",
                            "&:hover": { boxShadow: "none" },
                          }
                        : {
                            flexShrink: 0,
                            backgroundColor: "background.default",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": { boxShadow: "none" },
                          }
                    }
                  >
                    {item.ten_nvt}
                  </Button>
                );
              })}
          </>
        )}
      </>
    </Stack>
  );
}

export default Group;
