import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Avatar, IconButton, Stack } from "@mui/material";
import { LuScanLine } from "react-icons/lu";
import Search from "./Search";
import Logo from "../../../../../components/common/layout/header/Logo";
import { useBackdropContext } from "../../../../../contexts/backdrop/BackdropProvider";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import { asyncSearchList } from "../../../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../PosPage";
import { Link } from "react-router-dom";

interface IHeader {
  height: string;
  condition: { [key: string]: any };
  setCondition: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

function Header({ height, condition, setCondition }: IHeader) {
  const token = useSelector((state: any) => state.auth.token);
  const app = useSelector((state: any) => state.app.data);
  const { currentHd } = useSelector((state: any) => state.pos);
  const { setOpenBackdrop } = useBackdropContext();
  const { handleAddProductToDetails } = usePosContext();
  const { showAlert } = useAlertContext();
  const [isScanner, setIsScanner] = useState<boolean>(true);
  const timerRef = useRef<any>();

  // get product by barcode
  const handleGetProductByBarcode = async (barcode: string) => {
    try {
      setOpenBackdrop?.(true);
      const resp = await asyncSearchList({
        apiCode: "dmvt",
        token,
        idApp: app?._id,
        condition: { page: 1, limit: 1, q: { ma_vt2: barcode } },
      });
      if (resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.error ||
            resp?.data?.message ||
            "Lỗi khi tìm mã vạch sản phẩm",
        });
        setOpenBackdrop?.(false);
        return;
      }
      const product = resp?.data?.[0];
      if (!product) {
        showAlert({
          type: "error",
          message: `Mã vạch '${barcode}' không tồn tại`,
        });
        return;
      }
      // thêm product vào details
      handleAddProductToDetails({
        product,
        gia_ban_le: product.gia_ban_le,
        ma_dvt: product.ma_dvt,
        ten_dvt: product.ten_dvt,
        overwrite: true,
      });
    } finally {
      setOpenBackdrop?.(false);
    }
  };

  // lắng nghe scan mã vạch
  // useEffect(() => {
  //   let barcode = "";
  //   const handleScanner = async (e: KeyboardEvent) => {
  //     if (!isScanner) return;
  //     clearTimeout(timerRef.current);
  //     const textInput =
  //       e.which !== 13 ? e.key || String.fromCharCode(e.keyCode) : "";
  //     barcode += textInput;

  //     timerRef.current = setTimeout(() => {
  //       if (e.which === 13) {
  //         handleGetProductByBarcode(barcode);
  //       }
  //       barcode = "";
  //     }, 300);
  //   };
  //   document.addEventListener("keydown", handleScanner);
  //   return () => {
  //     document.removeEventListener("keydown", handleScanner);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isScanner, currentHd]);

  // nếu có input focus thì không scan mã vạch
  // useEffect(() => {
  //   const checkFocusInput = () => {
  //     const inputs = document.querySelectorAll("input") || [];
  //     const areas = document.querySelectorAll("textarea") || [];

  //     inputs.forEach((input) => {
  //       input.onfocus = () => {
  //         setIsScanner(false);
  //       };
  //       input.onblur = () => {
  //         setIsScanner(true);
  //       };
  //     });
  //     areas.forEach((area) => {
  //       area.onfocus = () => {
  //         setIsScanner(false);
  //       };
  //       area.onblur = () => {
  //         setIsScanner(true);
  //       };
  //     });
  //   };
  //   checkFocusInput();
  // }, [currentHd?.details?.length]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
      sx={{
        height: height,
        backgroundColor: "background.paper",
        pl: 2,
      }}
    >
      <Link to="/">
        <Avatar
          src="/logo.svg"
          sx={{ width: "40px", height: "40px", borderRadius: "0px" }}
        />
      </Link>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ flex: 1 }}
      >
        <Search condition={condition} setCondition={setCondition} />
        {/* <IconButton
          sx={{
            borderRadius: "10px",
            width: "35px",
            height: "35px",
            border: "2px solid",
            borderColor: isScanner ? "primary.main" : "divider",
            color: isScanner ? "primary.main" : "text.primary",
          }}
        >
          <LuScanLine size={20} />
        </IconButton> */}
      </Stack>
      <div></div>
    </Stack>
  );
}

export default Header;
