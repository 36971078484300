import React from "react";
import { IFilterReport } from "../types/filter.type";
import FilterReportHangHoa from "../components/common/filters/report/hanghoa/FilterReportHangHoa";
import FilterReportBanhang from "../components/common/filters/report/banhang/FilterReportBanHang";
import FilterReportKQHDKD from "../components/common/filters/report/kqhdkd/FilterReportKQHDKD";
import FilterReportSoQuy from "../components/common/filters/report/soquy/FilterReportSoQuy";

type ReportType = {
  title: string;
  Filter: React.ElementType<IFilterReport>;
};
const reports: { [key: string]: ReportType } = {
  hanghoa: {
    title: "Báo cáo Sản phẩm",
    Filter: FilterReportHangHoa,
  },
  banhang: {
    title: "Báo cáo bán hàng",
    Filter: FilterReportBanhang,
  },
  soquy: {
    title: "Báo cáo sổ quỹ",
    Filter: FilterReportSoQuy,
  },
  kqhdkd: {
    title: "Báo cáo kết quả kinh doanh",
    Filter: FilterReportKQHDKD,
  },
};
export default reports;
