import React, { useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import TextInput from "../../../input/TextInput";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface IDescriptionSection {
  register: UseFormRegister<FieldValues>;
}

function DescriptionSection({ register }: IDescriptionSection) {
  const [show, setShow] = useState<boolean>(true);

  return (
    <CollapseSection
      title="Ghi chú"
      show={show}
      onCollapse={() => setShow(!show)}
    >
      <TextInput
        direction="column"
        name="dien_giai"
        register={register}
        placeholder="Nhập ghi chú..."
      />
    </CollapseSection>
  );
}

export default DescriptionSection;
