import { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import FilterSelectApi from "../FilterSelectApi";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterCDVT({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    nam: "",
    vat_tu: null,
    lo: null,
    kho: null,
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.nam) {
      condition.nam = filter.nam;
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.lo) {
      condition.ma_lo = filter.lo.ma_lo;
    }
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Năm đầu kỳ"
          placeholder="Tìm theo năm đầu kỳ"
          value={filter.nam}
          onSearch={(value) => setFilter({ ...filter, nam: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          placeholder="Chọn chi nhánh"
          apiCode="dmkho"
          value={filter.kho}
          onChange={(val) => setFilter({ ...filter, kho: val })}
          getOptionLabel={(option) => option.ten_kho}
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
          searchFields={["ma_kho", "ten_kho"]}
        />
        <FilterSelectApi
          direction="column"
          title="Sản phẩm"
          placeholder="Chọn Sản phẩm"
          apiCode="dmvt"
          value={filter.vat_tu}
          onChange={(val) => setFilter({ ...filter, vat_tu: val })}
          getOptionLabel={(option) => option.ten_vt}
          checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
          searchFields={["ma_vt", "ten_vt"]}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterCDVT;
