import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    data: null
  },
  reducers: {
    updateApp(state, action) {
        state.data = action.payload
    }
  },
});

export const { updateApp} = appSlice.actions;
export default appSlice.reducer;
