import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import SelectApiInput from "../../../input/SelectApiInput";
import DatetimeInput from "../../../input/DateTimeInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <TextInput
                label="Số CT"
                labelWidth="25%"
                placeholder="Nhập hoặc tạo tự động"
                name="so_ct"
                register={register}
              />
              <Controller
                name="ngay_ct"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatetimeInput
                      required
                      label="Ngày CT"
                      labelWidth="25%"
                      name="ngay_ct"
                      value={value}
                      onChange={onChange}
                      errorMessage={errors?.ngay_ct?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="kho"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Chi nhánh"
                      placeholder="Chọn chi nhánh"
                      value={value}
                      onChange={onChange}
                      apiCode="dmkho"
                      searchFields={["ma_kho", "ten_kho"]}
                      getOptionLabel={(option) => option.ten_kho}
                      checkEqual={(value, option) =>
                        value.ma_kho === option.ma_kho
                      }
                      errorMessage={errors?.kho?.message as string}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Stack gap={2}>
              <Controller
                control={control}
                name="nha_cung_cap"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Nhà cung cấp"
                      placeholder="Chọn nhà cung cấp"
                      value={value}
                      onChange={onChange}
                      apiCode="customer"
                      defaultCondition={{ ncc_yn: true }}
                      searchFields={["ma_kh", "ten_kh"]}
                      getOptionLabel={(option) => option.ten_kh}
                      checkEqual={(value, option) =>
                        value.ma_kh === option.ma_kh
                      }
                      errorMessage={errors?.nha_cung_cap?.message as string}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="trang_thai"
                render={({ field: { value, onChange } }) => {
                  return (
                    <SelectApiInput
                      required
                      labelWidth="25%"
                      label="Trạng thái"
                      placeholder="Chọn trạng thái"
                      value={value}
                      onChange={onChange}
                      apiCode="trangthai"
                      withIdApp={false}
                      defaultCondition={{ ma_ct: "PN1" }}
                      searchFields={["ma_trang_thai", "ten_trang_thai"]}
                      getOptionLabel={(option) => option.ten_trang_thai}
                      checkEqual={(value, option) =>
                        value.ma_trang_thai === option.ma_trang_thai
                      }
                      errorMessage={errors?.trang_thai?.message as string}
                    />
                  );
                }}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
