import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import CheckboxInput from "../../../input/CheckboxInput";
import SelectApiInput from "../../../input/SelectApiInput";
import DatetimeInput from "../../../input/DateTimeInput";
import NumberInput from "../../../input/NumberInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              labelWidth="25%"
              label="STT ưu tiên"
              placeholder="Số thứ tự ưu tiên"
              name="stt_uu_tien"
              register={register}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    labelWidth="25%"
                    label="Chi nhánh"
                    placeholder="Chọn chi nhánh"
                    value={value}
                    onChange={onChange}
                    apiCode="dmkho"
                    searchFields={["ma_kho", "ten_kho"]}
                    getOptionLabel={(option) => option.ten_kho}
                    checkEqual={(value, option) =>
                      value.ma_kho === option.ma_kho
                    }
                    errorMessage={errors?.kho?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    labelWidth="25%"
                    label="Sản phẩm"
                    placeholder="Chọn sản phẩm"
                    value={value}
                    onChange={onChange}
                    apiCode="dmvt"
                    searchFields={["ma_vt", "ten_vt"]}
                    getOptionLabel={(option) => option.ten_vt}
                    checkEqual={(value, option) =>
                      value.ma_vt === option.ten_vt
                    }
                    errorMessage={errors?.vat_tu?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm hàng"
                    placeholder="Chọn nhóm hàng"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnvt"
                    searchFields={["ma_nvt", "ten_nvt"]}
                    getOptionLabel={(option) => option.ten_nvt}
                    checkEqual={(value, option) => value._id === option._id}
                    errorMessage={errors?.nhom_vat_tu?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Khách hàng"
                    placeholder="Chọn khách hàng"
                    value={value}
                    onChange={onChange}
                    apiCode="customer"
                    searchFields={["ma_kh", "ten_kh"]}
                    getOptionLabel={(option) => option.ten_kh}
                    checkEqual={(value, option) => value.ma_kh === option.ma_kh}
                    errorMessage={errors?.khach_hang?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="nhom_khach"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm khách"
                    placeholder="Chọn nhóm khách"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnhkh"
                    searchFields={["group_name"]}
                    getOptionLabel={(option) => option.group_name || option._id}
                    checkEqual={(value, option) => value._id === option._id}
                    errorMessage={errors?.nhom_khach?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="hieu_luc_tu"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Từ ngày"
                  labelWidth="25%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_tu?.message as string}
                />
              )}
            />
            <Controller
              control={control}
              name="hieu_luc_den"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Đến ngày"
                  labelWidth="25%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_den?.message as string}
                />
              )}
            />
            <NumberInput
              label="SL từ"
              labelWidth="25%"
              name="sl_tu"
              register={register}
            />
            <NumberInput
              label="SL đến"
              labelWidth="25%"
              name="sl_den"
              register={register}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
