import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LuPlusCircle, LuTrash } from "react-icons/lu";
import { v4 } from "uuid";
import { TableColumn } from "react-data-table-component";
import TableApp from "../../../tableapp/TableApp";
import { cloneDeep, isArray } from "lodash";
import FormAdd from "./FormAdd";
import { numeralCustom } from "../../../../../utils/helpers";

const columns: TableColumn<any>[] = [
  {
    name: "Năm",
    width: "80px",
    selector: (row) => row.nam,
  },
  {
    name: "Chi nhánh",
    wrap: true,
    selector: (row) => row.ten_kho,
  },
  {
    name: "Tồn đầu kỳ",
    wrap: true,
    selector: (row) => row.ton00,
  },
  {
    name: "Dư đầu kỳ",
    wrap: true,
    selector: (row) => row.du00,
    format: (row) => numeralCustom(row.du00).format(),
  },
];

interface ITonDauKySection {
  data: any[];
  setData: Dispatch<SetStateAction<any[]>>;
}

function TonDauKySection({ data, setData }: ITonDauKySection) {
  const [show, setShow] = useState<boolean>(true);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toggleClear, setToggleClear] = useState<boolean>(false);

  const addTonDauKy = (tonDauKy: any, isEdit: boolean) => {
    if (isEdit) {
      const index = data.findIndex((item) => item._id === tonDauKy._id);
      setData((prev) => {
        prev.splice(index, 1, tonDauKy);
        return prev;
      });
    } else {
      setData([...data, { ...tonDauKy, _id: v4() }]);
    }
  };

  const handleCloseForm = () => {
    setDefaultValue({});
    setIsEdit(false);
    setOpenForm(false);
  };

  const handleRowClicked = (data: any) => {
    setDefaultValue(data);
    setIsEdit(true);
    setOpenForm(true);
  };

  const handleSelectRowsChange = (rows: any[]) => {
    setSelectedRows(rows);
  };

  const handleDelete = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item._id);
    let dataCopy = cloneDeep(data);
    dataCopy = dataCopy.filter((item) => !ids.includes(item._id));
    setData(dataCopy);
  };

  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [data]);

  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          onClose={handleCloseForm}
          defaultValue={defaultValue}
          add={addTonDauKy}
          isEdit={isEdit}
        />
      )}
      <CollapseSection
        title="Tồn đâu kỳ"
        show={show}
        onCollapse={() => setShow(!show)}
      >
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <LoadingButton
              onClick={() => setOpenForm(true)}
              variant="contained"
              size="small"
              startIcon={<LuPlusCircle size={16} />}
              sx={{
                boxShadow: "none",
                color: "common.white",
                textTransform: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Thêm
            </LoadingButton>
            {selectedRows?.length > 0 && (
              <Button
                onClick={handleDelete}
                variant="contained"
                size="small"
                color="error"
                startIcon={<LuTrash size={16} />}
                sx={{
                  textTransform: "none",
                  boxShadow: "none",
                  fontWeight: 400,
                  "&:hover": { boxShadow: "none" },
                }}
              >
                Xóa
              </Button>
            )}
          </Stack>
          {isArray(data) && data.length > 0 && (
            <Box
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "10px",
                p: 1,
              }}
            >
              <TableApp
                columns={columns}
                data={data}
                onRowClicked={handleRowClicked}
                selectableRows
                onSelectedRowsChange={handleSelectRowsChange}
                //   onSelectedRowsChange={handleSelectRowsChange}
                clearSelectedRows={toggleClear}
              />
            </Box>
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default TonDauKySection;
