import React from "react";
import withAuth from "../../hocs/withAuth";
import MainLayout from "../../components/common/layouts/main";
import { Box, Container, Grid2, Stack, Typography } from "@mui/material";
import { HEADER_HEIGHT, NAVBAR_HEIGHT } from "../../utils/ui.constants";
import ResultSellToday from "./components/ResultSellToday";
import DoanhThuThang from "./components/DoanhThuThang";
import HangBanChay from "./components/HangBanChay";

function HomePage() {
  return (
    <MainLayout>
      <Box
        sx={{
          width: "100%",
          minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
          height: "auto",
          backgroundColor: "background.default",
          py: 2,
        }}
      >
        <Container>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 9 }}>
              <Stack gap={2} sx={{ pb: 2 }}>
                <ResultSellToday />
                <DoanhThuThang />
                <HangBanChay />
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 0, md: 3 }}>
              <Box sx={{ p: 2, pr: 1 }}>
                <Stack
                  direction="row"
                  sx={{
                    pb: 2,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    Các hoạt động gần đây
                  </Typography>
                </Stack>
                <Stack
                  gap={2}
                  className="custome-scrolly"
                  sx={{
                    maxHeight: "calc(100vh - 50px - 42px - 10px - 20px - 32px)",
                    overflow: "auto",
                    padding: "5px 5px 5px 0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    Chưa có hoạt động
                  </Typography>
                </Stack>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default withAuth(HomePage);
