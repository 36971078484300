import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",
  initialState: {
    currentStore: null,
  },
  reducers: {
    updateCurrentStore(state, action) {
      state.currentStore = action.payload;
    },
    resetConfig(state) {
      state.currentStore = null;
    },
  },
});

export const { updateCurrentStore, resetConfig } = configSlice.actions;
export default configSlice.reducer;
