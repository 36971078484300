import React from "react";
import { Stack, SxProps, Typography } from "@mui/material";
import { numeralCustom } from "../../../utils/helpers";

interface ITotalLine {
  label: string;
  value: number;
  count?: number;
  labelSx?: SxProps;
  valueSx?: SxProps;
}

function TotalLine({ label, value, count, labelSx, valueSx }: ITotalLine) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography sx={{ fontWeight: 600, ...labelSx }}>{label}</Typography>
        {!!count && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "background.default",
              borderRadius: "50%",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            {count}
          </Stack>
        )}
      </Stack>
      <Typography sx={{ ...valueSx }}>
        {numeralCustom(value).format()} đ
      </Typography>
    </Stack>
  );
}

export default TotalLine;
