import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { LuRefreshCcw } from "react-icons/lu";

interface IFilterContainer {
  children: React.ReactNode;
  handleRefreshFilter?: () => void;
}

function FilterContainer({ children, handleRefreshFilter }: IFilterContainer) {
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Bộ lọc</Typography>
        <IconButton onClick={handleRefreshFilter}>
          <LuRefreshCcw size={16} />
        </IconButton>
      </Stack>
      <Box
        className="hidden-scroll"
        sx={{ height: "calc(100vh - 16px - 32px - 8px)", overflow: "auto" }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export default FilterContainer;
