import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { groupBy, numeralCustom } from "../../../../../utils/helpers";
import { IFilterReport } from "../../../../../types/filter.type";
import FilterRadios from "../../FilterRadios";
import FilterSelectApi from "../../FilterSelectApi";
import FilterTimeFormTo from "../../FilterTimeFromTo";
import { ConcernType } from "../../../../../types/report.type";

const concerns: ConcernType[] = [
  // bán hàng
  {
    value: "banhang",
    label: "Bán hàng",
    api: "ctbanle",
    convertData: (data: any[]) => {
      let result: any[] = [];
      const total = data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => `${item.ma_vt}_${item.ma_dvt}`,
      }) as any[];

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc: any, item: any) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              ma_dvt: item.ma_dvt,
              sl_ban: acc.sl_ban + item.sl_xuat,
              doanh_thu: acc.doanh_thu + item.tien_xuat,
            };
          },
          { ma_vt: "", ten_vt: "", ma_dvt: "", sl_ban: 0, doanh_thu: 0 }
        );
        result.push(res);
      });
      result = result.filter((item) => item.sl_ban);
      result.unshift({
        ma_vt: "Tổng cộng",
        ten_vt: "Tất cả Sản phẩm",
        ma_dvt: "--",
        sl_ban: total.sl_xuat,
        doanh_thu: total.tien_xuat,
      });
      return result;
    },
    columns: [
      {
        name: "Mã SP",
        selector: (row) => row.ma_vt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ma_vt}</Typography>
            );
          }
          return row.ma_vt;
        },
        width: "140px",
        wrap: true,
      },
      {
        name: "Tên sản phẩm",
        selector: (row) => row.ten_vt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ten_vt}</Typography>
            );
          }
          return row.ten_vt;
        },
        wrap: true,
      },
      {
        name: "Số lượng bán",
        selector: (row) => row.sl_ban,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.sl_ban).format()}
              </Typography>
            );
          }
          return numeralCustom(row.sl_ban).format();
        },
        wrap: true,
      },
      {
        name: "Đơn vị tính",
        selector: (row) => row.ma_dvt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ma_dvt}</Typography>
            );
          }
          return row.ma_dvt;
        },
        wrap: true,
      },
      {
        name: "Doanh thu",
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.doanh_thu).format()}
              </Typography>
            );
          }
          return numeralCustom(row.doanh_thu).format();
        },
        wrap: true,
      },
    ],
  },
  // nhập hàng
  {
    value: "nhaphang",
    label: "Nhập hàng",
    api: "ctbanle",
    convertData: (data: any[]) => {
      let result: any[] = [];
      const total = data.pop();
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => `${item.ma_vt}_${item.ma_dvt}`,
      }) as any[];

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc: any, item: any) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              ma_dvt: item.ma_dvt,
              sl_nhap: acc.sl_nhap + item.sl_nhap,
              tien_nhap: acc.tien_nhap + item.tien_nhap,
            };
          },
          { ma_vt: "", ten_vt: "", ma_dvt: "", sl_nhap: 0, tien_nhap: 0 }
        );
        result.push(res);
      });
      result = result.filter((item) => item.sl_nhap);
      result.unshift({
        ma_vt: "Tổng cộng",
        ten_vt: "Tất cả Sản phẩm",
        ma_dvt: "--",
        sl_nhap: total.sl_nhap,
        tien_nhap: total.tien_nhap,
      });
      return result;
    },
    columns: [
      {
        name: "Mã SP",
        selector: (row) => row.ma_vt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ma_vt}</Typography>
            );
          }
          return row.ma_vt;
        },
        width: "140px",
        wrap: true,
      },
      {
        name: "Tên sản phẩm",
        selector: (row) => row.ten_vt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ten_vt}</Typography>
            );
          }
          return row.ten_vt;
        },
        wrap: true,
      },
      {
        name: "Số lượng nhập",
        selector: (row) => row.sl_nhap,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.sl_nhap).format()}
              </Typography>
            );
          }
          return numeralCustom(row.sl_nhap).format();
        },
        wrap: true,
      },
      {
        name: "Đơn vị tính",
        selector: (row) => row.ma_dvt,
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>{row.ma_dvt}</Typography>
            );
          }
          return row.ma_dvt;
        },
        wrap: true,
      },
      {
        name: "Tiền nhập",
        selector: (row) => row.tien_nhap,
        format: (row) => numeralCustom(row.tien_nhap).format(),
        cell: (row, index) => {
          if (index === 0) {
            return (
              <Typography sx={{ fontWeight: 600 }}>
                {numeralCustom(row.tien_nhap).format()}
              </Typography>
            );
          }
          return numeralCustom(row.tien_nhap).format();
        },
        wrap: true,
      },
    ],
  },
  // Lợi nhuận
  {
    value: "loinhuan",
    label: "Lợi nhuận",
    api: "ctbanle",
    convertData: (data) => {
      let result = [];
      const total = data.pop();
      let totalBenefit = 0;
      const dataGroup = groupBy({
        data,
        callbackMatch: (item) => item.ma_vt,
      }) as any[];

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc: any, item: any) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              tien_hang: acc.tien_hang + item.tien_hang,
              tien_ck: acc.tien_ck + item.tien_ck * item.sl_xuat,
              doanh_thu: acc.doanh_thu + item.tien_xuat,
              loi_nhuan:
                acc.loi_nhuan +
                (item.tien_xuat -
                  item.gia_von_nt * item.sl_xuat -
                  item.tien_ck -
                  item.tien_phi_nt),
            };
          },
          {
            ma_vt: "",
            ten_vt: "",
            tien_hang: 0,
            tien_ck: 0,
            doanh_thu: 0,
            loi_nhuan: 0,
          }
        );
        totalBenefit += res.loi_nhuan;
        result.push(res);
      });
      result.unshift({
        ma_vt: "",
        ten_vt: "Tất cả Sản phẩm",
        tien_hang: total.tien_hang,
        tien_ck: total.tien_ck,
        doanh_thu: total.tien_xuat,
        loi_nhuan: totalBenefit,
      });
      return result;
    },
    columns: [
      {
        name: "Mã SP",
        selector: (row) => row.ma_vt,
        width: "100px",
        wrap: true,
      },
      {
        name: "Tên sản phẩm",
        selector: (row) => row.ten_vt,
        cell: (row, index) => {
          return (
            <Typography sx={index === 0 ? { fontWeight: 600 } : {}}>
              {row.ten_vt}
            </Typography>
          );
        },
        width: "200px",
        wrap: true,
      },
      {
        name: "Tiền hàng",
        selector: (row) => row.tien_hang,
        format: (row) => numeralCustom(row.tien_hang).format(),
        wrap: true,
      },
      {
        name: "Tiền CK",
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        wrap: true,
      },
      {
        name: "Doanh thu",
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        wrap: true,
      },
      {
        name: "Lợi nhuận",
        selector: (row) => row.loi_nhuan,
        format: (row) => numeralCustom(row.loi_nhuan).format(),
        wrap: true,
      },
    ],
  },
  // nhập xuất tồn
  {
    value: "thnxt",
    label: "Nhập xuất tồn",
    labelKey: "ten_kho",
    api: "thnxt",
    // modal: ReportSCTVTModal,
    convertData: (data) => {
      const total = data.pop();
      data.push({ ...total, ma_vt: "Tổng cộng", ten_vt: "Tất cả Sản phẩm" });
      return data || [];
    },
    columns: [
      {
        name: "Mã SP",
        selector: (row) => row.ma_vt,
        wrap: true,
        width: "120px",
      },
      {
        name: "Tên Sản phẩm",
        selector: (row) => row.ten_vt,
        width: "200px",
        wrap: true,
      },
      {
        name: "ĐVT",
        selector: (row) => row.ma_dvt,
        width: "100px",
        wrap: true,
      },
      {
        name: "Tồn đầu kỳ",
        selector: (row) => row.ton_dau,
        wrap: true,
        width: "150px",
      },
      {
        name: "Nhập kho",
        selector: (row) => row.sl_nhap,
        wrap: true,
        width: "120px",
      },
      {
        name: "Xuất kho",
        selector: (row) => row.sl_xuat,
        width: "150px",
      },
      {
        name: "Tồn cuối kỳ",
        selector: (row) => row.ton_cuoi,
        wrap: true,
        width: "150px",
      },
    ],
  },
];

function FilterReportHangHoa({ setConcern, setQueryObject }: IFilterReport) {
  const defaultFilter: { [key: string]: any } = {
    timeFrom: moment().startOf("months").format("YYYY-MM-DD"),
    timeTo: moment().format("YYYY-MM-DD"),
    kho: null,
    vat_tu: null,
  };
  const [valueConcern, setValueConcern] = useState(concerns[0].value);
  const [filter, setFilter] = useState(defaultFilter);

  useEffect(() => {
    setConcern(concerns.find((item) => item.value === valueConcern));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueConcern]);

  useEffect(() => {
    const queryObject: { [key: string]: any } = {};
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }
    if (filter.vat_tu) {
      queryObject.ma_vt = filter.vat_tu.ma_vt;
    }
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Box
      sx={{
        p: 0.5,
      }}
    >
      <Stack gap={1}>
        <FilterRadios
          title="Chọn báo cáo"
          values={concerns}
          defaultValue={valueConcern}
          onChange={(newConcern) => setValueConcern(newConcern)}
        />
        <FilterSelectApi
          direction="column"
          title="Chi nhánh"
          apiCode="dmkho"
          placeholder="Chọn chi nhánh"
          value={filter.kho}
          searchFields={["ma_kho", "ten_kho"]}
          getOptionLabel={(option) => option.ten_kho}
          checkEqual={(value, option) => value?.ma_kho === option?.ma_kho}
          onChange={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Sản phẩm"
          placeholder="Chọn Sản phẩm"
          apiCode="dmvt"
          value={filter.vat_tu}
          searchFields={["ma_vt", "ten_vt"]}
          getOptionLabel={(option) => option.ten_vt}
          checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
          onChange={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterTimeFormTo
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          title="Thời gian"
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
      </Stack>
    </Box>
  );
}

export default FilterReportHangHoa;
