import React from "react";
import { Drawer, DrawerProps, SxProps } from "@mui/material";

interface IDrawerBase extends Omit<DrawerProps, ""> {}

const styles: { [key: string]: SxProps } = {
  right: {
    borderRadius: "10px 0px 0px 10px",
  },
  left: {
    borderRadius: "0px 10px 10px 0px",
  },
  bottom: {
    borderRadius: "10px 10px 0px 0px",
  },
  top: {
    borderRadius: "0px 0px 10px 10px",
  },
};

function DrawerBase({ children, anchor = "left", open, onClose }: IDrawerBase) {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: styles[anchor] }}
    >
      {children}
    </Drawer>
  );
}

export default DrawerBase;
