import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import FilterContainer from "../FilterContainer";
import FilterRadios from "../FilterRadios";
import FilterList from "../FilterList";
import FilterSelectApi from "../FilterSelectApi";
import { IFilterProps } from "../../../../types/filter.type";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { asyncSearchList } from "../../../../utils/httpRequests";
import FilterText from "../FilterText";

function FilterCustomer({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    khach_hang: "",
    dien_thoai: "",
    email: "",
    cskd: "",
    cccd: "",
    ma_so_thue: "",
    trang_thai_hoat_dong: "true",
    nhom_kh: null,
    trang_thai_khach_hang: [],
    tinh_thanh: null,
    quan_huyen: null,
    xa_phuong: null,
  };
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [filter, setFilter] = useState(originFilter);
  const [trangThais, setTrangThais] = useState([]);

  const getTrangThais = async () => {
    const resp = await asyncSearchList({
      apiCode: "trangthai",
      token,
      condition: {
        page: 1,
        limit: 9999999,
        q: { ma_ct: "CUSTOMER" },
      },
      withIdApp: false,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi tải trạng thái",
      });
      return;
    }
    const result = (resp?.data || []).reduce((acc: any[], item: any) => {
      return [
        ...acc,
        { label: item.ten_trang_thai, value: item.ma_trang_thai },
      ];
    }, []);
    setTrangThais(result);
  };
  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.khach_hang) {
      condition.$or = [
        {
          ma_kh: {
            $regex: filter.khach_hang.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_kh: {
            $regex: filter.khach_hang.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    if (filter.dien_thoai) {
      condition.dien_thoai = {
        $regex: filter.dien_thoai,
        $options: "i",
      };
    }
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: "i",
      };
    }
    if (filter.cskd) {
      condition.ten_kh2 = {
        $regex: filter.cskd,
        $options: "i",
      };
    }
    if (filter.cccd) {
      condition.id_number = {
        $regex: filter.cccd,
        $options: "i",
      };
    }
    if (filter.ma_so_thue) {
      condition.ma_so_thue = {
        $regex: filter.ma_so_thue,
        $options: "i",
      };
    }
    if (filter.nhom_kh) {
      condition.nh_kh = filter.nhom_kh._id;
    }
    if (filter.tinh_thanh) {
      condition.tinh_thanh = filter.tinh_thanh.ma_tinh_thanh;
    }
    if (filter.quan_huyen) {
      condition.quan_huyen = filter.quan_huyen.ma_quan_huyen;
    }
    if (filter.xa_phuong) {
      condition.xa_phuong = filter.xa_phuong.ma_xa_phuong;
    }
    if ((filter.trang_thai_khach_hang || []).length > 0) {
      condition.trang_thai = { $in: filter.trang_thai_khach_hang };
    }
    switch (filter.trang_thai_hoat_dong) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    setFilter({ ...filter, quan_huyen: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.tinh_thanh]);
  useEffect(() => {
    setFilter({ ...filter, xa_phuong: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.quan_huyen]);

  useEffect(() => {
    getTrangThais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterList
          title="Trạng thái khách hàng"
          items={trangThais}
          defaultValues={filter.trang_thai_khach_hang}
          onChange={(value) =>
            setFilter({ ...filter, trang_thai_khach_hang: value })
          }
        />
        <FilterText
          title="Mã, tên khách hàng"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.khach_hang}
          onSearch={(value) => setFilter({ ...filter, khach_hang: value })}
        />
        <FilterText
          title="Số điện thoại"
          placeholder="Tìm theo số điện thoại"
          value={filter.dien_thoai}
          onSearch={(value) => setFilter({ ...filter, dien_thoai: value })}
        />
        <FilterText
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Nhóm khách"
          apiCode="dmnhkh"
          placeholder="Chọn nhóm khách"
          searchFields={["group_name"]}
          value={filter.nhom_kh}
          onChange={(val) => setFilter({ ...filter, nhom_kh: val })}
          getOptionLabel={(option) => option.group_name}
          checkEqual={(value, option) => value?._id === option?._id}
        />
        <FilterRadios
          title="Trạng thái hoạt động"
          values={[
            { label: "Tất cả", value: "all" },
            { label: "Còn hoạt động", value: "true" },
            { label: "Ngừng hoạt động", value: "false" },
          ]}
          defaultValue={filter.trang_thai_hoat_dong}
          onChange={(value) =>
            setFilter({ ...filter, trang_thai_hoat_dong: value })
          }
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterCustomer;
