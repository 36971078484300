import RenderRoute from "./routes/RenderRoute";

function App() {
  return (
    <>
      <RenderRoute />
    </>
  );
}

export default App;
