import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import ModalBase from "../../../modal/ModalBase";
import { Box, Button, Grid2 } from "@mui/material";
import SelectApiInput from "../../../input/SelectApiInput";
import TextInput from "../../../input/TextInput";
import NumberInput from "../../../input/NumberInput";
import { numeralCustom } from "../../../../../utils/helpers";

const schema = yup.object({
  kho: yup
    .object()
    .typeError("Vui lòng chọn chi nhánh")
    .required("Vui lòng chọn chi nhánh"),
  nam: yup.string().required("Vui lòng nhập năm đầu kỳ"),
  ton00: yup
    .number()
    .typeError("Vui lòng nhập tồn đầu kỳ")
    .required("Vui lòng nhập tồn đầu kỳ"),
});

interface IFormAdd {
  open: boolean;
  defaultValue: any;
  isEdit?: boolean;
  add?: (data: any, isEdit: boolean) => void;
  onClose: () => void;
}

function FormAdd({ open, defaultValue, isEdit, add, onClose }: IFormAdd) {
  const defaultData: { [key: string]: any } = {
    kho: null,
    ton00: 0,
    du00: 0,
    nam: moment().year(),
  };
  const originData = !!defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        kho: defaultValue.ma_kho
          ? { ma_kho: defaultValue.ma_kho, ten_kho: defaultValue.ten_kho }
          : null,
      }
    : defaultData;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const generateDataAdd = (values: any) => {
    const { kho, ...fields } = values;
    return {
      ...fields,
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
    };
  };

  const handleSave = (values: any) => {
    const data = generateDataAdd(values);
    add?.(data, !!isEdit);
    onClose();
  };

  return (
    <ModalBase
      title={`${isEdit ? "Thêm" : "Sửa"} đơn vị tính`}
      open={open}
      handleClose={onClose}
      width="500px"
      actions={[
        <Button
          key={1}
          onClick={handleSubmit(handleSave)}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Lưu
        </Button>,
        <Button
          key={2}
          onClick={onClose}
          variant="contained"
          size="small"
          color="error"
          sx={{
            boxShadow: "none",
            textTransform: "none",
            color: "common.white",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
    >
      <Box sx={{ p: 1 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Controller
              name="kho"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    label="Chi nhánh"
                    labelWidth="30%"
                    placeholder="Chọn chi nhánh"
                    apiCode="dmkho"
                    checkEqual={(value, option) =>
                      value?.ma_kho === option?.ma_kho
                    }
                    searchFields={["ma_kho", "ten_kho"]}
                    getOptionLabel={(option) => option.ten_kho}
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.kho?.message as string}
                  />
                );
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <TextInput
              required
              label="Năm đầu kỳ"
              labelWidth="30%"
              placeholder="Nhập năm đầu kỳ"
              name="nam"
              register={register}
              errorMessage={errors?.nam?.message as string}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <NumberInput
              required
              label="Số lượng tồn"
              labelWidth="30%"
              placeholder="Nhập số lượng tồn"
              name="ton00"
              register={register}
              errorMessage={errors?.ton00?.message as string}
            />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <Controller
              name="du00"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    label="Giá trị tồn"
                    labelWidth="30%"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange(numeralCustom(val).value());
                    }}
                  />
                );
              }}
            />
          </Grid2>
        </Grid2>
      </Box>
    </ModalBase>
  );
}

export default FormAdd;
