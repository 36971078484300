import React from "react";
import { Button, IconButton } from "@mui/material";
import { BsDownload } from "react-icons/bs";
import { downloadFile, generateLinkImage } from "../../../utils/helpers";
import ModalBase from "./ModalBase";
import TableApp from "../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";

interface IModalExcelFile {
  open: boolean;
  handleClose: () => void;
  data: any[];
}

function ModalExcelFile({ open, handleClose, data }: IModalExcelFile) {
  const columns: TableColumn<any>[] = [
    {
      name: "Tên file",
      selector: (row: any) => row.title,
      grow: 1,
    },
    {
      name: "Tải về",
      cell: (row: any) => (
        <IconButton
          onClick={() => {
            downloadFile({ link: generateLinkImage(row.file) });
            handleClose();
          }}
        >
          <BsDownload size={14} />
        </IconButton>
      ),
      grow: 1,
      right: true,
    },
  ];

  return (
    <ModalBase
      width="600px"
      title="Tải về file excel"
      open={open}
      handleClose={handleClose}
      sx={{ zIndex: "9999999" }}
      actions={[
        <Button key={1} variant="outlined" onClick={handleClose}>
          Đóng
        </Button>,
      ]}
    >
      <TableApp columns={columns} data={data} />
      {/* <TableDisplay columns={columns} data={data} /> */}
    </ModalBase>
  );
}

export default ModalExcelFile;
