import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalBase from "./ModalBase";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { asyncDelete, asyncSearchList } from "../../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { TableColumn } from "react-data-table-component";
import { cloneDeep, isArray } from "lodash";
import { v4 } from "uuid";
import TableApp from "../tableapp/TableApp";
import { LuTrash } from "react-icons/lu";

interface IModalDuplicateRow {
  open: boolean;
  fields: { value: string; label: string }[];
  apiCode: string;
  columns: TableColumn<any>[];
  onClose: () => void;
}

function ModalDuplicateRow({
  open,
  apiCode,
  fields,
  columns,
  onClose,
}: IModalDuplicateRow) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);

  const handleCheckDuplicate = async () => {
    if (!fields || loading) return;
    try {
      setLoading(true);
      const group: { [key: string]: any } = {};
      fields.forEach((item) => {
        group[item.value] = `$${item.value}`;
      });
      const query = {
        group: {
          _id: group,
          count: {
            $sum: 1,
          },
          documents: {
            $push: "$$ROOT",
          },
        },
        match: { count: { $gt: 1 } },
      };
      const condition = { page: 1, limit: 20 };
      const resp = await asyncSearchList({
        apiCode,
        token,
        idApp: app?._id,
        condition,
        query,
      });
      if (resp?.status === 200) {
        setData(resp.data);
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error.response?.data?.message ||
          error.response?.data?.error ||
          "Lỗi khi kiểm tra trùng dữ liệu",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && apiCode) {
      handleCheckDuplicate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode, load]);

  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      width="1200px"
      title="Kết quả kiểm tra trùng"
      actions={[
        <Button
          key={1}
          onClick={onClose}
          variant="contained"
          color="error"
          size="small"
          sx={{
            textTransform: "none",
            boxShadow: "none",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Đóng
        </Button>,
      ]}
      hideCloseIcon
    >
      <Box sx={{ maxHeight: "80vh", overflow: "auto", px: 2 }}>
        {loading ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {isArray(data) && data.length > 0 ? (
              <Stack gap={2}>
                {data.map((row) => {
                  return (
                    <Displaydata
                      key={v4()}
                      row={row}
                      columns={columns}
                      apiCode={apiCode}
                      setLoad={setLoad}
                    />
                  );
                })}
              </Stack>
            ) : (
              <Typography sx={{ textAlign: "center" }}>
                Không có kết quả trùng nào
              </Typography>
            )}
          </>
        )}
      </Box>
    </ModalBase>
  );
}

export default ModalDuplicateRow;

function Displaydata({
  row,
  columns,
  apiCode,
  setLoad,
}: {
  row: any;
  columns: TableColumn<any>[];
  apiCode: string;
  setLoad: Dispatch<SetStateAction<number>>;
}) {
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [data, setData] = useState<any[]>(row.documents || []);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toggleCleared, setToggleCleared] = useState<boolean>(false);

  const deleteSelectedRows = async () => {
    try {
      if (isArray(selectedRows) && selectedRows.length > 0) {
        for (let i = 0; i < selectedRows.length; i++) {
          const resp = await asyncDelete({
            apiCode,
            token,
            id: selectedRows[i]._id,
          });
          if (resp.status !== 200) {
            showAlert({
              type: "error",
              message: resp.data?.message || "Lỗi khi xóa dữ liệu",
            });
            break;
          }
          if (i === selectedRows.length - 1) {
            showAlert({
              type: "success",
              message: `Đã xóa thành công ${selectedRows.length} dòng`,
            });
            // loại bỏ các dòng đã xóa
            const ids = selectedRows.map((item) => item._id);
            const dataClone = cloneDeep(data);
            const newData = dataClone.filter((item) => !ids.includes(item._id));
            setData(newData);
          }
        }
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Lỗi khi xóa dữ liệu",
      });
    } finally {
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
      setLoad((prev) => prev + 1);
    }
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ height: "32px" }}
      >
        <Typography
          sx={{
            color: "secondary.main",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {row.count} kết quả trùng
        </Typography>
        {selectedRows?.length > 0 && (
          <IconButton
            onClick={deleteSelectedRows}
            sx={{
              backgroundColor: "error.main",
              color: "common.white",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "error.main",
                color: "common.white",
              },
            }}
          >
            <LuTrash size={16} />
          </IconButton>
        )}
      </Stack>
      <TableApp
        toggleCleared={toggleCleared}
        columns={columns}
        data={data || []}
        selectableRows
        onSelectedRowsChange={setSelectedRows}
      />
    </Stack>
  );
}
