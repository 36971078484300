import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ModalBase from "../../../modal/ModalBase";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import { API_URL, ID_APP } from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import axios from "axios";
import { isArray } from "lodash";
import { numeralCustom } from "../../../../../utils/helpers";
import { LoadingButton } from "@mui/lab";

interface IDichVuGiaoHang {
  data: any;
  setLoad?: Dispatch<SetStateAction<number>>;
}

const apiCode = "pbl";

function DichVuGiaoHang({ data, setLoad }: IDichVuGiaoHang) {
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [info, setInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getService = async () => {
    try {
      const url = `${API_URL}/${ID_APP}/${apiCode}/vtp_getservice/${data?._id}?access_token=${token}`;
      const resp = await axios.get(url);
      setInfo(resp?.data);
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          "Lỗi khi tải dịch vụ giao hàng",
      });
    }
  };

  const applyService = async (dv: any) => {
    if (!dv) return;
    try {
      setLoading(true);
      const url = `${API_URL}/${ID_APP}/${apiCode}/vtp_createservice/${data?._id}?access_token=${token}`;
      let tienThuHo = info.tien_thu_ho;
      if (info.thu_ho === 2 || info.thu_ho === 4) {
        tienThuHo += dv.GIA_CUOC || 0;
      }
      await axios.post(url, {
        ma_dv: dv.MA_DV_CHINH,
        tien_thu_ho: tienThuHo,
      });
      showAlert({
        type: "success",
        message: "Tạo vận đơn thành công",
      });
      setLoad?.((prev) => prev + 1);
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          "Lỗi khi tải dịch vụ giao hàng",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      getService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <ModalBase
        title="Dịch vụ giao hàng"
        width="450px"
        open={openModal}
        handleClose={handleCloseModal}
        actions={[
          <Box key={1} sx={{ width: "100%" }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 6 }}>
                <Stack>
                  <Typography sx={{ fontWeight: 600, color: "info.main" }}>
                    Địa chỉ nhận hàng
                  </Typography>
                  <Typography>{info?.SENDER_ADDRESS?.ADDRESS}</Typography>
                </Stack>
              </Grid2>
              <Grid2 size={{ xs: 6 }}>
                <Stack>
                  <Typography sx={{ fontWeight: 600, color: "success.main" }}>
                    Địa chỉ giao hàng
                  </Typography>
                  <Typography>{info?.RECEIVER_ADDRESS?.ADDRESS}</Typography>
                </Stack>
              </Grid2>
            </Grid2>
          </Box>,
        ]}
      >
        <Box sx={{ maxHeight: "60vh", overflow: "auto" }}>
          {isArray(info?.RESULT) && info?.RESULT.length > 0 ? (
            <Stack gap={1} sx={{ p: 1 }}>
              {info?.RESULT?.map((item: any) => {
                return (
                  <Stack
                    key={item.MA_DV_CHINH}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      p: 1,
                      border: "1px dashed",
                      borderColor: "divider",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack>
                      <Typography sx={{ fontWeight: 600 }}>
                        {item.TEN_DICHVU}
                      </Typography>
                      <Stack direction="row" gap={1}>
                        <Typography>
                          Giá cước:{" "}
                          <Typography
                            component="span"
                            sx={{ color: "error.main", fontWeight: 500 }}
                          >
                            {numeralCustom(item.GIA_CUOC).format()} đ
                          </Typography>
                        </Typography>
                        <Typography>
                          Thời gian:{" "}
                          <Typography
                            component="span"
                            sx={{ color: "secondary.main", fontWeight: 500 }}
                          >
                            {item.THOI_GIAN}
                          </Typography>
                        </Typography>
                      </Stack>
                    </Stack>
                    <LoadingButton
                      loading={loading}
                      onClick={() => applyService(item)}
                      variant="contained"
                      size="small"
                      sx={{
                        boxShadow: "none",
                        textTransform: "none",
                        color: "common.white",
                        "&:hover": { boxShadow: "none" },
                      }}
                    >
                      Áp dụng
                    </LoadingButton>
                  </Stack>
                );
              })}
            </Stack>
          ) : (
            <Typography sx={{ py: 2, textAlign: "center" }}>
              Không tìm thấy dịch vụ
            </Typography>
          )}
        </Box>
      </ModalBase>
      <Button
        onClick={() => setOpenModal(true)}
        variant="contained"
        size="small"
        color="secondary"
        sx={{
          boxShadow: "none",
          textTransform: "none",
          color: "common.white",
          "&:hover": {
            boxShadow: "none",
          },
        }}
      >
        Tạo vận đơn
      </Button>
    </>
  );
}

export default DichVuGiaoHang;
