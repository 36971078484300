import React from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import SelectApiInput from "../../../input/SelectApiInput";
import { numeralCustom } from "../../../../../utils/helpers";
import CheckboxInput from "../../../input/CheckboxInput";
import FormDMDVT from "../../dmdvt/FormDMDVT";
import FormNhomHang from "../../nhomhang/FormNhomHang";
import FormNhomHangPOS from "../../nhomhangpos/FormNhomHangPOS";

interface IInfoSection {
  isEdit?: boolean;
  errors?: FieldErrors<any>;
  control?: Control<any, any>;
  register?: UseFormRegister<FieldValues> | undefined;
}

function InfoSection({ isEdit, errors, control, register }: IInfoSection) {
  return (
    <CollapseSection title="Thông tin sản phẩm" show>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              readOnly={isEdit}
              label="Mã SP "
              labelWidth="25%"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_vt"
              register={register}
            />
            <TextInput
              required
              label="Tên sản phẩm"
              labelWidth="25%"
              placeholder="Nhập tên Sản phẩm"
              name="ten_vt"
              register={register}
              errorMessage={errors?.ten_vt?.message as string}
            />
            <Controller
              control={control}
              name="don_vi_tinh"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    labelWidth="25%"
                    label="Đơn vị tính"
                    placeholder="Chọn đơn vị tính"
                    value={value}
                    onChange={onChange}
                    apiCode="dmdvt"
                    searchFields={["ma_dvt", "ten_dvt"]}
                    getOptionLabel={(option) => option.ten_dvt}
                    checkEqual={(value, option) =>
                      value.ma_dvt === option.ma_dvt
                    }
                    errorMessage={errors?.don_vi_tinh?.message as string}
                    Form={FormDMDVT}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm hàng"
                    placeholder="Chọn nhóm hàng"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnvt"
                    defaultCondition={{ la_nhom_hang_hoa: true }}
                    searchFields={["ten_nvt"]}
                    getOptionLabel={(option) => option.ten_nvt}
                    checkEqual={(value, option) => value._id === option._id}
                    Form={FormNhomHangPOS}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="nhom_pos"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm POS"
                    placeholder="Chọn nhóm POS"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnvt"
                    defaultCondition={{ la_nhom_hang_hoa: false }}
                    searchFields={["ten_nvt"]}
                    getOptionLabel={(option) => option.ten_nvt}
                    checkEqual={(value, option) => value._id === option._id}
                    Form={FormNhomHang}
                  />
                );
              }}
            />
            <Controller
              name="gia_ban_le"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    label="Giá bán"
                    labelWidth="25%"
                    placeholder="Nhập giá bán"
                    value={numeralCustom(value).format()}
                    endAdornment="VND"
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange(numeralCustom(val).value());
                    }}
                  />
                );
              }}
            />
            <TextInput
              label="Vị trí"
              labelWidth="25%"
              placeholder="Vị trí đặt trong kho"
              name="vi_tri"
              register={register}
            />
            <TextInput
              label="Quy cách"
              labelWidth="25%"
              placeholder="VD: Thùng x 20 gói"
              name="quy_cach"
              register={register}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Controller
              name="tg_tk"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Theo dõi tồn kho"
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                  />
                );
              }}
            />
            <Controller
              name="sold_on_pos"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Bán trên pos"
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                  />
                );
              }}
            />
            <Controller
              name="sold_on_app"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Bán trên app"
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                  />
                );
              }}
            />
            <Controller
              name="sold_on_web"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Bán trên web"
                    checked={value}
                    onChange={(_, checked) => onChange(checked)}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
