import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";

type TabType = {
  label: React.ReactNode;
  value: string;
};
type PanelType = {
  value: string;
  content: React.ReactNode;
};
interface ITabbse {
  tabs: TabType[];
  panels: PanelType[];
  defaultActive?: string;
}

function Tabbase({ tabs, panels, defaultActive = "1" }: ITabbse) {
  const [value, setValue] = useState(defaultActive);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} sx={{ minHeight: "unset" }}>
            {tabs.map((tab: TabType) => {
              return (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{
                    px: 2,
                    py: 1,
                    minHeight: "42px",
                    fontWeight: 400,
                    textTransform: "none",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                />
              );
            })}
          </TabList>
        </Box>
        {panels.map((panel: PanelType) => {
          return (
            <TabPanel key={panel.value} value={panel.value} sx={{ p: 1 }}>
              {panel.content}
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
}

export default Tabbase;
