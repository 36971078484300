import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import InfoSection from "./info-section/InfoSection";

const schema = yup.object({
  ten_kh: yup.string().required("Vui lòng nhập tên khách hàng"),
  email: yup.string().email("Email không đúng định dạng"),
  dien_thoai: yup.string().matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, {
    message: "Số điện thoại không hợp lệ",
    excludeEmptyString: true,
  }),
});

function FormNCC({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ma_kh: "",
    ten_kh: "",
    dien_thoai: "",
    email: "",
    ghi_chu: "",
    ma_so_thue: "",
    status: true,
    nhom_ncc: null,
    tinh_thanh_obj: null,
    quan_huyen_obj: null,
    xa_phuong_obj: null,
    dia_chi: "",
    ncc_yn: true,
    kh_yn: false,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        nhom_ncc: defaultValues.nh_kh
          ? {
              _id: defaultValues.nh_kh,
              group_name: defaultValues.ten_nh_kh,
            }
          : null,
        tinh_thanh_obj: defaultValues.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen_obj: defaultValues.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong_obj: defaultValues.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const {
      tinh_thanh_obj,
      quan_huyen_obj,
      xa_phuong_obj,
      nhom_ncc,
      ...fields
    } = values;
    return {
      ...fields,
      tinh_thanh: tinh_thanh_obj?.ma_tinh_thanh || "",
      ten_tinh_thanh: tinh_thanh_obj?.ten_tinh_thanh || "",
      quan_huyen: quan_huyen_obj?.ma_quan_huyen || "",
      ten_quan_huyen: quan_huyen_obj?.ten_quan_huyen || "",
      xa_phuong: xa_phuong_obj?.ma_xa_phuong || "",
      ten_xa_phuong: xa_phuong_obj?.ten_xa_phuong || "",
      nh_kh: nhom_ncc?._id || "",
      ten_nh_kh: nhom_ncc?.group_name || "",
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="1000px"
      formTitle="nhà cung cấp"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
      </Stack>
    </ModalForm>
  );
}

export default FormNCC;
