import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Grid2, Stack } from "@mui/material";
import moment from "moment";
import SelectApiInput from "../../input/SelectApiInput";
import TextInput from "../../input/TextInput";
import DatetimeInput from "../../input/DateTimeInput";
import { generateRandomString, numeralCustom } from "../../../../utils/helpers";
import NumberInput from "../../input/NumberInput";
import CheckboxInput from "../../input/CheckboxInput";

const schema = yup.object({
  ten_chietkhau: yup.string().required("Vui lòng nhập tên"),
  kho: yup
    .object()
    .typeError("Vui lòng chọn kho")
    .required("Vui lòng chọn kho"),
  hieu_luc_tu: yup
    .date()
    .typeError("Vui lòng chọn ngày bắt đầu")
    .required("Vui lòng chọn ngày bắt đầu"),
  hieu_luc_den: yup
    .date()
    .typeError("Vui lòng chọn ngày kết thúc")
    .required("Vui lòng chọn ngày kết thúc"),
});

function FormChietKhauSP({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const { currentStore } = useSelector((state: any) => state.config);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    stt_uu_tien: 0,
    ma_chietkhau: "",
    ten_chietkhau: "",
    kho: currentStore || null,
    hieu_luc_tu: moment().format("YYYY-MM-DD HH:mm"),
    hieu_luc_den: moment().add(7, "days").format("YYYY-MM-DD HH:mm"),
    status: false,
    vat_tu: null,
    nhom_vat_tu: null,
    khach_hang: null,
    nhom_khach: null,
    ty_le_ck: 0,
    tien_ck: 0,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: defaultValues?.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        vat_tu: defaultValues?.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        nhom_vat_tu: defaultValues.ma_nvt
          ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        nhom_khach: defaultValues.nh_kh
          ? { _id: defaultValues.nh_kh, group_name: defaultValues.ten_nh_kh }
          : null,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format("YYYY-MM-DD HH:mm")
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format("YYYY-MM-DD HH:mm")
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const {
      ma_chietkhau,
      vat_tu,
      khach_hang,
      nhom_khach,
      nhom_vat_tu,
      kho,
      ...fields
    } = values;
    return {
      ...fields,
      exfields: { ...(fields.exfields || {}), loai_chiet_khau: 2 },
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_nvt: nhom_vat_tu?._id || "",
      ten_nvt: nhom_vat_tu?.ten_nvt || "",
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
      ma_kh: khach_hang?.ma_kh || "",
      ten_kh: khach_hang?.ten_kh || "",
      nh_kh: nhom_khach?._id || "",
      ten_nh_kh: nhom_khach?.group_name || "",
      ma_chietkhau: ma_chietkhau || `CK${generateRandomString(6)}`,
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="800px"
      formTitle="chiết khấu sản phẩm"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <NumberInput
              label="STT ưu tiên"
              labelWidth="25%"
              name="stt_uu_tien"
              register={register}
            />
            <TextInput
              label="Mã"
              labelWidth="25%"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_chietkhau"
              register={register}
            />
            <TextInput
              required
              label="Tên"
              labelWidth="25%"
              placeholder="Nhập tên chiết khấu"
              name="ten_chietkhau"
              register={register}
              errorMessage={errors?.ten_chietkhau?.message as string}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    required
                    labelWidth="25%"
                    label="Chi nhánh"
                    placeholder="Chọn chi nhánh"
                    value={value}
                    onChange={onChange}
                    apiCode="dmkho"
                    searchFields={["ma_kho", "ten_kho"]}
                    getOptionLabel={(option) => option.ten_kho}
                    checkEqual={(value, option) =>
                      value.ma_kho === option.ma_kho
                    }
                    errorMessage={errors?.kho?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Sản phẩm"
                    placeholder="Chọn sản phẩm"
                    value={value}
                    onChange={onChange}
                    apiCode="dmvt"
                    searchFields={["ma_vt", "ten_vt"]}
                    getOptionLabel={(option) => option.ten_vt}
                    checkEqual={(value, option) =>
                      value.ma_vt === option.ten_vt
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm sản phẩm"
                    placeholder="Chọn sản phẩm"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnvt"
                    searchFields={["ma_nvt", "ten_nvt"]}
                    getOptionLabel={(option) => option.ten_nvt}
                    checkEqual={(value, option) => value._id === option._id}
                    errorMessage={errors?.nhom_vat_tu?.message as string}
                  />
                );
              }}
            />

            <Controller
              name="status"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Áp dụng"
                    checked={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="30%"
                    label="Khách hàng"
                    placeholder="Chọn khách hàng"
                    value={value}
                    onChange={onChange}
                    apiCode="customer"
                    searchFields={["ma_kh", "ten_kh"]}
                    getOptionLabel={(option) => option.ten_kh}
                    checkEqual={(value, option) => value.ma_kh === option.ma_kh}
                    errorMessage={errors?.khach_hang?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="nhom_khach"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="30%"
                    label="Nhóm khách"
                    placeholder="Chọn nhóm khách"
                    value={value}
                    onChange={onChange}
                    apiCode="dmnhkh"
                    searchFields={["group_name"]}
                    getOptionLabel={(option) => option.group_name || option._id}
                    checkEqual={(value, option) => value._id === option._id}
                    errorMessage={errors?.nhom_khach?.message as string}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="hieu_luc_tu"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Từ ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_tu?.message as string}
                />
              )}
            />
            <Controller
              control={control}
              name="hieu_luc_den"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Đến ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_den?.message as string}
                />
              )}
            />
            <Controller
              name="ty_le_ck"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <NumberInput
                    label="Tỷ lệ CK"
                    labelWidth="30%"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Controller
              name="tien_ck"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    label="Tiền CK"
                    labelWidth="30%"
                    value={numeralCustom(value).format()}
                    onChange={(e) => {
                      const val = e.target.value;
                      onChange(numeralCustom(val).value());
                    }}
                  />
                );
              }}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </ModalForm>
  );
}

export default FormChietKhauSP;
