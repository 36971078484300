import React from "react";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  SxProps,
  Typography,
  Zoom,
} from "@mui/material";
import { LuX } from "react-icons/lu";

interface IModalBase {
  children: React.ReactNode;
  open: boolean;
  title?: string;
  actions?: any[];
  width?: string;
  ghost?: boolean;
  sx?: SxProps;
  hideCloseIcon?: boolean;
  handleClose: () => void;
  renderHeader?: () => React.ReactNode;
}

function ModalBase({
  children,
  open,
  title,
  actions,
  width,
  ghost,
  sx,
  hideCloseIcon,
  handleClose,
  renderHeader,
  ...props
}: IModalBase) {
  return (
    <Modal
      open={open}
      disableAutoFocus
      sx={sx}
      keepMounted={false}
      slotProps={{ backdrop: { "aria-hidden": false } }}
      {...props}
    >
      <Zoom in={open}>
        <Box>
          <Box
            sx={{
              width: width || "fit-content",
              minWidth: "200px",
              maxWidth: "95vw",
              minHeight: "50px",
              position: "absolute",
              top: "5%",
              left: "50%",
              transform: "translate(-50%, 0)",
              bgcolor: ghost ? "transparent" : "background.paper",
              py: 1,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!!renderHeader ? (
              renderHeader()
            ) : (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                  height: "40px",
                  pr: 2,
                  pb: 1,
                  pl: 2,
                  borderBottom: "1px dashed",
                  borderColor: "divider",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  {title}
                </Typography>
                {!hideCloseIcon && (
                  <IconButton onClick={handleClose}>
                    <LuX size={16} />
                  </IconButton>
                )}
              </Stack>
            )}
            <Box
              sx={{
                height: "auto",
                py: 1,
              }}
            >
              {children}
            </Box>
            {actions && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                sx={{
                  pt: 1,
                  pr: 1,
                  pl: 1,
                  borderTop: "1px dashed",
                  borderColor: "divider",
                }}
              >
                {actions}
              </Stack>
            )}
          </Box>
        </Box>
      </Zoom>
    </Modal>
  );
}

export default ModalBase;
