import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import DateInput from "../input/DateInput";
import FilterBox from "./FilterBox";

type TimeType = {
  timeFrom: Date | undefined;
  timeTo: Date | undefined;
};

interface IFilterTimeFormTo {
  title?: string;
  defaultTimeFrom?: Date;
  defaultTimeTo?: Date;
  showOptions?: boolean;
  onSearch: (params: TimeType) => void;
  onOptionChange?: () => void;
}

function FilterTimeFormTo({
  title,
  defaultTimeFrom,
  defaultTimeTo,
  onSearch,
  onOptionChange,
}: IFilterTimeFormTo) {
  const [active, setActive] = useState<boolean>(false);
  const [option, setOption] = useState<string>("");
  const [time, setTime] = useState<TimeType>({
    timeFrom: defaultTimeFrom,
    timeTo: defaultTimeTo,
  });

  const handleTimeChange = ({ key, value }: { key: string; value: Date }) => {
    if (option) {
      setOption("");
    }
    setTime({ ...time, [key]: value });
  };

  const handleFilterTime = (time: TimeType) => {
    onSearch(time);
    setActive(!!time.timeFrom || !!time.timeTo);
  };

  useEffect(() => {
    if (time.timeFrom || time.timeTo) {
      setActive(true);
    }
    onSearch(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTime({
      timeFrom: defaultTimeFrom,
      timeTo: defaultTimeTo,
    });
    setActive(!!defaultTimeFrom || !!defaultTimeTo);
    handleFilterTime({ timeFrom: defaultTimeFrom, timeTo: defaultTimeTo });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTimeFrom, defaultTimeTo]);

  return (
    <FilterBox title={title} active={active}>
      <Stack
        gap={1}
        sx={{
          border: "1px dashed",
          borderColor: "divider",
          p: 1,
          borderRadius: "10px",
        }}
      >
        <DateInput
          direction="column"
          label="Từ ngày"
          value={time.timeFrom}
          onChange={(value) => handleTimeChange({ key: "timeFrom", value })}
        />
        <DateInput
          direction="column"
          label="Đến ngày"
          value={time.timeTo}
          onChange={(value) => handleTimeChange({ key: "timeTo", value })}
        />
        <Button
          onClick={() => handleFilterTime(time)}
          size="small"
          variant="contained"
          fullWidth
          sx={{
            boxShadow: "none",
            color: "common.white",
            textTransform: "none",
            "&:hover": { boxShadow: "none" },
          }}
        >
          Áp dụng
        </Button>
      </Stack>
    </FilterBox>
  );
}

export default FilterTimeFormTo;
