import React from 'react'
import { Stack } from '@mui/material'
import Header from '../header'
import Navbar from '../navbar'

interface IMainLayout {
  children: React.ReactNode
}

function MainLayout({ children }: IMainLayout) {
  return (
   <Stack>
    <Header />
    <Navbar />
    {children}
   </Stack>
  )
}

export default MainLayout