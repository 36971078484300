import { Avatar, Box, IconButton, SxProps } from "@mui/material";
import React, { useEffect, useId, useState } from "react";
import { useAlertContext } from "../../../contexts/alert/AlertProvider";
import { useDropzone } from "react-dropzone";
import { LuPlusCircle, LuX } from "react-icons/lu";

interface IImageInput {
  url?: string;
  wrapperSx?: SxProps;
  onChange?: (file: File | undefined) => void;
}

function ImageInput({ url, wrapperSx, onChange }: IImageInput) {
  const id = useId();
  const { showAlert } = useAlertContext();
  const [imageUrl, setImageUrl] = useState<string>(url || "");

  const onDrop = (acceptedFiles: any) => {
    handleFileChange({
      target: { files: acceptedFiles },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { onClick, onBlur, onFocus, onKeyDown, ...rootProps } = getRootProps();

  const handleDeleteFile = () => {
    onChange?.(undefined);
    setImageUrl("");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (!file.type.startsWith("image")) {
      showAlert({ type: "warning", message: "Chỉ cho phép file hình ảnh" });
      return;
    } else {
      const imageURL = URL.createObjectURL(file);
      onChange?.(file);
      setImageUrl(imageURL);
    }
  };

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [imageUrl]);

  useEffect(() => {
    setImageUrl(url || "");
  }, [url]);

  return (
    <Box
      sx={{
        width: "80px",
        height: "80px",
        borderRadius: "4px",
        border: isDragActive ? "2px dashed" : "1px dashed",
        borderColor: "primary.main",
        position: "relative",
        transform: isDragActive ? "scale(1.1)" : "",
        transition: "all linear 0.1s",
        ...wrapperSx,
      }}
      {...rootProps}
    >
      {imageUrl ? (
        <>
          <Avatar
            component="label"
            htmlFor={id}
            src={imageUrl || url}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          />
          <IconButton
            onClick={handleDeleteFile}
            sx={{
              position: "absolute",
              top: "2px",
              right: "2px",
              backgroundColor: "whitish.pureWhite",
              color: "#000",
              padding: "4px",
              "&:hover": {
                backgroundColor: "whitish.pureWhite",
              },
            }}
          >
            <LuX size={12} />
          </IconButton>
        </>
      ) : (
        <IconButton
          component="label"
          htmlFor={id}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "primary.main",
          }}
        >
          <LuPlusCircle size={20} />
        </IconButton>
      )}
      <input
        hidden
        id={id}
        type="file"
        {...getInputProps({
          accept: "image/*",
          onChange: handleFileChange,
          multiple: false,
        })}
      />
    </Box>
  );
}

export default ImageInput;
