import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";

function FilterDVVANCHUYEN({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    dvvc: "",
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.dvvc) {
      condition.$or = [
        {
          ma: {
            $regex: filter.dvvc.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten: {
            $regex: filter.dvvc.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    switch (filter.trang_thai) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} spacing="10px">
        <FilterText
          title="Mã, tên"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.dvvc}
          onSearch={(value) => setFilter({ ...filter, dvvc: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDVVANCHUYEN;
