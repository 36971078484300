import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { ConcernType } from "../../../../../types/report.type";
import { numeralCustom } from "../../../../../utils/helpers";
import { IFilterReport } from "../../../../../types/filter.type";
import FilterContainer from "../../FilterContainer";
import FilterTimeFormTo from "../../FilterTimeFromTo";

function FilterReportKQHDKD({ setQueryObject, setConcern }: IFilterReport) {
  const originFilter: { [key: string]: any } = {
    timeFrom: moment().startOf("months").format("YYYY-MM-DD"),
    timeTo: moment().format("YYYY-MM-DD"),
    timeFromKt: moment().startOf("months").format("YYYY-MM-DD"),
    timeToKt: moment().format("YYYY-MM-DD"),
  };
  const [timeOption, setTimeOption] = useState<any>(null);
  const [filter, setFilter] = useState<{ [key: string]: any }>(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const queryObject: { [key: string]: any } = {
      t: 1,
      cType: "$and",
      id_rptform: "66bdc1d5aa284e5848c549b0",
    };
    const concern: ConcernType = {
      value: "kqhdkd",
      label: "kqhdkd",
      api: "kqhdkd",
      convertData: (data) => {
        return data;
      },
      columns: [
        {
          name: "Chỉ tiêu",
          width: "400px",
          wrap: true,
          selector: (row) => row.chi_tieu,
          cell: (row) => {
            return (
              <Typography sx={row.bold ? { fontWeight: 600 } : {}}>
                {row.chi_tieu}
              </Typography>
            );
          },
        },
        {
          name: "Mã số",
          wrap: true,
          selector: (row) => row.ma_so,
          cell: (row) => {
            return (
              <Typography sx={row.bold ? { fontWeight: 600 } : {}}>
                {row.ma_so}
              </Typography>
            );
          },
        },
        {
          name: "Kỳ trước",
          selector: (row) => row.ky_truoc_nt,
          cell: (row) => {
            return (
              <Typography sx={row.bold ? { fontWeight: 600 } : {}}>
                {numeralCustom(row.ky_truoc_nt).format()}
              </Typography>
            );
          },
          wrap: true,
        },
        {
          name: "Kỳ này",
          selector: (row) => row.ky_nay_nt,
          cell: (row) => {
            return (
              <Typography sx={row.bold ? { fontWeight: 600 } : {}}>
                {numeralCustom(row.ky_nay_nt).format()}
              </Typography>
            );
          },
          wrap: true,
        },
      ],
    };
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = moment(filter.timeTo)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .toISOString();
    }
    if (filter.timeFromKt) {
      queryObject.tu_ngay_kt = filter.timeFromKt;
    }
    if (filter.timeToKt) {
      queryObject.den_ngay_kt = moment(filter.timeToKt)
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .toISOString();
    }
    setConcern(concern);
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOption, filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack gap={1}>
        <FilterTimeFormTo
          title="Thời gian"
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          onSearch={(time) => setFilter({ ...filter, ...time })}
        />
        <FilterTimeFormTo
          title="Thời gian kỳ trước"
          defaultTimeFrom={filter.timeFromKt}
          defaultTimeTo={filter.timeToKt}
          onSearch={(time) =>
            setFilter({
              ...filter,
              timeFromKt: time.timeFrom,
              timeToKt: time.timeTo,
            })
          }
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterReportKQHDKD;
