import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../redux/actions/auth.action";
import { updateProfile } from "../redux/reducers/auth.reducer";
import { useAlertContext } from "../contexts/alert/AlertProvider";

function InitData() {
  const { showAlert } = useAlertContext();
  const token = useSelector((state: any) => state.auth.token);
  const dispatch = useDispatch();

  const initProFile = async () => {
    try {
      const resp = await getProfile({ token });
      if (resp.status === 200) {
        dispatch(updateProfile(resp.data));
      }
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.message || error?.error || "Tải profile không thành công",
      });
    }
  };

  const getInitData = async () => {
    await initProFile();
  };

  useEffect(() => {
    if (token) {
      getInitData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return null;
}

export default InitData;
