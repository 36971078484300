import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  currentStore: any;
  currentHd: any;
};

const initialState: StateType = {
  currentStore: null,
  currentHd: null,
};

const kitchenSlice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {
    updateCurrentStore(state, action) {
      state.currentStore = action.payload;
    },
    updateCurrentHd(state, action) {
      state.currentHd = action.payload;
    },
    resetKitchen(state) {
      state.currentStore = null;
      state.currentHd = null;
    },
  },
});

export const { updateCurrentStore, updateCurrentHd, resetKitchen } =
  kitchenSlice.actions;
export default kitchenSlice.reducer;
