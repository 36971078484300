import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import TextInput from "../../input/TextInput";

const schema = yup.object({
  ma: yup.string().required("Vui lòng nhập mã"),
  ten: yup.string().required("Vui lòng nhập tên"),
  api_token: yup.string().required("Vui lòng nhập api token"),
});

function FormDVVANCHUYEN({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData = { ma: "", ten: "", logo: "", api_token: "" };
  const originData = defaultValues || defaultData;

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    return values;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="600px"
      formTitle="đơn vị tính"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={1}>
        <TextInput
          label="Mã"
          labelWidth="30%"
          required
          placeholder="Nhập mã đơn vị vận chuyển"
          name="ma"
          register={register}
          errorMessage={errors?.ma?.message as string}
        />
        <TextInput
          label="Tên"
          labelWidth="30%"
          required
          placeholder="Nhập tên đơn vị vận chuyển"
          name="ten"
          register={register}
          errorMessage={errors?.ten?.message as string}
        />
        <TextInput
          label="API Token"
          labelWidth="30%"
          required
          placeholder="Vui lòng nhập api token"
          name="api_token"
          register={register}
          errorMessage={errors?.api_token?.message as string}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormDVVANCHUYEN;
