import axios from "axios";
import { API_URL, ID_APP, PUBLIC_TOKEN } from "./constants";
import { generateSearchKeywords } from "./helpers";

type asyncPublicRequestType = {
  method?: "get" | "put" | "put" | "delete";
  endpoint?: string;
  withIdApp?: boolean;
  searchObj?: { [key: string]: any };
  data: any;
};
const asyncPublicRequest = async ({
  method = "get",
  endpoint,
  withIdApp = true,
  searchObj,
  data,
}: asyncPublicRequestType) => {
  const queryString = generateSearchKeywords(searchObj || {});

  const url = `${API_URL}${withIdApp ? `/${withIdApp ? ID_APP : ""}` : ""}${
    endpoint || ""
  }${queryString}${`${!!queryString ? "&" : "?"}access_token=${PUBLIC_TOKEN}`}`;

  const resp = await axios[method](url, data);
  return resp;
};

// get list
type asyncGetListType = {
  apiCode: string;
  withIdApp?: boolean;
  idApp?: string;
  condition?: { [key: string]: any };
  applyToken?: boolean;
  token?: string;
};
const asyncGetList = async ({
  apiCode = "dmnvt",
  withIdApp = true,
  idApp,
  condition = {},
  applyToken = true,
  token,
}: asyncGetListType) => {
  try {
    const queryString = generateSearchKeywords(condition);
    let url = `${API_URL}`;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    url += `/${apiCode}`;
    url += queryString;
    if (applyToken) {
      url += `${!!queryString ? "&" : "?"}access_token=${
        token || PUBLIC_TOKEN
      }`;
    }
    const resp = await axios.get(url);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

// search list
type asyncSearchListType = {
  apiCode: string;
  withIdApp?: boolean;
  idApp?: string;
  condition?: { [key: string]: any };
  query?: { [key: string]: any };
  applyToken?: boolean;
  token?: string;
};
const asyncSearchList = async ({
  apiCode,
  withIdApp = true,
  idApp,
  condition = {},
  query = {},
  applyToken = true,
  token,
}: asyncSearchListType) => {
  try {
    let url = `${API_URL}/search`;
    const queryString = generateSearchKeywords(query);
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    if (apiCode) {
      url += `/${apiCode}`;
    }
    if (queryString) {
      url += queryString;
    }
    if (applyToken) {
      url += `${!!queryString ? "&" : "?"}access_token=${
        token || PUBLIC_TOKEN
      }`;
    }
    const resp = await axios.post(url, condition);
    return resp;
  } catch (error: any) {
    return error.response;
  }
};

// get item
type asyncGetType = {
  apiCode: string;
  id: string;
  withIdApp?: boolean;
  idApp?: string;
  applyToken?: boolean;
  token?: string;
};
const asyncGet = async ({
  apiCode = "dmnvt",
  withIdApp = true,
  idApp,
  applyToken = true,
  token,
  id,
}: asyncGetType) => {
  try {
    let url = API_URL;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    url += `/${apiCode}/${id}`;
    if (applyToken) {
      url += `?access_token=${token || PUBLIC_TOKEN}`;
    }

    const resp = await axios.get(url);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

// post data
type asyncPostDataType = {
  apiCode: string;
  withIdApp?: boolean;
  idApp?: string;
  data?: any;
  applyToken?: boolean;
  token?: string;
};
const asyncPostData = async ({
  apiCode = "dmnvt",
  withIdApp = true,
  idApp,
  data = {},
  applyToken = true,
  token,
}: asyncPostDataType) => {
  try {
    let url = `${API_URL}`;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    if (apiCode) {
      url += `/${apiCode}`;
    }
    if (applyToken) {
      url += `?access_token=${token || PUBLIC_TOKEN}`;
    }
    const resp = await axios.post(url, data);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

// put data
type asyncPutDataType = {
  apiCode: string;
  id: string;
  withIdApp?: boolean;
  idApp?: string;
  data: any;
  applyToken?: boolean;
  token?: string;
};
const asyncPutData = async ({
  apiCode = "dmnvt",
  id,
  withIdApp = true,
  idApp,
  data = {},
  applyToken = true,
  token,
}: asyncPutDataType) => {
  try {
    let url = `${API_URL}`;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    if (apiCode) {
      url += `/${apiCode}`;
    }
    url += `/${id}`;
    if (applyToken) {
      url += `?access_token=${token || PUBLIC_TOKEN}`;
    }

    const resp = await axios.put(url, data);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

// delete
type asyncDeleteType = {
  apiCode: string;
  id: string;
  withIdApp?: boolean;
  idApp?: string;
  applyToken?: boolean;
  token?: boolean;
};
const asyncDelete = async ({
  apiCode = "dmnvt",
  id,
  withIdApp = true,
  idApp,
  applyToken = true,
  token,
}: asyncDeleteType) => {
  try {
    let url = API_URL;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    url += `/${apiCode}/${id}`;
    if (applyToken) {
      url += `?access_token=${token}`;
    }

    const resp = await axios.delete(url);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

// get report
type asyncGetReportType = {
  apiCode: string;
  query?: { [key: string]: any };
  withIdApp?: boolean;
  idApp?: string;
  applyToken?: boolean;
  token?: string;
};
const asyncGetReport = async ({
  apiCode,
  query,
  withIdApp = true,
  idApp,
  applyToken = true,
  token,
}: asyncGetReportType) => {
  try {
    let queryString = "";
    for (let key in query) {
      queryString += `&${key}=${query[key]}`;
    }
    let url = API_URL;
    if (withIdApp) {
      url += `/${idApp || ID_APP}`;
    }
    url += `/${apiCode}?rpt=1${queryString}`;
    if (applyToken) {
      url += `&access_token=${token || PUBLIC_TOKEN}`;
    }
    const resp = await axios.get(url);
    if (resp.status === 200) {
      return resp;
    }
  } catch (error: any) {
    return error.response;
  }
};

type uploadFileType = { formData: FormData; folder: string; token: string };
const uploadFile = async ({ formData, folder, token }: uploadFileType) => {
  try {
    if (!navigator.onLine) {
      return;
    }
    const resp = await axios.post(
      `${API_URL}/uploadfile?json=1&folder=${folder}&access_token=${token}`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return resp;
  } catch (error: any) {
    return error.response;
  }
};

export {
  asyncPublicRequest,
  asyncGet,
  asyncGetList,
  asyncSearchList,
  asyncPostData,
  asyncPutData,
  asyncDelete,
  asyncGetReport,
  uploadFile,
};
