import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Grid2, Stack } from "@mui/material";
import SelectApiInput from "../../input/SelectApiInput";
import TextInput from "../../input/TextInput";
import CheckboxInput from "../../input/CheckboxInput";
import PasswordInput from "../../input/PasswordInput";

const schema = yup.object({
  email: yup.string().required("Vui lòng nhập trường này"),
  name: yup.string().required("Vui lòng nhập họ tên"),
});

function FormParticipant({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    email: "",
    name: "",
    usergroup: null,
    auto_create_user: false,
    active: true,
    admin: false,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        usergroup: defaultValues.group_id
          ? {
              _id: defaultValues.group_id,
              group_name: defaultValues.group_name,
            }
          : null,
        auto_create_user: false,
      }
    : defaultData;

  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });
  const autoCreateUser = watch("auto_create_user");

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { userGroup, ...data } = values;
    const result = { ...data };
    result.group_id = userGroup?._id || "";
    result.group_name = userGroup?.group_name || "";
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="800px"
      formTitle="người dùng"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              readOnly={isEdit}
              required
              name="email"
              label="Tài khoản"
              labelWidth="25%"
              placeholder="email@gmail.com"
              register={register}
              errorMessage={errors?.email?.message as string}
            />
            <TextInput
              label="Họ tên"
              labelWidth="25%"
              placeholder="Họ tên"
              name="name"
              required
              register={register}
              errorMessage={errors?.name?.message as string}
            />
            <Controller
              control={control}
              name="usergroup"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhóm"
                  labelWidth="25%"
                  apiCode="usergroup"
                  placeholder="Chọn nhóm người dùng"
                  searchFields={["group_name"]}
                  value={value}
                  onChange={onChange}
                  getOptionLabel={(option) => option.group_name}
                  checkEqual={(value, option) => value?._id === option?._id}
                />
              )}
            />
            {!!autoCreateUser && (
              <PasswordInput
                label="Mật khẩu"
                labelWidth="25%"
                placeholder="Mật khẩu"
                name="password"
                register={register}
              />
            )}
          </Stack>
        </Grid2>
        <Grid2>
          <Stack gap={2}>
            <Controller
              control={control}
              name="admin"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Là người quản trị"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Còn hoạt động"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="auto_create_user"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Tạo người dùng nếu người dùng chưa được tạo"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </ModalForm>
  );
}

export default FormParticipant;
