import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { isArray } from "lodash";
import { TbTableExport } from "react-icons/tb";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { IFilterReport } from "../../types/filter.type";
import { asyncGetReport } from "../../utils/httpRequests";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../contexts/alert/AlertProvider";
import { TableColumn } from "react-data-table-component";
import TableApp from "../../components/common/tableapp/TableApp";
import { HEADER_HEIGHT, NAVBAR_HEIGHT } from "../../utils/ui.constants";

interface IReportBase {
  report: {
    title: string;
    Filter: React.ElementType<IFilterReport>;
  };
}

const heights = {
  header: HEADER_HEIGHT,
  py: "16px",
  topList: "50px",
};

function ReportBase({ report }: IReportBase) {
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [isChart, setIsChart] = useState(false);
  const [queryObject, setQueryObject] = useState<any>({});
  const [concern, setConcern] = useState<any>(null);
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getReport = async () => {
    try {
      setLoading(true);
      const resp = await asyncGetReport({
        token,
        apiCode: concern.api,
        query: queryObject,
      });
      if (!resp || resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.message || resp?.data?.error || "Lỗi khi tải báo cáo",
        });
        return;
      }
      const data = resp?.data || [];
      if (isArray(data)) {
        if (concern.convertData) {
          const dataConverted = concern.convertData(data);
          setData(dataConverted);
        }
      } else {
        showAlert({
          type: "error",
          message: resp?.message || resp?.error || "Lỗi khi tải báo cáo",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExportReport = () => {
    // Chuyển đổi data-table thành mảng đối tượng
    const formattedData = data.map((row) => {
      const rowData: { [key: string]: any } = {};
      concern.columns.forEach((col: TableColumn<any>) => {
        rowData[col.name as string] = col?.selector?.(row);
      });
      return rowData;
    });
    // Tạo một work sheet từ dữ liệu đã định dạng
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Tạo một work book
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Tạo buffer từ work book
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Tạo Blob từ buffer
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Tự động tải xuống file
    saveAs(dataBlob, `Báo cáo${concern.label ? ` ${concern.label}` : ""}.xlsx`);
  };
  useEffect(() => {
    setOpenModal(false);
    if (concern) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concern, queryObject]);

  return (
    <>
      {concern && concern.modal && openModal && (
        <concern.modal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          data={rowData}
          masterQueryObject={queryObject}
        />
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          width: "100%",
          minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
          height: "auto",
          py: `calc(${heights.py} / 2)`,
        }}
      >
        <Container>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 12, md: 2.5 }}>
              <Stack
                className="hidden-scroll"
                sx={{
                  width: "100%",
                  pr: 0.5,
                  height: `calc(100vh - ${heights.py})`,
                  overflow: "auto",
                }}
              >
                {report?.Filter && (
                  <report.Filter
                    setQueryObject={setQueryObject}
                    setConcern={setConcern}
                  />
                )}
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 9.5 }}>
              <Stack
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    flexShrink: 0,
                    height: heights.topList,
                    px: 2,
                    borderBottom: "1px dashed",
                    borderColor: "divider",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "20px",
                      fontWeight: 600,
                    }}
                  >
                    {report?.title || "Báo cáo"}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      height: "30px",
                      textTransform: "none",
                      color: "common.white",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                      },
                    }}
                    startIcon={<TbTableExport size={16} />}
                    onClick={handleExportReport}
                  >
                    Xuất báo cáo
                  </Button>
                </Stack>
                <Box
                  sx={{
                    width: "100%",
                    height: `calc(100vh - ${heights.topList} - ${heights.py})`,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <TableApp
                      columns={concern?.columns}
                      progressPending={loading}
                      data={data}
                      onRowClicked={(row) => {
                        setRowData(row);
                        setOpenModal(true);
                      }}
                      fixedHeaderScrollHeight={`calc(100vh - ${heights.py}  - ${heights.topList})`}
                    />
                  </Box>
                </Box>
              </Stack>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
}

export default ReportBase;
