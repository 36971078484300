import React from "react";
import WrapperInput, { IWrapperInput } from "./WrapperInput";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { LuCalendarDays } from "react-icons/lu";
import moment from "moment";
import { FormHelperText, Stack } from "@mui/material";

interface IDateInput extends Omit<IWrapperInput, "children"> {
  errorMessage?: string | undefined;
  name?: DatePickerProps<any>["name"];
  value?: DatePickerProps<any>["value"];
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: DatePickerProps<any>["onChange"];
}

const heights = {
  input: "45px",
};

function DateInput({
  label,
  labelWidth = "20%",
  id,
  required,
  errorMessage,
  name,
  value,
  disabled,
  readOnly,
  onChange,
  ...wrapperProps
}: IDateInput) {
  return (
    <WrapperInput
      id={id}
      label={label}
      required={required}
      labelWidth={labelWidth}
      {...wrapperProps}
    >
      <Stack>
        <DatePicker
          value={!!value ? moment(value) : null}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          dayOfWeekFormatter={(date) => date.format("ddd")}
          format="DD/MM/YYYY"
          formatDensity="spacious"
          slots={{
            openPickerIcon: () => <LuCalendarDays size={16} />,
            clearButton: () => <div>x</div>,
          }}
          slotProps={{
            textField: {
              variant: "outlined",
              fullWidth: true,
              size: "small",
              name,
              sx: {
                "& .MuiFormHelperText-root": {
                  color: "error.main",
                  fontStyle: "italic",
                },
                "& .MuiInputBase-root": {
                  height: heights.input,
                  pr: 2,
                  py: 0,
                  border: "2px solid",
                  borderColor: "divider",
                  "&:focus-within": {
                    borderColor: "primary.main",
                  },
                  "& .MuiInputBase-input": {
                    height: heights.input,
                    pr: 0,
                    pl: 2,
                    py: 1,
                  },
                  "& fieldset": { border: "none", px: 0 },
                },
              },
            },
          }}
        />
        {!!errorMessage && (
          <FormHelperText sx={{ fontStyle: "italic", color: "error.main" }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Stack>
    </WrapperInput>
  );
}

export default DateInput;
