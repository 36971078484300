import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { lists } from "../../utils/list";
import { ListType } from "../../types/list.type";
import ListBase from "./ListBase";
import { Typography } from "@mui/material";
import withAuth from "../../hocs/withAuth";
import MainLayout from "../../components/common/layouts/main";

function ListPage() {
  const { code } = useParams();
  const [category, setCategory] = useState<ListType>();

  useEffect(() => {
    if (code) {
      const currentCate = lists[code];
      setCategory(currentCate);
    }
  }, [code]);

  return (
    <MainLayout>
      {category ? (
        <ListBase category={category} />
      ) : (
        <Typography sx={{ textAlign: "center", fontSize: "20px", py: 2 }}>
          Danh mục không tồn tại
        </Typography>
      )}
    </MainLayout>
  );
}

export default withAuth(ListPage);
