import { Avatar, SxProps, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

interface ISummary {
  imageUrl?: string;
  title?: string;
  children: React.ReactNode;
  wrapperSx?: SxProps;
}

function Summary({ imageUrl, title, children, wrapperSx }: ISummary) {
  return (
    <Stack
      sx={{
        width: "100%",
        backgroundColor: "primary.500",
        p: 1,
        borderRadius: "10px",
        height: "100%",
        ...wrapperSx,
      }}
      direction="row"
      gap={2}
      alignItems="center"
    >
      {imageUrl && (
        <Avatar
          src={imageUrl}
          sx={{ width: 50, height: 50, borderRadius: 0 }}
        />
      )}
      <Stack>
        {title && <Typography sx={{ fontWeight: 500 }}>{title}</Typography>}
        {children}
      </Stack>
    </Stack>
  );
}

export default Summary;
