import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { Controller, useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Grid2 } from "@mui/material";
import TextInput from "../../input/TextInput";
import SelectApiInput from "../../input/SelectApiInput";
import FormHangHoa from "../hanghoa/FormHangHoa";
import FormDMDVT from "../dmdvt/FormDMDVT";
import NumberInput from "../../input/NumberInput";
import { numeralCustom } from "../../../../utils/helpers";

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError("Vui lòng chọn Sản phẩm")
    .required("Vui lòng chọn Sản phẩm"),
  dvt: yup
    .object()
    .typeError("Vui lòng chọn đơn vị tính quy đổi")
    .required("Vui lòng chọn đơn vị tính quy đổi"),
  tu: yup.string().required("Vui lòng nhập số lượng quy đổi"),
});

function FormDMQDDVT({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    vat_tu: null,
    dvt: null,
    tu: 1,
    mau: 1,
    ma_dvt_goc: "",
    gia_ban_nt: 0,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues?.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        dvt: defaultValues?.ma_dvt
          ? { ma_dvt: defaultValues.ma_dvt, ten_dvt: defaultValues.ten_dvt }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { vat_tu, dvt, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || "",
      ten_vt: vat_tu?.ten_vt || "",
      ma_dvt: dvt?.ma_dvt || "",
      ten_dvt: dvt?.ten_dvt || "",
      mau: 1,
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="900px"
      formTitle="quy đổi"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            name="vat_tu"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  required
                  labelWidth="25%"
                  label="Sản phẩm"
                  placeholder="Chọn Sản phẩm"
                  apiCode="dmvt"
                  defaultCondition={{
                    sp_yeu_cau_che_bien: { $ne: true },
                    sp_yeu_cau_pha_che: { $ne: true },
                  }}
                  searchFields={["ma_vt", "ten_vt"]}
                  getOptionLabel={(option) => option.ten_vt}
                  checkEqual={(value, option) => value?.ma_vt === option?.ma_vt}
                  value={value}
                  onChange={(val) => {
                    setValue("ma_dvt_goc", val?.ma_dvt || "");
                    onChange(val);
                  }}
                  errorMessage={errors?.vat_tu?.message as string}
                  Form={FormHangHoa}
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <TextInput
            readOnly
            label="Đơn vị tính gốc"
            labelWidth="25%"
            placeholder="Đơn vị tính tự động theo Sản phẩm"
            name="ma_dvt_goc"
            register={register}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            name="dvt"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <SelectApiInput
                  required
                  labelWidth="25%"
                  label="Đơn vị tính"
                  placeholder="Chọn đơn vị tính"
                  apiCode="dmdvt"
                  searchFields={["ma_dvt", "ten_dvt"]}
                  getOptionLabel={(option) => option.ten_dvt}
                  checkEqual={(value, option) =>
                    value?.ma_dvt === option?.ma_dvt
                  }
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.dvt?.message as string}
                  Form={FormDMDVT}
                />
              );
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <NumberInput
            required
            min={1}
            label="SL quy đổi"
            labelWidth="25%"
            placeholder="Nhập số lượng quy đổi"
            name="tu"
            register={register}
            errorMessage={errors?.tu?.message as string}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Controller
            name="gia_ban_nt"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  label="Giá bán"
                  labelWidth="25%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                />
              );
            }}
          />
        </Grid2>
      </Grid2>
    </ModalForm>
  );
}

export default FormDMQDDVT;
