import React, { Dispatch, SetStateAction, useState } from "react";
import ModalBase from "./ModalBase";
import { Box, Button, Checkbox, FormControlLabel, Grid2 } from "@mui/material";
import { cloneDeep } from "lodash";
import { ListType } from "../../../types/list.type";
import ModalDuplicateRow from "./ModalDuplicateRow";

type FieldType = {
  label: string;
  value: string;
};

interface IModalDuplicateData {
  open: boolean;
  category: ListType;
  checkFields: FieldType[];
  onClose: () => void;
  setCheckFields: Dispatch<SetStateAction<FieldType[]>>;
}

function ModalFieldDuplicate({
  open,
  category,
  checkFields,
  setCheckFields,
  onClose,
}: IModalDuplicateData) {
  const [openModalRows, setOpenModalRows] = useState<boolean>(false);

  const handleChangeField = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = e.target.value;
    let fieldsClone = cloneDeep(checkFields || []);
    if (checked) {
      const fieldToAdd = (category?.fields || []).find(
        (f) => f.value === value
      );
      if (fieldToAdd) {
        fieldsClone?.push(fieldToAdd);
      }
    } else {
      fieldsClone = (checkFields || []).filter((f) => f.value !== value);
    }
    setCheckFields?.(fieldsClone);
  };

  return (
    <>
      {openModalRows && (
        <ModalDuplicateRow
          open={openModalRows}
          onClose={() => setOpenModalRows(false)}
          apiCode={category?.apiCode}
          columns={category?.columns}
          fields={checkFields}
        />
      )}
      <ModalBase
        open={open}
        handleClose={onClose}
        width="800px"
        title="Chọn các trường kiểm tra trùng dữ liệu"
        hideCloseIcon
        actions={[
          <Button
            key={1}
            variant="contained"
            color="primary"
            size="small"
            disabled={checkFields?.length <= 0}
            onClick={() => {
              onClose();
              setOpenModalRows(true);
            }}
            sx={{
              textTransform: "none",
              color: "common.white",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Áp dụng
          </Button>,
          <Button
            key={2}
            variant="contained"
            color="error"
            size="small"
            onClick={onClose}
            sx={{
              textTransform: "none",
              color: "common.white",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        <Box sx={{ px: 2 }}>
          <Grid2 container spacing={2}>
            {(category.fields || []).map((field) => {
              const checked = (checkFields || [])?.find(
                (f) => f.value === field.value
              );
              return (
                <Grid2 key={field.value} size={{ xs: 6, md: 3 }}>
                  <FormControlLabel
                    label={field.label}
                    control={
                      <Checkbox
                        size="small"
                        value={field.value}
                        checked={!!checked}
                        onChange={handleChangeField}
                      />
                    }
                  />
                </Grid2>
              );
            })}
          </Grid2>
        </Box>
      </ModalBase>
    </>
  );
}

export default ModalFieldDuplicate;
