import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    profile: null,
  },
  reducers: {
    updateToken(state, action) {
      state.token = action.payload;
    },
    updateProfile(state, action) {
      state.profile = action.payload;
    },
    logoutUser(state) {
      state.token = "";
      state.profile = null;
    },
  },
});

export const { updateToken, updateProfile, logoutUser } = authSlice.actions;
export default authSlice.reducer;
