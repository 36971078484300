import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import InitData from "../routes/InitData";
import { getProfile } from "../redux/actions/auth.action";
import { updateProfile } from "../redux/reducers/auth.reducer";
import { useAlertContext } from "../contexts/alert/AlertProvider";
import { useEffect } from "react";

function withAuth<T>(WrapperComponent: React.ElementType) {
  function Auth(props: T) {
    const { showAlert } = useAlertContext();
    const token = useSelector((state: any) => state.auth.token);
    const dispatch = useDispatch();

    const initProFile = async () => {
      const resp = await getProfile({ token });
      if (resp?.status !== 200) {
        showAlert({
          type: "error",
          message:
            resp?.data?.message ||
            resp?.data?.error ||
            "Lỗi khi tải thông tin người dùng",
        });
      } else {
        dispatch(updateProfile(resp.data));
      }
    };

    useEffect(() => {
      if(token) {
        initProFile();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    if (!token) {
      return <Navigate to="/login" />;
    }
    return (
      <>
        <InitData />
        <WrapperComponent {...props} />
      </>
    );
  }
  return Auth;
}
export default withAuth;
