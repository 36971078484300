import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function withUnAuth<T>(WrapperComponent: React.ElementType) {
  function Auth(props: T) {
    const token = useSelector((state: any) => state.auth.token);
    if (token) {
      return <Navigate to="/" />;
    }
    return <WrapperComponent {...props} />;
  }
  return Auth;
}
export default withUnAuth;
