import React, { useEffect, useState } from "react";
import FilterBox from "./FilterBox";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { isArray } from "lodash";

interface IFilterRadios {
  title?: string;
  values?: { label: string; value: string }[];
  defaultValue?: string;
  showActive?: boolean;
  onChange?: (val: string) => void;
}

function FilterRadios({
  title,
  values,
  defaultValue,
  showActive,
  onChange,
}: IFilterRadios) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    onChange?.(value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <FilterBox title={title} active={true} showActive={showActive}>
      <RadioGroup name="radio" value={value} onChange={handleChange}>
        {isArray(values) &&
          values.map((item) => (
            <FormControlLabel
              key={item.value}
              value={item.value}
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
              label={item.label}
              control={<Radio sx={{ padding: "6px" }} size="small" />}
            />
          ))}
      </RadioGroup>
    </FilterBox>
  );
}

export default FilterRadios;
