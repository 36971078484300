import React, { useEffect, useState } from "react";
import { IconButton, Stack, TextField } from "@mui/material";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { numeralCustom } from "../../../../../../utils/helpers";

interface INumber {
  defaultValue?: number;
  onNumberChange?: (val: number) => void;
}

function Number({ defaultValue, onNumberChange }: INumber) {
  const [value, setValue] = useState<number>(defaultValue || 1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let number = numeralCustom(e.target.value).value() || 0;
    setValue(number);
  };

  const applyNumber = (val?: number) => {
    if (val === value) return;
    const number = (val || value) < 1 ? 1 : val || value;
    setValue(number);
    onNumberChange?.(number);
  };

  useEffect(() => {
    setValue(defaultValue || 0);
  }, [defaultValue]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <IconButton disabled={value <= 1} onClick={() => applyNumber(value - 1)}>
        <LuMinusCircle size={16} />
      </IconButton>
      <TextField
        variant="standard"
        autoComplete="off"
        value={numeralCustom(value).format()}
        onChange={handleChange}
        onKeyUp={(e) => {
          if (e.which === 13) {
            applyNumber();
          }
        }}
        onBlur={() => applyNumber()}
        sx={{
          "& .MuiInputBase-input": {
            textAlign: "center",
            width: "60px",
          },
        }}
      />
      <IconButton onClick={() => applyNumber(value + 1)}>
        <LuPlusCircle size={16} />
      </IconButton>
    </Stack>
  );
}

export default Number;
