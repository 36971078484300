import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import CollapseSection from "../../../collapse/CollapseSection";
import { Grid2, Stack } from "@mui/material";
import TextInput from "../../../input/TextInput";
import SelectApiInput from "../../../input/SelectApiInput";
import FormDMNHKH from "../../dmnhkh/FormDMNHKH";
import DateInput from "../../../input/DateInput";

interface IInfoSection {
  register?: UseFormRegister<FieldValues> | undefined;
  control?: Control<any, any>;
  errors: FieldErrors<any>;
}

function InfoSection({ register, control, errors }: IInfoSection) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack gap={2}>
            <TextInput
              label="Mã khách"
              labelWidth="25%"
              placeholder="Nhập hoặc mặc định là số điện thoại"
              name="ma_kh"
              register={register}
            />
            <TextInput
              required
              label="Tên khách"
              labelWidth="25%"
              placeholder="Nhập tên khách hàng"
              name="ten_kh"
              register={register}
              errorMessage={errors?.ten_kh?.message as string}
            />
            <Controller
              name="nhom_kh"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectApiInput
                    labelWidth="25%"
                    label="Nhóm khách"
                    placeholder="Chọn Nhóm khách"
                    apiCode="dmnhkh"
                    searchFields={["group_name"]}
                    getOptionLabel={(option) => option.group_name}
                    checkEqual={(value, option) => value?._id === option?._id}
                    value={value}
                    onChange={onChange}
                    Form={FormDMNHKH}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Trạng thái"
                  labelWidth="25%"
                  apiCode="trangthai"
                  placeholder="Trạng thái"
                  searchFields={["ma_trang_thai", "ten_trang_thai"]}
                  checkEqual={(value, option) =>
                    value?.ma_trang_thai === value?.ten_trang_thai
                  }
                  getOptionLabel={(option) => option.ten_trang_thai}
                  value={value}
                  onChange={onChange}
                  withIdApp={false}
                  defaultCondition={{ ma_ct: "CUSTOMER", status: true }}
                  errorMessage={errors?.trang_thai?.message as string}
                />
              )}
            />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack spacing={2}>
            <Controller
              name="ngay_sinh"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <DateInput
                    required
                    label="Ngày sinh"
                    labelWidth="25%"
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.ngay_sinh?.message as string}
                  />
                );
              }}
            />
            <TextInput
              required
              label="Số điện thoại"
              labelWidth="25%"
              placeholder="Nhập số điện thoại"
              name="dien_thoai"
              register={register}
              errorMessage={errors?.dien_thoai?.message as string}
            />

            <TextInput
              label="Email"
              labelWidth="25%"
              placeholder="Nhập Email"
              name="email"
              register={register}
              errorMessage={errors?.email?.message as string}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </CollapseSection>
  );
}

export default InfoSection;
