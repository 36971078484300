import React, { useRef, useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LuChevronDown, LuPlusCircle, LuTrash2 } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { usePosContext } from "../../../PosPage";
import {
  updateHds,
  updateIndexHd,
} from "../../../../../redux/reducers/pos.reducer";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";

function Bill() {
  const { hds, renderHds, currentHd, indexHd } = useSelector(
    (state: any) => state.pos
  );
  const { handleCreateHd } = usePosContext();
  const { showAlert } = useAlertContext();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const anchorMenu = useRef<HTMLElement>();
  const dispatch = useDispatch();

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const createHd = () => {
    handleCreateHd();
    dispatch(updateIndexHd(renderHds.length));
  };
  const handleSelectHd = (index: number) => {
    handleCloseMenu();
    dispatch(updateIndexHd(index));
  };
  const handleDeleteHd = (id: string) => {
    if (!id) return;
    const newHds = hds.filter((item: any) => item._id !== id);
    dispatch(updateHds(newHds));
    handleCloseMenu();
  };

  const more = renderHds?.length > 1;

  return (
    <>
      {more && (
        <Menu
          anchorEl={anchorMenu?.current}
          open={openMenu}
          onClose={handleCloseMenu}
          slotProps={{
            paper: {
              elevation: 1,
              sx: { width: anchorMenu?.current?.clientWidth || "auto" },
            },
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          {renderHds.map((item: any, index: number) => {
            return (
              <MenuItem
                key={item._id}
                onClick={() => handleSelectHd(index)}
                sx={{
                  minHeight: "45px",
                  justifyContent: "space-between",
                  "&:hover": {
                    "& .btn-delete-bill": {
                      visibility: "visible",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    color: item._id === currentHd._id ? "primary.main" : "",
                  }}
                >
                  Hóa đơn {index + 1}{" "}
                </Typography>
                <IconButton
                  className="btn-delete-bill"
                  sx={{ color: "error.main", visibility: "hidden" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    showAlert({
                      type: "warning",
                      title: "Xác nhận",
                      message: "Bạn có chắc muốn xóa hóa đơn này không ?",
                      onConfirm: () => handleDeleteHd(item._id),
                    });
                  }}
                >
                  <LuTrash2 size={16} />
                </IconButton>
              </MenuItem>
            );
          })}
        </Menu>
      )}
      <Stack
        direction="row"
        alignItems="flex-end"
        gap={1}
        sx={{ flex: 1, height: "100%" }}
      >
        <IconButton onClick={createHd}>
          <LuPlusCircle size={20} />
        </IconButton>
        <Box ref={anchorMenu} sx={{ display: "flex", alignItems: "center" }}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "120px",
              height: "42px",
              borderRadius: more ? "10px 0px 0px 0px" : "10px 10px 0px 0px",
              backgroundColor: "primary.main",
              color: "common.white",
              position: "relative",
            }}
          >
            <Typography>Hóa đơn {indexHd + 1} </Typography>
          </Stack>
          {more && (
            <IconButton
              onClick={(e) => setOpenMenu(true)}
              sx={{
                width: "42px",
                height: "42px",
                borderRadius: "0px 10px 0px 0px",
                backgroundColor: "primary.main",
                color: "common.white",
                borderLeft: "1px dashed",
                borderColor: "common.white",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              <LuChevronDown size={20} />
            </IconButton>
          )}
        </Box>
      </Stack>
    </>
  );
}

export default Bill;
