import React from "react";
import { TransitionProps } from "@mui/material/transitions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Zoom,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

interface IDialogBase {
  children: React.ReactNode;
  title?: string;
  actions?: React.ReactNode[];
  dialogSx?: SxProps;
  open: boolean;
  onClose?: () => void;
}

function DialogBase({
  children,
  title,
  actions,
  dialogSx,
  open,
  onClose,
}: IDialogBase) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      sx={dialogSx}
      PaperProps={{ sx: { maxWidth: "90vw" } }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}

export default DialogBase;
