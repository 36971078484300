import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IconButton, Stack, TextField, TextFieldProps } from "@mui/material";
import { LuSearch, LuXCircle } from "react-icons/lu";

interface ISearch {
  condition: { [key: string]: any };
  setCondition: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

function Search({
  condition,
  setCondition,
  onFocus,
  onBlur,
}: ISearch & TextFieldProps) {
  const [text, setText] = useState<string>(condition.search || "");

  const handleSearch = (value?: string) => {
    if (value === "") {
      setCondition({ ...condition, search: value, page: 1 });
    } else {
      if (text !== condition.search) {
        setCondition({ ...condition, search: text, page: 1 });
      }
    }
  };

  const handleClear = () => {
    handleSearch("");
  };

  useEffect(() => {
    setText(condition?.search || "");
  }, [condition?.search]);

  return (
    <TextField
      variant="outlined"
      autoComplete="off"
      spellCheck="false"
      placeholder="Tìm theo mã, Tên sản phẩm"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          handleSearch();
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      sx={{
        width: "100%",
        maxWidth: "450px",
        backgroundColor: "background.default",
        borderRadius: "10px",
        "& .MuiInputBase-root": {
          border: "2px solid",
          borderColor: "transparent",
          "&:focus-within": {
            borderColor: "primary.main",
          },
          "& .MuiInputBase-input": {
            px: 2,
            py: 1,
            height: "38px",
          },
          "& fieldset": {
            border: "none",
          },
        },
      }}
      slotProps={{
        input: {
          endAdornment: (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ color: "text.primary" }}
            >
              {!!condition.search && (
                <IconButton onClick={handleClear} sx={{ color: "error.main" }}>
                  <LuXCircle size={16} />
                </IconButton>
              )}
              <LuSearch size={20} />
            </Stack>
          ),
        },
      }}
    />
  );
}

export default Search;
