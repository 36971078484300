import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CollapseSection from "../../../collapse/CollapseSection";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { LuPlusCircle, LuTrash } from "react-icons/lu";
import TableApp from "../../../tableapp/TableApp";
import { TableColumn } from "react-data-table-component";
import { useAlertContext } from "../../../../../contexts/alert/AlertProvider";
import { cloneDeep } from "lodash";
import FormAdd from "./FormAdd";

const columns: TableColumn<any>[] = [
  {
    name: "Sản phẩm",
    wrap: true,
    selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
  },
  {
    name: "ĐVT",
    selector: (row) => row.ma_dvt,
    width: "100px",
    wrap: true,
  },
  {
    name: "SL Khuyến mãi",
    selector: (row) => row.sl_km,
    width: "200px",
    wrap: true,
  },
];

interface IDetaisSection {
  details: any[];
  setDetails: Dispatch<SetStateAction<any>>;
}

function DetailsSection({ details, setDetails }: IDetaisSection) {
  const { showAlert } = useAlertContext();
  const [show, setShow] = useState<boolean>(true);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toggleClear, setToggleClear] = useState(false);

  // add a detail
  const handleAdd = (detail: any, isEdit: boolean = false) => {
    // const { vat_tu, don_vi_tinh, lo, ...detailValue } = detail;
    if (!isEdit) {
      const existed = details.find((item) => item.ma_vt === detail.ma_vt);
      if (existed) {
        showAlert({
          type: "warning",
          message: `Sản phẩm '${detail.ten_vt}' đã có trong danh sách`,
        });
        return;
      }
    }
    if (isEdit) {
      const index = details.findIndex((item) => item.ma_vt === detail.ma_vt);
      if (index >= 0) {
        const detailsCopy = cloneDeep(details);
        detailsCopy.splice(index, 1, detail);
        setDetails(detailsCopy);
      }
    } else {
      setDetails([...details, detail]);
    }
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = (rows: any[]) => {
    setSelectedRows(rows);
  };
  // delete detail
  const handleDeleteDetail = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item.ma_vt);
    let detailsCopy = cloneDeep(details);
    detailsCopy = detailsCopy.filter((item) => !ids.includes(item.ma_vt));
    setDetails(detailsCopy);
  };
  // click on row
  const handleRowClicked = (row: any) => {
    setDefaultValue(row);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseFormAdd = () => {
    setIsEdit(false);
    setDefaultValue(null);
    setOpenForm(false);
  };
  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [details]);

  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          handleClose={handleCloseFormAdd}
          add={handleAdd}
          isEdit={isEdit}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Sản phẩm khuyến mãi"
        show={show}
        onCollapse={() => setShow(!show)}
      >
        <Stack gap={1}>
          <Stack direction="row" gap={1} alignItems="center">
            <Button
              onClick={() => setOpenForm(true)}
              variant="contained"
              size="small"
              startIcon={<LuPlusCircle size={16} />}
              sx={{
                boxShadow: "none",
                color: "common.white",
                textTransform: "none",
                "&:hover": { boxShadow: "none" },
              }}
            >
              Thêm
            </Button>
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: "error.main",
                  color: "common.white",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "error.main",
                  },
                }}
                onClick={handleDeleteDetail}
              >
                <LuTrash size={14} />
              </IconButton>
            )}
          </Stack>
          {details && Array.isArray(details) && details.length > 0 && (
            <Box
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "10px",
                p: 1,
              }}
            >
              <TableApp
                toggleCleared={toggleClear}
                columns={columns}
                data={details}
                selectableRows
                onSelectedRowsChange={handleSelectRowsChange}
                onRowClicked={handleRowClicked}
              />
            </Box>
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default DetailsSection;
