import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../contexts/alert/AlertProvider";
import reports from "../../utils/report";
import MainLayout from "../../components/common/layouts/main";
import withAuth from "../../hocs/withAuth";
import ReportBase from "./ReportBase";
import { Typography } from "@mui/material";

function ReportPage() {
  const { showAlert } = useAlertContext();
  const params = useParams();
  const [report, setReport] = useState<any>();

  useEffect(() => {
    const reportCode = params?.report || "";
    const report = reports[reportCode];
    if (report) {
      setReport(report);
    } else {
      showAlert({
        type: "error",
        message: `Loại báo cáo '${reportCode}' không tồn tại`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <MainLayout>
      {report ? (
        <ReportBase report={report} />
      ) : (
        <Typography sx={{ textAlign: "center", fontSize: "20px", py: 2 }}>
          Báo cáo không tồn tại
        </Typography>
      )}
    </MainLayout>
  );
}

export default withAuth(ReportPage);
