import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Grid2 } from "@mui/material";
import Left from "./components/left";
import Right from "./components/right";
import withAuth from "../../hocs/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { useAlertContext } from "../../contexts/alert/AlertProvider";
import { useBackdropContext } from "../../contexts/backdrop/BackdropProvider";
import { API_URL, ID_APP } from "../../utils/constants";
import axios from "axios";
import {
  asyncPostData,
  asyncPutData,
  asyncSearchList,
} from "../../utils/httpRequests";
import { cloneDeep, isArray } from "lodash";
import {
  createHd,
  initRenderHds,
  updateCurrentHd,
  updateCustomer,
  updateHds,
  updateIndexHd,
  updateOneHd,
} from "../../redux/reducers/pos.reducer";
import { v4 } from "uuid";
import useLocalStorage from "../../hooks/useLocalStorage";
import moment from "moment";

type PosContextType = {
  print: boolean;
  ship: boolean;
  giamGia: { gia_tri: number; so_luong: number };
  pttts: any[];
  handleUpdateCurrentHd: (data: { [key: string]: any }) => void;
  setPrint: (data: boolean) => void;
  setShip: (data: boolean) => void;
  handleCreateHd: () => void;
  saveHd: (trang_thai: number) => any;
  handleAddProductToDetails: (args: {
    product: any;
    gia_ban_le: number;
    ma_dvt: string;
    ten_dvt: string;
    overwrite?: boolean;
  }) => void;
};
const PosContext = createContext<PosContextType>({
  print: true,
  ship: false,
  giamGia: { gia_tri: 0, so_luong: 0 },
  pttts: [],
  handleUpdateCurrentHd: () => {},
  setPrint: (data: boolean) => {},
  setShip: (data: boolean) => {},
  handleCreateHd: () => {},
  saveHd: () => {},
  handleAddProductToDetails: (args) => {},
});
const apiCode = "pbl";

function PosPage() {
  const { token, profile } = useSelector((state: any) => state.auth);
  const { currentStore } = useSelector((state: any) => state.config);
  const app = useSelector((state: any) => state.app.data);
  const { hds, renderHds, indexHd, currentHd } = useSelector(
    (state: any) => state.pos
  );
  const { showAlert } = useAlertContext();
  const { setOpenBackdrop } = useBackdropContext();
  const [print, setPrint] = useLocalStorage<boolean>("pos_print", true);
  const [ship, setShip] = useLocalStorage<boolean>("pos_ship", false);
  const [pttts, setPttts] = useState<any[]>([]);

  const dispatch = useDispatch();

  type handleAddProductToDetailsType = {
    product: any;
    gia_ban_le: number;
    ma_dvt: string;
    ten_dvt: string;
    overwrite?: boolean;
  };
  const handleAddProductToDetails = ({
    product,
    gia_ban_le,
    ma_dvt,
    ten_dvt,
    overwrite = false,
  }: handleAddProductToDetailsType) => {
    if (!currentHd) return;
    const { ma_vt, ten_vt, gia_mua, ma_nvt, ten_nvt } = product;
    const detailData = {
      _id: v4(),
      dien_giai: "",
      ma_vt,
      ten_vt,
      ma_nvt,
      ten_nvt,
      ma_dvt,
      ten_dvt,
      gia_ban: gia_ban_le,
      gia_ban_nt: gia_ban_le,
      gia_ban_le_goc: gia_ban_le,
      gia_von: gia_mua,
      gia_von_nt: gia_mua,
      sl_order: 1,
      sl_xuat: 1,
      tien_hang: gia_ban_le,
      tien_hang_nt: gia_ban_le,
      tien_ck: 0,
      tien_ck_nt: 0,
      ty_le_ck: 0,
      tien: gia_ban_le,
      tien_nt: gia_ban_le,
      tien_xuat: gia_ban_le,
      tien_xuat_nt: gia_ban_le,
      tk_dt: "51111",
      tk_gv: "6321",
      tk_vt: "1561",
    };
    const currentHdClone = cloneDeep(currentHd);

    if (!!overwrite) {
      const existedDetail = (currentHdClone?.details || []).find(
        (item: any) => item.ma_vt === product.ma_vt
      );
      if (!!existedDetail) {
        existedDetail.sl_order += 1;
        existedDetail.sl_xuat = existedDetail.sl_order;
        const tienCk: number =
          Math.floor(
            ((existedDetail.ty_le_ck || 0) *
              (existedDetail.gia_ban_le_goc || 0)) /
              100
          ) * existedDetail.sl_order;
        existedDetail.tien_ck_nt = tienCk;
      } else {
        currentHdClone.details.push(detailData);
      }
    } else {
      currentHdClone.details.push(detailData);
    }
    handleUpdateCurrentHd({ details: currentHdClone.details });
  };
  const handlePrint = async (idHd: string) => {
    try {
      const mauIn = (currentStore?.printers || []).find(
        (item: any) => item.ma_cn.toLowerCase() === apiCode
      );
      if (!mauIn) {
        showAlert({
          type: "warning",
          message: `Chi nhánh hiện tại chưa có mẫu in`,
        });
        return;
      }
      const idMauIn = mauIn.id_mau_in;
      const printUrl = `${API_URL}/${
        app?._id || ID_APP
      }/${apiCode}/excel/${idMauIn}?_id=${idHd}&print=1&access_token=${token}`;
      const resp = await axios.get(printUrl);
      let content: any;
      if (resp && resp.status === 200) {
        content = resp.data;
      }
      const printFrame = document.createElement("iframe");
      printFrame.style.position = "absolute";
      printFrame.style.width = "0";
      printFrame.style.height = "0";
      printFrame.style.border = "none";
      document.body.appendChild(printFrame);
      printFrame?.contentDocument?.write(content);
      printFrame?.contentDocument?.close();
      setTimeout(() => {
        printFrame?.contentWindow?.focus(); // Đảm bảo focus vào iframe trước khi in
        printFrame?.contentWindow?.print(); // Thực hiện lệnh in
        // Sau khi in xong, xóa iframe để không giữ lại
        document.body.removeChild(printFrame);
      }, 500); // Đảm bảo đủ thời gian để nội dung được render
    } catch (error: any) {
      showAlert({
        type: "error",
        message:
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          error?.error ||
          "Lỗi khi in hóa đơn",
      });
    }
  };
  const getOriginCustomer = async () => {
    const resp = await asyncSearchList({
      apiCode: "customer",
      token,
      idApp: app?._id,
      condition: {
        page: 1,
        limit: 1,
        q: { ma_kh: currentHd?.ma_kh, kh_yn: true },
      },
    });
    if (
      resp &&
      resp.status === 200 &&
      isArray(resp.data) &&
      resp.data.length > 0
    ) {
      dispatch(updateCustomer(resp.data[0]));
    } else {
      showAlert({
        type: "error",
        message:
          resp?.data?.error ||
          resp?.data?.message ||
          "Lỗi khi tải thông tin khách hàng",
      });
    }
  };
  const handleCreateHd = async () => {
    const respNv = await asyncSearchList({
      apiCode: "dmnv",
      token,
      condition: { page: 1, limit: 1, q: { user: profile?.email } },
    });
    if (respNv?.status !== 200) {
      showAlert({
        type: "error",
        message:
          respNv?.data?.error ||
          respNv?.data?.message ||
          "Lỗi khi tải thông tin nhân viên",
      });
      return;
    }
    const hd = {
      _id: v4(),
      dich_vu_giao_hang: "VTP",
      ma_kho: currentStore?.ma_kho || "",
      ten_kho: currentStore?.ten_kho || "",
      ma_nv: respNv?.[0]?.ma_nv || "",
      ten_nv: respNv?.[0]?.ten_nv || "",
      pt_thanh_toan: "",
      ten_pt_thanh_toan: "",
      is_new: true,
      ma_kh: "",
      ten_kh: "",
      t_tien_nt: 0,
      t_ck_nt: 0,
      tien_ck_hd: 0,
      ty_le_ck_hd: 0,
      t_tt_nt: 0,
      tien_thu: 0,
      phai_tra: 0,
      exfields: {},
      details: [],
      promotion: [],
      dien_giai: "",
      date_created: moment().toISOString(),
      user_created: profile?.email || "",
      ma_kenh: "Showroom",
      ten_kenh: "Showroom",
    };
    dispatch(createHd(hd));
    return hd;
  };
  const handleUpdateCurrentHd = (updateData: { [key: string]: any } = {}) => {
    const hdData = { ...currentHd, ...updateData };
    dispatch(updateOneHd(hdData));
  };
  const handleInitRenderHds = async () => {
    if (!currentStore || !currentStore.ma_kho) return;
    const hdsInStore = (hds || []).filter(
      (hd: any) => hd.ma_kho === currentStore.ma_kho
    );
    if (hdsInStore.length === 0) {
      const hd = await handleCreateHd();
      dispatch(initRenderHds([hd]));
      dispatch(updateIndexHd(0));
    } else {
      dispatch(initRenderHds(hdsInStore));
      if (!hdsInStore[indexHd]) {
        dispatch(updateIndexHd(0));
      }
    }
  };
  const saveHd = async (trang_thai = 0) => {
    const { _id, is_new, details, ...fields } = currentHd;
    const method = !is_new ? asyncPutData : asyncPostData;
    const hdToSave = {
      ...fields,
      trang_thai,
      details: details.map((detail: any) => {
        const { _id, ...detailData } = detail;
        return {
          ...detailData,
        };
      }),
    };
    if (!hdToSave.ma_kh) {
      showAlert({
        type: "warning",
        message: "Vui lòng chọn khách hàng",
      });
      return;
    }
    if (hdToSave.tien_thu < hdToSave.t_tt_nt) {
      showAlert({ type: "warning", message: "Số tiền thu chưa đủ" });
      return;
    }
    if (!hdToSave.pt_thanh_toan) {
      showAlert({
        type: "warning",
        message: "Vui lòng chọn phương thức thanh toán",
      });
      return;
    }

    setOpenBackdrop?.(true);
    const resp = await method({
      apiCode,
      data: hdToSave,
      id: _id,
      token,
      idApp: app?._id,
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.messsage || "Lỗi khi lưu hóa đơn",
      });
      setOpenBackdrop?.(false);
      return;
    }
    const newHds = hds.filter((item: any) => item._id !== currentHd._id);
    dispatch(updateHds(newHds));
    showAlert({ type: "success", message: "Thanh toán hoàn tất" });
    if (Number(trang_thai) === 5 && print) {
      handlePrint(resp?.data?._id);
    }
    setOpenBackdrop?.(false);
    return resp?.data;
  };

  const getPttts = async () => {
    setOpenBackdrop?.(true);
    const resp = await asyncSearchList({
      apiCode: "ptthanhtoan",
      token,
      idApp: app?._id,
      condition: { page: 1, limit: 999999 },
    });
    if (resp?.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error ||
          resp?.data?.message ||
          "Lỗi khi tải phương thức thanh toán",
      });
      setOpenBackdrop?.(false);
      return;
    }
    setPttts(resp?.data || []);
    setOpenBackdrop?.(false);
  };
  const initData = () => {
    getPttts();
  };

  const giamGia: { gia_tri: number; so_luong: number } = useMemo(() => {
    if (!currentHd) return { gia_tri: 0, so_luong: 0 };
    let giaTri = 0;
    let soLuong = 0;
    let item;
    for (let i = 0; i < currentHd.details.length; i++) {
      item = currentHd.details[i];

      if (item.tien_ck_nt > 0) {
        giaTri += item.tien_ck_nt;
        soLuong += 1;
      }
    }
    return {
      gia_tri: giaTri,
      so_luong: soLuong,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd]);

  useEffect(() => {
    if (currentStore) {
      if (hds?.length > 0) {
        handleInitRenderHds();
      }
    } else {
      handleCreateHd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  useEffect(() => {
    if (!!renderHds && renderHds.length > 0 && renderHds[indexHd]) {
      dispatch(updateCurrentHd(renderHds[indexHd]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexHd, renderHds]);

  useEffect(() => {
    if (hds) {
      handleInitRenderHds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hds]);

  useEffect(() => {
    if (currentHd?.ma_kh) {
      getOriginCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHd?.ma_kh]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PosContext.Provider
      value={{
        ship,
        print,
        giamGia,
        pttts,
        setPrint,
        setShip,
        handleCreateHd,
        handleUpdateCurrentHd,
        saveHd,
        handleAddProductToDetails,
      }}
    >
      <Box>
        <Grid2 container>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Left />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Right />
          </Grid2>
        </Grid2>
      </Box>
    </PosContext.Provider>
  );
}

export default withAuth(PosPage);

export const usePosContext = () => {
  const value = useContext(PosContext);
  if (!value) {
    throw new Error("PosContext must be used inside PosProvider");
  }
  return value;
};
