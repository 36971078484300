import React, { useState } from 'react'
import { Button, Menu, Typography } from '@mui/material'
import { LuBell } from 'react-icons/lu'

function Notification() {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

  return (
    <>
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu} slotProps={{paper: {elevation: 1, sx: {p: 2}}}}>
        <Typography>Chưa có thông báo mới</Typography>
    </Menu>
        <Button
        onClick={e => setAnchorEl(e.currentTarget)}
        variant='text'
        size='small'
        endIcon={<LuBell size={16} />}
        sx={{textTransform:'none', color: 'text.primary'}}>
        Thông báo
        </Button>
    </>
  )
}

export default Notification