import React from "react";
import { Box } from "@mui/material";
import Table, { ColumnType } from "rc-table";

const columns: ColumnType<any>[] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 200,
    fixed: true,
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
    width: 200,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 200,
  },
  {
    title: "Operations",
    dataIndex: "",
    key: "operations",
    width: 200,
    render: () => <a href="#">Delete</a>,
  },
];
const data = [
  { name: "Jack", age: 28, address: "some where", key: "1" },
  { name: "Rose", age: 36, address: "some where", key: "2" },
];

function TestPage() {
  return (
    <Box sx={{ p: 2, width: "900px" }}>
      <Table columns={columns} data={data} scroll={{ x: "2000px" }} />
    </Box>
  );
}

export default TestPage;
