import { RouteType } from "../types/route.type";
import LoginPage from "../pages/login/LoginPage";
import HomePage from "../pages/home/HomePage";
import ListPage from "../pages/list/ListPage";
import NotFoundPage from "../pages/notfound/NotFoundPage";
import TestPage from "../pages/test/TestPage";
import PosPage from "../pages/pos/PosPage";
import ReportPage from "../pages/report/ReportPage";

const publicRoutes: RouteType[] = [
  {
    id: "notfound",
    path: "*",
    component: NotFoundPage,
  },
  {
    id: "test",
    path: "/test",
    component: TestPage,
  },
];

const restrictedRoutes: RouteType[] = [
  {
    id: "login",
    path: "/login",
    component: LoginPage,
  },
];
const privateRoutes: RouteType[] = [
  {
    id: "home",
    path: "/",
    component: HomePage,
  },
  {
    id: "pos",
    path: "/pos",
    component: PosPage,
  },
  {
    id: "list",
    path: "/list/:code",
    component: ListPage,
  },
  {
    id: "report",
    path: "/report/:report",
    component: ReportPage,
  },
];

export { publicRoutes, restrictedRoutes, privateRoutes };
