import React, { useState } from "react";
import IConButtonApp from "../../IconButtonApp/IConButtonApp";
import AvatarApp from "../../avatarapp/AvatarApp";
import { LuLogOut, LuSettings } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useNavigate } from "react-router-dom";
import ModalUser from "../../modal/ModalUser";
import { generateLinkImage } from "../../../../utils/helpers";
import { logout } from "../../../../redux/actions/auth.action";
import { MenuItemType } from "../../../../types/menu.type";

interface IUserSetting {
  options: MenuItemType[];
}

function UserSetting({ options }: IUserSetting) {
  const { showAlert } = useAlertContext();
  const profile = useSelector((state: any) => state.auth.profile);
  const [anchorMenu, setAnchorMenu] = useState<HTMLElement>();
  const [openModalUser, setOpenModalUser] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout({ dispatch });
  };

  const handleCloseMenu = () => {
    setAnchorMenu(undefined);
  };

  return (
    <>
      {openModalUser && (
        <ModalUser
          open={openModalUser}
          onClose={() => setOpenModalUser(false)}
        />
      )}
      <Menu
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{ paper: { elevation: 1 } }}
        disableAutoFocus
      >
        <Stack sx={{ minWidth: "300px" }}>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              setOpenModalUser(true);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              px: 2,
              py: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "text.primary",
              }}
            >
              Xin chào{" "}
              <Typography component="span" sx={{ fontSize: "16px" }}>
                {profile?.name}
              </Typography>
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Tài khoản: {profile?.email}
            </Typography>
          </MenuItem>
          <Divider />
          <List>
            {options.map((item) => {
              return (
                <ListItemButton
                  key={item.text}
                  sx={{ py: 0.5 }}
                  onClick={
                    item.path ? () => navigate(item.path as string) : undefined
                  }
                >
                  <ListItemText>{item.text}</ListItemText>
                </ListItemButton>
              );
            })}
          </List>
          <Divider />
          <Stack sx={{ px: 2, py: 1 }}>
            <Button
              color="error"
              startIcon={<LuLogOut size={14} />}
              sx={{ textTransform: "none", fontWeight: 400 }}
              onClick={() => {
                handleCloseMenu();
                showAlert({
                  title: "Đăng xuất",
                  type: "info",
                  message: "Bạn sẽ đăng xuất khỏi hệ thống",
                  onConfirm: handleLogout,
                });
              }}
            >
              Đăng xuất
            </Button>
          </Stack>
        </Stack>
      </Menu>
      <IConButtonApp
        sx={{ gap: 1, borderRadius: "50px", p: 0.5 }}
        onClick={(e) => setAnchorMenu(e.currentTarget)}
      >
        <AvatarApp
          name={profile?.name}
          src={
            profile?.picture && profile.picture !== "/images/avatar.jpg"
              ? generateLinkImage(profile.picture)
              : ""
          }
          sx={{ width: "30px", height: "30px", fontSize: "14px" }}
        />
        <LuSettings size={20} />
      </IConButtonApp>
    </>
  );
}

export default UserSetting;
