import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";
import FilterContainer from "../FilterContainer";

function FilterRPT({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    ma_cn: "",
    ten_mau_in: "",
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.ma_cn) {
      condition.ma_cn = filter.ma_cn;
    }
    if (filter.ten_mau_in) {
      condition.ten_mau_in = {
        $regex: filter.ten_mau_in.split(" ").join(".*"),
        $options: "i",
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterText
          title="Mã CN"
          placeholder="Tìm theo mã CN"
          onSearch={(value) => setFilter({ ...filter, ma_cn: value })}
        />
        <FilterText
          title="Tên mẫu in"
          placeholder="Tìm theo tên mẫu in"
          onSearch={(value) => setFilter({ ...filter, ten_mau_in: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterRPT;
