import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import FilterContainer from "../FilterContainer";
import { IFilterProps } from "../../../../types/filter.type";
import FilterText from "../FilterText";
import FilterSelectApi from "../FilterSelectApi";
import FilterRadios from "../FilterRadios";

function FilterDMNCC({ setCondition }: IFilterProps) {
  const originFilter: { [key: string]: any } = {
    khach_hang: "",
    dien_thoai: "",
    email: "",
    ma_so_thue: "",
    trang_thai_hoat_dong: "true",
    nhom_ncc: null,
    tinh_thanh: null,
    quan_huyen: null,
    xa_phuong: null,
  };
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    const condition: { [key: string]: any } = {};
    if (filter.khach_hang) {
      condition.$or = [
        {
          ma_kh: {
            $regex: filter.khach_hang.split(" ").join(".*"),
            $options: "i",
          },
        },
        {
          ten_kh: {
            $regex: filter.khach_hang.split(" ").join(".*"),
            $options: "i",
          },
        },
      ];
    }
    if (filter.dien_thoai) {
      condition.dien_thoai = {
        $regex: filter.dien_thoai,
        $options: "i",
      };
    }
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: "i",
      };
    }
    if (filter.ma_so_thue) {
      condition.ma_so_thue = {
        $regex: filter.ma_so_thue,
        $options: "i",
      };
    }
    if (filter.nhom_ncc) {
      condition.nh_kh = filter.nhom_ncc._id;
    }
    switch (filter.trang_thai_hoat_dong) {
      case "true":
        condition.status = true;
        break;
      case "false":
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer handleRefreshFilter={handleRefreshFilter}>
      <Stack sx={{ width: "100%" }} gap={1}>
        <FilterText
          title="Mã, tên NCC"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.khach_hang}
          onSearch={(value) => setFilter({ ...filter, khach_hang: value })}
        />
        <FilterText
          title="Số điện thoại"
          placeholder="Tìm theo số điện thoại"
          value={filter.dien_thoai}
          onSearch={(value) => setFilter({ ...filter, dien_thoai: value })}
        />
        <FilterText
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterSelectApi
          direction="column"
          title="Nhóm NCC"
          apiCode="dmnhncc"
          placeholder="Chọn nhóm NCC"
          searchFields={["group_name"]}
          value={filter.nhom_ncc}
          onChange={(val) => setFilter({ ...filter, nhom_ncc: val })}
          getOptionLabel={(option) => option.group_name}
          checkEqual={(value, option) => value?._id === option?._id}
        />
        <FilterRadios
          title="Trạng thái hoạt động"
          values={[
            { label: "Tất cả", value: "all" },
            { label: "Còn hoạt động", value: "true" },
            { label: "Ngừng hoạt động", value: "false" },
          ]}
          defaultValue={filter.trang_thai_hoat_dong}
          onChange={(value) =>
            setFilter({ ...filter, trang_thai_hoat_dong: value })
          }
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterDMNCC;
