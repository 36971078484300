import React from "react";
import { TextField } from "@mui/material";
import type { TextFieldProps } from "@mui/material";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface ITextInput {
  label: string;
  register?: UseFormRegister<FieldValues> | undefined;
  errorMessage?: string | undefined;
}

function TextInput({
  name,
  label,
  errorMessage,
  register,
  ...props
}: ITextInput & TextFieldProps) {
  return (
    <TextField
      name={name}
      variant="filled"
      autoComplete="off"
      label={errorMessage || label}
      error={!!errorMessage}
      {...register?.(name as string)}
      {...props}
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: "background.default",
          borderRadius: "10px",
          "&:before": {
            display: "none",
          },
          "&:after": {
            display: "none",
          },
          "& .MuiInputBase-input": {
            height: "50px",
          },
        },
      }}
    />
  );
}

export default TextInput;
