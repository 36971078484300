import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { LuCheck } from "react-icons/lu";

interface IFilterBox {
  children: React.ReactNode;
  title?: string;
  active?: boolean;
  showActive?: boolean;
}

function FilterBox({ children, title, active, showActive = true }: IFilterBox) {
  return (
    <Box
      sx={{
        py: 1,
        px: 1,
        position: "relative",
        overflow: "hidden",
        backgroundColor: "background.paper",
        borderRadius: "10px",
      }}
    >
      {showActive && (
        <>
          <Box
            sx={{
              display: active ? "block" : "none",
              position: "absolute",
              top: 0,
              right: 0,
              fontSize: "12px",
              width: "40px",
              height: "40px",
              transform: "rotate(45deg) translateY(-28px)",
              color: "black",
              backgroundColor: "success.main",
            }}
          ></Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            className={active ? "animate__animated animate__heartBeat" : ""}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "common.white",
            }}
          >
            <LuCheck size={16} />
          </Stack>
        </>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
        </Stack>
      </Stack>
      {children}
    </Box>
  );
}

export default FilterBox;
