import React, { createContext, useContext, useState } from "react";
import DialogBase from "../../components/common/dialog/DialogBase";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import SuccesssIcon from "../../assets/images/success.png";
import ErrorIcon from "../../assets/images/error.png";
import InfoIcon from "../../assets/images/info.png";
import WarningIcon from "../../assets/images/warning.png";

const images = {
  success: SuccesssIcon,
  error: ErrorIcon,
  info: InfoIcon,
  warning: WarningIcon,
};

type variantType = "success" | "info" | "warning" | "error";
type showAlertType = {
  title?: string;
  type?: variantType;
  message?: string;
  width?: string;
  onConfirm?: () => void;
  renderContent?: () => React.ReactNode;
};
type AlertConfigsType = {
  open: boolean;
} & showAlertType;

type AlertProviderType = {
  showAlert: (prarams: showAlertType) => void;
};

const AlertContext = createContext<AlertProviderType>({
  showAlert: () => console.log("alert context"),
});

const defaultData: AlertConfigsType = {
  title: "",
  type: "error",
  message: "",
  width: "400px",
  open: false,
  onConfirm: undefined,
  renderContent: undefined,
};

function AlertProvider({ children }: { children: React.ReactNode }) {
  const [alertConfigs, setAlertConfigs] =
    useState<AlertConfigsType>(defaultData);

  const handleClose = () => setAlertConfigs({ ...alertConfigs, open: false });

  const showAlert = ({ type = "error", ...params }: showAlertType) => {
    setAlertConfigs({
      open: true,
      type,
      ...params,
    });
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      <DialogBase
        open={alertConfigs.open}
        title={alertConfigs.title}
        dialogSx={{ zIndex: 6001 }}
        onClose={handleClose}
        actions={[
          !!alertConfigs.onConfirm ? (
            <Button
              key={1}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                alertConfigs?.onConfirm?.();
                handleClose();
              }}
              sx={{
                textTransform: "none",
                color: "common.white",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Đồng ý
            </Button>
          ) : null,
          <Button
            key={2}
            variant="contained"
            color={alertConfigs.onConfirm ? "error" : "primary"}
            size="small"
            onClick={handleClose}
            sx={{
              textTransform: "none",
              color: "common.white",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            {!!alertConfigs.onConfirm ? "Đóng" : "Đã hiểu"}
          </Button>,
        ]}
      >
        <Stack
          alignItems="center"
          gap={1}
          sx={{ width: alertConfigs.width || "400px", maxWidth: "100%" }}
        >
          {!!alertConfigs.renderContent ? (
            alertConfigs.renderContent()
          ) : (
            <>
              <Avatar
                src={images[alertConfigs.type || "error"]}
                alt="alert icon"
                sx={{ width: "80px", height: "80px" }}
              />
              {alertConfigs.message && (
                <Typography sx={{ textAlign: "center" }}>
                  {alertConfigs.message}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </DialogBase>
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;

export const useAlertContext = () => {
  const value = useContext(AlertContext);
  if (!value) {
    throw new Error("AlertContext must be used inside AlertProvider");
  }
  return value;
};
