import React, { useEffect, useState } from "react";
import { Box, Grid2, Stack } from "@mui/material";
import TotalLine from "../../../../../components/common/total-line";
import TextInput from "../../../../../components/common/input/TextInput";
import { useSelector } from "react-redux";
import { usePosContext } from "../../../PosPage";

interface ISummary {
  height: string;
}

function Summary({ height }: ISummary) {
  const { currentHd } = useSelector((state: any) => state.pos);
  const { giamGia, handleUpdateCurrentHd } = usePosContext();
  const [note, setNote] = useState<string>();

  const handleUpdateNote = () => {
    if (note === currentHd?.dien_giai) return;
    handleUpdateCurrentHd({ dien_giai: note });
  };

  useEffect(() => {
    setNote(currentHd?.dien_giai || "");
  }, [currentHd]);

  return (
    <Box
      sx={{
        width: "100%",
        height,
        borderBottom: "1px dashed",
        borderColor: "divider",
        backgroundColor: "background.paper",
        px: 2,
        py: 1,
      }}
    >
      <Grid2 container spacing={2} sx={{ height: "100%" }}>
        <Grid2 size={{ xs: 6 }}>
          <TextInput
            labelWidth="0px"
            placeholder="Ghi chú hóa đơn"
            minRows={2}
            maxRows={2}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            textfieldProps={{ onBlur: handleUpdateNote }}
          />
        </Grid2>
        <Grid2 size={{ xs: 6 }}>
          <Stack justifyContent="space-between" sx={{ height: "100%" }}>
            <TotalLine
              label="Tiền hàng"
              value={currentHd?.t_tien_nt || 0}
              count={currentHd?.details?.length}
            />
            <TotalLine
              label="Giảm giá"
              value={giamGia.gia_tri}
              count={giamGia.so_luong}
            />
            <TotalLine label="Tổng tiền" value={currentHd?.t_tt_nt || 0} />
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Summary;
