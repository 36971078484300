import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <Stack alignItems="center">
      <Typography variant="h1" sx={{ py: 2, textAlign: "center" }}>
        Không tìm thấy trang
      </Typography>
      <Link to="/">
        <Button
          variant="contained"
          sx={{
            boxShadow: "none",
            color: "common.white",
            textTransform: "none",
            fontWeight: 400,
            "&:hover": { boxShadow: "none" },
          }}
        >
          Về trang chủ
        </Button>
      </Link>
    </Stack>
  );
}

export default NotFoundPage;
