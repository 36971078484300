import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from "react-router-dom";
import GlobalContextProvider from "./contexts/GlobalContextProvider";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/reducers/root";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/vi";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="vi">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalContextProvider>
              <App />
            </GlobalContextProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
