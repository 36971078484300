// cr_thuoc, cr_vtyt, cr_hc
const API_URL = "https://api135.goodapp.vn/api";
const PUBLIC_URL = "https://api135.goodapp.vn";
// const ID_APP = "66432d1af06bef4e9985aa6a"; // thien thuy
// const ID_APP = "60939744ac969b4078488026"; // dev
// const ID_APP = "66f002780432a228d2b5fde5"; // cr
const ID_APP = "625df20a02f00e19239e8b89"; // goodcharme
const PUBLIC_TOKEN = "flex.public.token";

export { API_URL, PUBLIC_URL, ID_APP, PUBLIC_TOKEN };
