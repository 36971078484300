import React from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import InfoSection from "./info-section";

const schema = yup.object({
  ten_nv: yup.string().required("Vui lòng nhập tên nhân viên"),
  dien_thoai: yup.string().required("Vui lòng nhập số điện thoại"),
  email: yup.string().email("Email không đúng định dạng"),
});

function FormDMNV({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    // general info
    ma_nv: "",
    ten_nv: "",
    dien_thoai: "",
    ngay_sinh: null,
    email: "",
    dia_chi: "",
    bo_phan: null,
    chuc_vu: null,
    device_user_id: "",
    account: null,
    // cham cong va luong
    ngay_bat_dau_lam: null,
    ngay_nghi_viec: null,
    thu_viec_tu_ngay: null,
    thu_viec_den_ngay: null,
    ngay_tinh_bhxh: null,
    luong_thu_viec: 0,
    luong_co_ban: 0,
    luong_bhxh: 0,
    lam_viec: true,
    cham_cong: true,
    tinh_luong: true,
    bhxh: true,
    bhtn: true,
    bhyt: true,
    // thue thu nhap ca nhan
    ttncn_mst: "",
    ttncn_so_nguoi_phu_thuoc: 0,
    ttncn_muc_gt_phu_thuoc: 0,
    ttncn_so_thang_gt_ban_than: 0,
    ttncn_muc_gt_ban_than: 0,
    // ngan hang
    so_tk_ngan_hang: "",
    ten_chu_tk_ngan_hang: "",
    phat_hanh_boi_ngan_hang: "",
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        chuc_vu: defaultValues.chuc_vu
          ? {
              _id: defaultValues.chuc_vu,
              group_name: defaultValues.ten_chuc_vu,
            }
          : null,
        bo_phan: defaultValues.ma_bp
          ? { ma_bp: defaultValues.ma_bp, ten_bp: defaultValues.ten_bp }
          : null,
        account: defaultValues.user ? { email: defaultValues.user } : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { bo_phan, chuc_vu, account, ...fields } = values;
    return {
      ...fields,
      ma_bp: bo_phan?.ma_bp || "",
      ten_bp: bo_phan?.ten_bp || "",
      chuc_vu: chuc_vu?._id || "",
      ten_chuc_vu: chuc_vu?.group_name || "",
      user: account?.email || "",
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="800px"
      formTitle="nhân viên"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
      </Stack>
    </ModalForm>
  );
}

export default FormDMNV;
