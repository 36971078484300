import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import Bill from "./Bill";
import UserSetting from "../../../../../components/common/layout/header/UserSetting";
import { MenuItemType } from "../../../../../types/menu.type";
import { LuFile, LuHome, LuUser } from "react-icons/lu";
import { useSelector } from "react-redux";
import SelectStore from "../../../../../components/common/layouts/navbar/SelectStore";

interface IHeader {
  height: string;
}

const options: MenuItemType[] = [
  {
    path: "/",
    text: "Trang chủ",
    Icon: LuHome,
  },
  {
    path: "/list/pbl",
    text: "Đơn hàng",
    Icon: LuFile,
  },
  {
    path: "/list/customer",
    text: "Khách hàng",
    Icon: LuUser,
  },
];

function Header({ height }: IHeader) {
  const { currentStore } = useSelector((state: any) => state.config);

  useEffect(() => {
    if (!currentStore) {
      const displayStore = document.getElementById("display-store");
      displayStore?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%", height, px: 2, backgroundColor: "background.paper" }}
    >
      <Bill />
      <Stack direction="row" alignItems="center" gap={1}>
        <SelectStore
          displaySx={{
            backgroundColor: "background.default",
            color: "text.primary",
            textTransform: "none",
            fontWeight: 400,
            boxShadow: "none",
            height: "34px",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        />
        <UserSetting options={options} />
      </Stack>
    </Stack>
  );
}

export default Header;
