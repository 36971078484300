import React, { useState } from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import moment from "moment";
import InfoSection from "./info-section/InfoSection";
import DetailsSection from "./details-section/DetailsSection";
import DescriptionSection from "./description-section/DescriptionSection";
import GiaoHangSection from "./giaohang-section/GiaoHangSection";

const schema = yup.object({
  kho: yup
    .object()
    .typeError("Vui lòng chọn kho")
    .required("Vui lòng chọn kho"),
  ngay_ct: yup
    .date()
    .typeError("Vui lòng nhập ngày chứng từ")
    .required("Vui lòng nhập ngày chứng từ"),
  khach_hang: yup
    .object()
    .typeError("Vui lòng chọn khách hàng")
    .required("Vui lòng chọn khách hàng"),
  pttt: yup
    .object()
    .typeError("Vui lòng chọn phương thức thanh toán")
    .required("Vui lòng chọn phương thức thanh toán"),
  trang_thai: yup
    .object()
    .typeError("Vui lòng chọn trạng thái")
    .required("Vui lòng chọn trạng thái"),
});

function FormPBL({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { currentStore } = useSelector((state: any) => state.config);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    so_ct: "",
    dich_vu_giao_hang: "VTP",
    ngay_ct: moment().format("YYYY-MM-DD HH:mm"),
    kho: currentStore || null,
    nhan_vien: null,
    khach_hang: null,
    kenh_ban: null,
    pttt: null,
    trang_thai: null,
    tinh_thanh: null,
    quan_huyen: null,
    xa_phuong: null,
    dia_chi: "",
    ten_nguoi_nhan: "",
    dien_thoai: "",
    don_vi_van_chuyen: null,
    tk_co: "331111",
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ngay_ct: moment(defaultValues.ngay_ct).format("YYYY-MM-DD HH:mm"),
        kho: {
          ma_kho: defaultValues?.ma_kho,
          ten_kho: defaultValues?.ten_kho,
        },
        nhan_vien: defaultValues.ma_nv
          ? { ma_nv: defaultValues.ma_nv, ten_nv: defaultValues.ten_nv }
          : null,
        kenh_ban: defaultValues?.ma_kenh
          ? {
              ma_kenh: defaultValues?.ma_kenh || "",
              ten_kenh: defaultValues?.ten_kenh || "",
            }
          : null,
        pttt: defaultValues.pt_thanh_toan
          ? {
              _id: defaultValues.pt_thanh_toan,
              ten: defaultValues.ten_pt_thanh_toan,
            }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        tinh_thanh: defaultValues.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen: defaultValues.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong: defaultValues.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
        don_vi_van_chuyen: defaultValues?.dich_vu_giao_hang
          ? {
              ma: defaultValues?.dich_vu_giao_hang || "",
              ten: defaultValues?.dich_vu_giao_hang || "",
            }
          : null,
      }
    : defaultData;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState<any[]>(defaultValues?.details || []);

  const tinhThanh = watch("tinh_thanh");
  const quanHuyen = watch("quan_huyen");

  const handleReset = () => {
    reset(originData);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const {
      kho,
      khach_hang,
      trang_thai,
      nhan_vien,
      pttt,
      kenh_ban,
      tinh_thanh,
      quan_huyen,
      xa_phuong,
      don_vi_van_chuyen,
      ...data
    } = values;
    const result = {
      ...data,
      ma_kho: kho?.ma_kho || "",
      ten_kho: kho?.ten_kho || "",
      ma_kh: khach_hang?.ma_kh || "",
      ten_kh: khach_hang?.ten_kh || "",
      trang_thai: trang_thai?.ma_trang_thai || "",
      ten_trang_thai: trang_thai?.ten_trang_thai || "",
      ma_nv: nhan_vien?.ma_nv || "",
      ten_nv: nhan_vien?.ten_nv || "",
      ma_kenh: kenh_ban?.ma_kenh || "",
      ten_kenh: kenh_ban?.ten_kenh || "",
      pt_thanh_toan: pttt?._id || "",
      ten_pt_thanh_toan: pttt?.ten || "",
      tinh_thanh: tinh_thanh?.ma_tinh_thanh || "",
      ten_tinh_thanh: tinh_thanh?.ten_tinh_thanh || "",
      quan_huyen: quan_huyen?.ma_quan_huyen || "",
      ten_quan_huyen: quan_huyen?.ten_quan_huyen || "",
      xa_phuong: xa_phuong?.ma_xa_phuong || "",
      ten_xa_phuong: xa_phuong?.ten_xa_phuong || "",
      dich_vu_giao_hang: don_vi_van_chuyen?.ma || "",
      details,
    };
    return result;
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="1000px"
      formTitle="đơn hàng"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} control={control} errors={errors} />
        <DetailsSection details={details} setDetails={setDetails} />
        <DescriptionSection register={register} />
        <GiaoHangSection
          register={register}
          setValue={setValue}
          control={control}
          tinhThanh={tinhThanh}
          quanHuyen={quanHuyen}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormPBL;
