import React, { useState } from "react";
import { IForm } from "../../../../types/form.type";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { useAlertContext } from "../../../../contexts/alert/AlertProvider";
import { useForm } from "react-hook-form";
import { asyncPostData, asyncPutData } from "../../../../utils/httpRequests";
import ModalForm from "../../modal/ModalForm";
import { Stack } from "@mui/material";
import InfoSection from "./info-section/InfoSection";
import DiachiSection from "./dia-chi/DiachiSection";
import PrinterSection from "./printer-section/PrinterSection";

const schema = yup.object({
  ma_kho: yup.string().required("Vui lòng nhập mã kho"),
  ten_kho: yup.string().required("Vui lòng nhập tên kho"),
  email: yup.string().email("Email không đúng định dạng"),
});

function FormDmKho({
  defaultValues,
  isEdit,
  apiCode,
  open,
  onClose,
  setLoad,
}: IForm) {
  const app = useSelector((state: any) => state.app.data);
  const token = useSelector((state: any) => state.auth.token);
  const { showAlert } = useAlertContext();
  const defaultData: { [key: string]: any } = {
    ma_kho: "",
    ten_kho: "",
    dia_chi: "",
    email: "",
    dien_thoai: "",
    status: true,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        dien_thoai: defaultValues.exfields?.dien_thoai
          ? defaultValues.exfields?.dien_thoai
          : "",
        dia_chi: defaultValues?.exfields?.dia_chi || "",
        email: defaultValues?.exfields?.email || "",
      }
    : defaultData;
  const [printers, setPrinters] = useState(defaultValues?.printers || []);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: originData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originData);
    setPrinters(defaultValues?.printers || []);
  };

  const generateDataPost = async (values: typeof defaultData) => {
    const { exfields, dia_chi, email, ...fields } = values;
    return {
      ...fields,
      printers: printers,
      dia_chi,
      exfields: {
        ...exfields,
        dien_thoai: fields.dien_thoai || "",
        dia_chi,
        email,
      },
    };
  };

  const handleSave = async (values: typeof defaultData) => {
    const dataPost: { [key: string]: any } = await generateDataPost(values);
    let resp = null;
    if (isEdit) {
      resp = await asyncPutData({
        id: dataPost._id,
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    } else {
      resp = await asyncPostData({
        apiCode,
        token,
        idApp: app?._id,
        data: dataPost,
      });
    }
    if (resp.status !== 200) {
      showAlert({
        type: "error",
        message:
          resp?.data?.error || resp?.data?.message || "Lỗi khi lưu đối tượng",
      });
    } else {
      onClose();
      setLoad?.((prev: number) => prev + 1);
    }
  };

  const onSubmit = handleSubmit(handleSave);

  return (
    <ModalForm
      formWidth="900px"
      formTitle="chi nhánh"
      loading={isSubmitting}
      open={open}
      isEdit={isEdit}
      onClose={onClose}
      onSubmit={onSubmit}
      onReset={handleReset}
    >
      <Stack gap={2}>
        <InfoSection register={register} errors={errors} control={control} />
        <DiachiSection register={register} />
        <PrinterSection details={printers} setDetails={setPrinters} />
      </Stack>
    </ModalForm>
  );
}

export default FormDmKho;
