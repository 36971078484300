import React, { useRef } from "react";
import JoditEditor, { IJoditEditorProps } from "jodit-react";

interface IRichTextInput {
  value: string;
  onChange?: (value: string) => void;
}

function RichTextInput({ value, onChange }: IRichTextInput) {
  const editor = useRef(null);

  const config: IJoditEditorProps["config"] = {
    defaultMode: 1,
    basePath: "/",
    enterBlock: "div",
    enter: "br",
    statusbar: false,
    buttons:
      "paragraph,bold,underline,italic,strikethrough,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,table,undo,redo",
    controls: {
      fontsize: {
        list: [
          "8",
          "9",
          "10",
          "11",
          "12",
          "14",
          "16",
          "18",
          "24",
          "30",
          "36",
          "48",
          "60",
          "72",
          "96",
          "100",
        ],
      },
      font: {
        command: "fontname",
        list: {
          "": "Default",
          "'Open Sans',sans-serif": "Open Sans",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
        },
      },
    },
  };

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      onBlur={(newValue) => {
        onChange?.(newValue);
      }}
    />
  );
}

export default RichTextInput;
